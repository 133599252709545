import { TournamentPlayer } from 'livegolf_shared';
import { ScoreEntry } from 'livegolf_shared/lib/types';
import { TournamentService } from '../services/TournamentService';
import { ClientCourseCombi } from '../classes/CourseCombiClass';


export interface LGUser {
   id: string;
   nickname: string;
   roles?: Array<string>;
   verified: boolean;
   player_id?: number;
}

export enum LGUnit {
   undefined = 0,
   meters = 1,
   yards = 2
}

export enum UserState {
   undefined = 0,
   pending_activation = 1,
   active = 2,
   disabled = 3
}

export enum TournamentMode {
   Strokeplay = 0, // used many times
   Zockerplay = 1, // strokeplay + points(money) with hcp-factor based extra strokes. Used many times
   Stableford = 2, // used many times
   StrokeStableMix = 3, // used twice
   ZockerStrokePlay = 4, // strokeplay + points. used 8 times
   StrokeplayNet = 5 // used 14 times
}

export enum TournamentStatus {
   InProgress = 0,
   Completed = 1,
   Suspended = 2
}

export enum TournamentVisibility {
   private = 0,
   public = 1,
   hidden = 2, // not found in searches
   restricted = 3, // make available to select users/groups, owner can define?
   friends = 4
}

export enum Gender {
   undefined = 0,
   male = 1,
   female = 2
}

export enum GroupPlayerStatus {
   undefined = 0,
   missed_cut = 1,
   pending_permission = 2,
   disqualified = 4,
   withdrawn = 8,
   active = 16
}

export type ScoreData = {
   group_players: Array<GroupPlayerData>;
   round: RoundData;
}

export type TournamentData = {
   id: number;
   name: string;
   active_round_index: number;
   mode: number;
   status: number;
}

export type TournamentListEntry = {
   id: number;
   name: string;
   mode: TournamentMode;
   active_round_index: number;
   status: TournamentStatus;
   owner_id: string;
   rounds: [{ date: number, course: { name: string } }]
}

export type AdminTournamentListEntry = {
   id: number;
   name: string;
   mode: TournamentMode;
   active_round_index: number;
   status: TournamentStatus;
   visibility: TournamentVisibility;
   owner_id: string;
   owner: LGUser;
   rounds: [{ date: number, course: { name: string } }]
}

export type TournamentList = {
   rows: Array<TournamentListEntry>;
   count: number;
}

export type HolesData = {
   "1": HoleData;
   "2": HoleData;
   "3": HoleData;
   "4": HoleData;
   "5": HoleData;
   "6": HoleData;
   "7": HoleData;
   "8": HoleData;
   "9": HoleData;
   "10": HoleData;
   "11": HoleData;
   "12": HoleData;
   "13": HoleData;
   "14": HoleData;
   "15": HoleData;
   "16": HoleData;
   "17": HoleData;
   "18": HoleData;
}

export type RoundData = {
   date: string;
   hcp_factor: number;
   id: number;
   round_index: number;
   tournament: TournamentData;
}

export type CourseData = {
   ID: number;
   course_id: number;
   name: string;
   tee_id: number;
   tee_name: string;
   holes: HolesData;
}

/*export type ScoreEntry = {
  hole: number;
  score: number;
  extra_data: string;
  time_created?: number;
}*/

export type CourseCombi = {
   id: number;
   name: string;
   holes: Array<HoleData>
   course_id: number;
   tee_id: number;
   tee_name: string;
   cr: number;
   slope: number;
   gender_id: number;
   unit: number;
   par?: number;
}

export type Player = {
   id: number;
   firstname: string;
   lastname: string;
}

export type GroupPlayerData = {
   group: {
      id: string;
      tee: number;
   }
   start_index: number;
   tee_id: number;
   status: number;
   hcp: number;
   gender_id: number;
   score_type: number;
   team_id: number;
   course_combi: CourseCombi;
   player: Player
   scores: Array<ScoreEntry>
}

export type ScoreTableDataEntry = {
   holeIndex: number;
   playerId: number;
   name: string;
   previousScore: number;
   currentScore: number;
}

export type ScoreInput = {
   group_id: string;
   hole: number;
   player_id: number;
   score: number;
   extraData: string;
   time_created?: number;
   time_changed?: number;
}

export type ScoreEditData = {

   customScore: number;
   customBackgroundColor: string;
   customTextColor: string;
   customBorderWidth: number;
} & ScoreInput;


export type ColorPair = {
   background: string;
   text: string;
}

export type HoleData = {
   par: number;
   hcp: number;
   length: number;
}

export type ScoreBoardTournament = {
   id: number;
   status: number;
   name: string;
   active_round_index: number;
   mode: number;
   visibility: number;
   owner_id: number;
   best_ball_points: number;
   birdie_points: number;
   nearest_pin_points: number;
   longest_drive_points: number;
   aggregate_points: number;
   score_version: number;
   rounds: Array<ScoreBoardRound>
}

export type ScoreBoardRound = {
   id: number;
   round_index: number;
   date: number;
   course: ScoreBoardCourse;
   groups: Array<ScoreBoardGroup>;
}

export type ScoreBoardCourse = {
   id: number;
   name: string;
   cluburl: string;
}

export type ScoreBoardGroup = {
   tee: number;
   starting_time: string;
   group_players: Array<ScoreBoardGroupPlayer>
}

export type ScoreBoardGroupPlayer = {
   gender_id: number;
   tee_id: number;
   status: number;
   score_type: number;
   course_combi_id: number;
   player: ScoreBoardPlayer;
   scores: Array<ScoreBoardScore>;
   hcp: number;
}

export type ScoreBoardPlayer = {
   id: number;
   firstname: string;
   lastname: string;
}

export type ScoreBoardScore = {
   hole: number;
   score: number;
   extra_data: string;
   time_created: number;
}

export type TournamentInput = {
   id: number;
   name?: string;
   active_round_index?: number;
   mode?: number;
   status?: number;
   visibility?: number;
   best_ball_points?: number;
   birdie_points?: number;
   nearest_pin_points?: number;
   longest_drive_points?: number;
   aggregate_points?: number;
}

export type GroupPlayerInput = {
   group_id: string;
   player_id: number;
   start_index?: number;
   tee_id: number;
   status?: number;
   hcp?: number;
   gender_id: Gender;
   score_type?: number;
   team_id?: number;
 }

export type GroupPlayerModify = Partial<GroupPlayerInput> &
   Pick<GroupPlayerInput, 'group_id' | 'player_id'>;

export type GroupInput = {
   round_id: number;
   starting_time: string;
   tee?: number;
}

export type GroupModify = {
   id: string;
   round_id: number;
   starting_time?: string;
   tee?: number
}

export type RoundModify = {
   id: number;
   date?: string;
   hcp_factor?: number;
   round_index?: number;
   course_id?: number;
}

export type TournamentScoreData = {
   coursecombis: Array<ClientCourseCombi>;
   playerdata: Array<TournamentPlayer>;
   tournamentDetails: TournamentService.ScoreBoardTournamentDetails;
   score_version: number
}

export type ScoreTableCourseData = {
   par: number;
   length: number;
}

export type FlowsDataNodeAttribute = {
   name: string;
   type: string;
   value?: string;
   required?: boolean;
   disabled: boolean;
   node_type: string;
}

export type FlowsDataNode = {
   attributes: FlowsDataNodeAttribute;
   messages?: [];
   type: string;
}

export type FlowsData = {
   ui: {
      action: string;
      method: string;
      nodes: Array<FlowsDataNode>;
   }
}

export type AuthRegistrationData = {
   email: string;
   password: string;
   flowId?: string;
}

export type AuthLoginData = {
   email: string;
   password: string;
   flowId?: string;
}

export type WhoAmIResponse = {
   identity: {
      traits: {
         email: string;
      }
   }
}

