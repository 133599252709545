import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { StyleSheet, Dimensions, NativeSyntheticEvent, TextInputChangeEventData, Pressable } from 'react-native';
import { RootStackParamList } from '../navigation/LGStackNavigator';
import * as AuthService from '../services/AuthService';
import { LivegolfHeader } from '../components/LivegolfHeader';
import MaterialIcons from '@expo/vector-icons/build/MaterialIcons';
import { isValidEmail } from '../services/ValidateHelper';
import { Button, Input, ScrollView, Stack, Text, XStack, YStack } from 'tamagui';

type Props = NativeStackScreenProps<RootStackParamList, 'Register'>;

const windowHeight = Dimensions.get('window').height;

type State = {
  email: string;
  password: string;
  passwordConfirm: string;
  showPassword: boolean;
  dataValid: boolean;
  exitMessage?: string;
}

export default class RegisterScreen extends React.Component<Props, State> {

  emailTimeout;

  constructor(props) {
    super(props);
    this.state = { email: "", password: "", passwordConfirm: "", showPassword: false, dataValid: false }
  }

  toggleShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  onEmailChanged(event: NativeSyntheticEvent<TextInputChangeEventData>) {
    this.setState({ email: event.nativeEvent.text }, () => {
      if (this.emailTimeout) {
        clearTimeout(this.emailTimeout);
      }
      this.emailTimeout = setTimeout(() => { this.emailTimeout = null; this.checkDataValid() }, 500);
    }
    );
  }

  onPasswordChanged(event: NativeSyntheticEvent<TextInputChangeEventData>) {
    this.setState({ password: event.nativeEvent.text }, () => { this.checkDataValid() });
  }

  onPasswordConfirmChanged(event: NativeSyntheticEvent<TextInputChangeEventData>) {
    this.setState({ passwordConfirm: event.nativeEvent.text }, () => { this.checkDataValid() });
  }

  emailValid() {
    return isValidEmail(this.state.email);
  }

  passwordValid() {
    return this.state.password != "" && this.state.passwordConfirm != "" && this.state.password == this.state.passwordConfirm;
  }

  checkDataValid() {
    this.setState({ dataValid: (this.emailValid() && this.passwordValid()) });
  }

  onRegisterPressed() {
    //const flowId = this.props.route.params.flow;

    if (this.emailValid() && this.passwordValid()) {
      AuthService.registerUser({ email: this.state.email, password: this.state.password }).then((res) => {
        //this.props.navigation.navigate('Verification', { flow: null, fromRegistration: true});
        this.setState({ exitMessage: "You received an email to verify your email address. Please check your inbox to activate your account!"});
      });
    }
  }

  onExitPressed() {
    this.props.navigation.replace("Home");
  }

  

  render() {

    return (
      <Stack bg="#FFF">
        <LivegolfHeader title="Registration" navigation={this.props.navigation} />
        <ScrollView>
          <Stack justifyContent='center' alignItems='center' marginTop={32}>
            { !this.state.exitMessage &&
              <Stack space={4} w={400} alignItems="center">
                <Input onChange={this.onEmailChanged.bind(this)} value={this.state.email} w="100%" placeholder="Email" />
                <XStack>
                  <Input onChange={this.onPasswordChanged.bind(this)} value={this.state.password} w="100%" secureTextEntry={this.state.showPassword ? false : true} placeholder='Password'/>
                  <Pressable onPress={() => this.toggleShowPassword()}>
                    <MaterialIcons name={this.state.showPassword ? "visibility" : "visibility-off"} />
                  </Pressable>
                </XStack>
                  

                <XStack>
                  <Input onChange={this.onPasswordConfirmChanged.bind(this)} value={this.state.passwordConfirm} w="100%" secureTextEntry={this.state.showPassword ? false : true} placeholder='Repeat Password'/>
                  <Pressable onPress={() => this.toggleShowPassword()}>
                    <MaterialIcons name={this.state.showPassword ? "visibility" : "visibility-off"} />
                  </Pressable>
                </XStack>

                <Button w={140} disabled={!this.state.dataValid} onPress={this.onRegisterPressed.bind(this)}>Register</Button>
              </Stack>
            }
            { this.state.exitMessage &&
              <YStack space={2}>
                <Text>{this.state.exitMessage}</Text>
                <Button w={140} disabled={!this.state.dataValid} onPress={this.onExitPressed.bind(this)}>Back to Home Screen</Button>
              </YStack>
            }
          </Stack>
        </ScrollView>
      </Stack>
    );
  }
}



