const MaterialIcons = React.lazy(() => import("@expo/vector-icons/build/MaterialIcons"));
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { Dimensions, VirtualizedList, ScaledSize, EmitterSubscription } from 'react-native';
import { RootStackParamList } from '../../navigation/LGStackNavigator';
import { LGAdminHeader } from '../../components/LGAdminHeader';
import * as AuthService from '../../services/AuthService';
import * as AuthTypes from '../../types/AuthTypes';
import * as Types from '../../types/types';
import { Stack } from 'tamagui';

type AdminTournamentList = Array<Types.AdminTournamentListEntry>;

type Props = NativeStackScreenProps<RootStackParamList, 'Admin'>;

const windowHeight = Dimensions.get('window').height;

type State = {
    authState: AuthTypes.AuthState;
    tournamentList: AdminTournamentList;
    windowDims: ScaledSize;
}

export default class LoginScreen extends React.Component<Props, State> {

    private fetchingTournamentList = false;
    private focusListener;
    private dimsSubcription: EmitterSubscription;
    private pendingFetchOffset: number = 0;
    private pendingFetchLimit: number = 0;
    private currentTournamentsFetched: number = 0;

  constructor(props) {
    super(props);
    //this.state = {  }
  }

  componentDidMount(): void {
    
    this.dimsSubcription = Dimensions.addEventListener("change", this.onDimsChanged.bind(this));

    const { navigation } = this.props;
    this.focusListener = navigation.addListener("focus", () => {
      this.getUserData();
    });  

    this.getUserData();
  }

  onDimsChanged({ window, screen }) {
    this.setState({ windowDims: Dimensions.get('window') }, () => {
      this.forceUpdate();
    });
  }

  getUserData() {
    
    AuthService.getLoginState();
       
  }

  componentWillUnmount(): void {
    if(this.focusListener) {
      this.focusListener.remove();
      this.focusListener = null;
    }
  }

  

  render() {

    //return ( <View> <LivegolfHeader title="Login" navigation={this.props.navigation} /></View>);
    return (
      
        <Stack backgroundColor="#FEE" height={this.state.windowDims.height}>
          <LGAdminHeader title="Live-Golf Admin" navigation={this.props.navigation} />
        </Stack>
      );
  }
}


