import getEnvVars from "../environment";
import { globals } from "../global";
import { AuthState, RegistrationFlowBody, RegistrationResponse, RecoverUserResponse, RecoveryFlowBody, LoginFlowBody, LoginResponse, SettingsResponse, SettingsFlowBody, AuthVerificationData, VerificationCompleteBody, VerificationResponse, FlowResponse, FlowBody, FlowType } from "../types/AuthTypes";
import { AuthLoginData, AuthRegistrationData, FlowsData, LGUser, WhoAmIResponse } from "../types/types";


console.log("AuthService.globals: ", globals);

const authListeners = [];

export function addAuthListener(callbackFunc) {
  if (authListeners.indexOf(callbackFunc != -1)) {
    authListeners.push(callbackFunc);
  }
}

export function removeAuthListener(callbackFunc) {
  const idx = authListeners.indexOf(callbackFunc);

  if (idx != -1) {
    authListeners.splice(idx, 1);
  }
}

function notifyAuthListeners() {
  //console.log("Notify auth listeners");
  authListeners.map((callbackFunc) => {
    callbackFunc();
  })
}

export function getLoginState(): AuthState {
  console.log("getLoginState");
  if (!globals.userData?.verified) {
    return 'not_logged_in';
  }

  

  /*if(globals.sessionData.expires_at.getUTCMilliseconds() < Date.now()) {
    return 'expired';
  }*/

  if (globals.userData.verified) {
        return 'verified';
  }

  return 'unverified';
}

export async function fetchUserData() {
  return new Promise<LGUser>((resolve, reject) => {
    fetch(`${getEnvVars().ApiUrl}/user`, { credentials: 'include'}).then((res) => {
      res.json().then((userData : LGUser) => {
        if(userData) {
          console.log("userData not null: ", userData);
          if(globals.userData != userData) {
            globals.userData = userData;
            notifyAuthListeners();
          }  
        }
        else {
          console.log("userData is null");
          if(globals.userData) {
            globals.userData = null;
            notifyAuthListeners();
          }
          else {
            globals.userData = null;
          }
        }
        resolve(userData);
      }).catch((err) => {
        console.log("failed fetching user: json");
        if(globals.userData) {
          globals.userData = null;
          notifyAuthListeners();
        }
        reject(err);
      })
    },
    (err) => {
      console.log("failed fetching user");
      if(globals.userData) {
        globals.userData = null;
        notifyAuthListeners();
      }
      reject(err);
    })
  })
  
}

export async function whoami(): Promise<WhoAmIResponse> {
  return new Promise((resolve, reject) => {
    fetch(`${getEnvVars().AuthBaseUrl}/sessions/whoami`, { credentials: 'include' }).then((res) => {
      res.json().then((sessionData) => {
        if (sessionData && !sessionData.error) {
          globals.sessionData = sessionData;
          //notifyAuthListeners();
          //console.log(sessionData);
          resolve(sessionData);
        }
        else {
          //console.log(globals.sessionData);
          if (globals.sessionData) {
            globals.sessionData = null;
            //console.log("Notify1");
            //notifyAuthListeners();
          }
          reject("not logged in");
        }
      },
        (err) => {
          //console.log(globals.sessionData)
          if (globals.sessionData) {
            globals.sessionData = null;
            //console.log("Notify2");
            //notifyAuthListeners();
          }
          reject(err);
        })
    },
    (err) => {
      reject(err);
    })
  })
}

export function getFlowID(flowType: FlowType): Promise<string> {
  let extraParam = "";
  if(flowType == "login") {
    extraParam = "?refresh=true";
  }
  return new Promise((resolve, reject) => {
    fetch(`${getEnvVars().AuthBaseUrl}/self-service/${flowType}/browser${extraParam}`, {
      credentials: 'include', headers: {
        'Accept': 'application/json'
      }
    }).then((data) => {
      if (data) {
        data.json().then((bodyjson) => {
          resolve(bodyjson.id);
        })
      }
      else {
        reject("No data");
      }
    }).catch(err => {
      reject(err);
    })
  })
}

export async function registerUser(registrationData: AuthRegistrationData) {
  const body: RegistrationFlowBody = {
    method: "password",
    password: registrationData.password,
    "traits.email": registrationData.email
  }

  return createFlow<RegistrationResponse, RegistrationFlowBody>('registration', body, true);
}

export function getCSRFTokenFromFlowJson(flowsData: FlowsData): string | null {

  if (flowsData?.ui?.nodes) {
    for (var i = 0; i < flowsData.ui.nodes.length; ++i) {
      const nodeData = flowsData.ui.nodes[i];

      if (nodeData.attributes?.name == 'csrf_token') {
        return nodeData.attributes.value;
      }
    }
  }

  return null;
}

export async function recoverUser(email: string): Promise<RecoverUserResponse> {
  const body: RecoveryFlowBody = {
    method: "code",
    email: email
  }

  return createFlow<RecoverUserResponse, RecoveryFlowBody>('recovery', body, true);
}

export async function loginUser(loginData: AuthLoginData) {
  const body: LoginFlowBody = {
    method: "password",
    password: loginData.password,
    identifier: loginData.email
  }

  return createFlow<LoginResponse, LoginFlowBody>('login', body, true);
}

export async function changePassword(password: string) {
  const body: SettingsFlowBody = {
    method: "password",
    password: password
  }

  return createFlow<SettingsResponse, SettingsFlowBody>('settings', body, true);
}

export async function verifyEmail(verificationData: AuthVerificationData) {
  

  return createFlow<RegistrationResponse, VerificationCompleteBody>('verification', verificationData, true);
}



export async function postVerificationData(flowId: string, code: string) {

  return postFlowData<VerificationResponse, VerificationCompleteBody>('verification', flowId, { method: 'code', code: code });
}

export async function getVerificationCode(flowId) : Promise<FlowsData>{
  return new Promise((resolve, reject) => {
    return getFlows('verification', flowId, true).then((res_json) => {
      res_json.json().then((veriFlowData: FlowsData) => {
        resolve(veriFlowData);
      },
      (err) => {
        reject(err);
      }
      );
    })
  })
}



export async function logout(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    fetch(`${getEnvVars().AuthBaseUrl}/self-service/logout/browser`, { credentials: 'include' }).then((data) => {
      //console.log(data);
      if (data) {
        data.json().then((jsondata) => {
          //resolve(jsondata.logout_url);
          fetch(jsondata.logout_url, { credentials: 'include', headers: { 'Accept': 'application/json' } }).then(() => {
            whoami().then(() => {
              resolve();
            },
              (err) => {
                resolve();
              })
          })
        },
          (err) => {
            reject();
          })
      }
      else {
        console.error("No data");
        reject();
      }
    })
  })
}

function getFlows(flowType: FlowType, flowId: string, credentials: boolean) {
  let fetchOptions: RequestInit = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
  if (credentials) {
    fetchOptions.credentials = 'include';
  }

  return fetch(`${getEnvVars().AuthBaseUrl}/self-service/${flowType}/flows?id=${flowId}`, fetchOptions);
}




export async function createFlow<ResponseType extends FlowResponse, BodyType extends FlowBody>(flowType: FlowType, body: BodyType, credentials: boolean): Promise<ResponseType> {
  return new Promise<ResponseType>((resolve, reject) => {
    getFlowID(flowType).then((flowId) => {

      let fetchOptions : RequestInit = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
      if(credentials) {
        fetchOptions.credentials = 'include';
      }
      fetch(`${getEnvVars().AuthBaseUrl}/self-service/${flowType}/flows?id=${flowId}`,fetchOptions).then((flowData) => {
        //flowData.json().then((jsonObject) => {

      //getFlows(flowType, flowId, credentials).then((flowData) => {
        //console.log("FlowData: ", JSON.stringify(flowData));
        flowData.json().then((jsonObject: FlowsData) => {
          //console.log("FlowJson: ", JSON.stringify(jsonObject));

          const csrfToken = getCSRFTokenFromFlowJson(jsonObject);

          if (csrfToken) {
            body.csrf_token = csrfToken;
            postFlowData<ResponseType, BodyType>(flowType, flowId, body).then((result) => {
              result.csrfToken = csrfToken;
              result.flowId = flowId;
              resolve(result);
            },
              (err) => {
                reject(err);
              });
          }
          else {
            reject("CSRF Token not found");
            return;
          }
        })
      })
    })
  })
}

export async function postFlowData<ResponseType extends FlowResponse, BodyType extends FlowBody>(flowType: FlowType, flowId: string, body: BodyType) {

  return new Promise<ResponseType>((resolve, reject) => {
    fetch(`${getEnvVars().AuthBaseUrl}/self-service/${flowType}?flow=${flowId}`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }).then(
        (data) => {
          data.json().then((resval) => {
            //console.log(resval);
            resolve(resval);
          },
            (err) => {
              reject(err);
            })
        }
      )
  })
}
