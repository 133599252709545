import { createStackNavigator } from '@react-navigation/stack';


import HomeScreen from '../screens/HomeScreen';
import ScoreScreen from '../screens/ScoreScreen';
import ScoreBoardScreen from '../screens/ScoreBoardScreen';
import LoginScreen from '../screens/LoginScreen';
import RecoverScreen from '../screens/RecoverScreen';
import RegisterScreen from '../screens/RegisterScreen';
import VerificationScreen from '../screens/VerificationScreen';
import AdminScreen from '../screens/Admin/AdminScreen';
import AdminTournamentsScreen from '../screens/Admin/AdminTournamentsScreen';
import AdminTournamentScreen from '../screens/Admin/AdminTournamentScreen';
import AdminAddTournamentScreen from '../screens/Admin/AddTournamentScreen';
import AdminCoursesScreen from '../screens/Admin/AdminCoursesScreen';
import AdminCourseScreen from '../screens/Admin/AdminCourseScreen';

export type RootStackParamList = {
  Home: undefined;
  Score: { gid: string };
  Register: { flow: string };
  Login: { flow: string };
  Recover: undefined;
  ScoreBoard: { tid: number };
  Verification: { flow: string, fromRegistration?: boolean };
  AdminTournament: { tid: number };
  AdminTournaments: undefined;
  AdminAddTournament: undefined;
  Admin: undefined;
  AdminCourses: undefined;
  AdminCourse: { cid: number };
};

export const LGNavigationConfig = {
  screens: {
    Home: '',
    Score: 'score/:gid',
    Login: 'login',
    Register: 'register',
    Recover: 'recover',
    ScoreBoard: 'scoreboard/:tid',
    Verification: 'verification',
    AdminTournament: 'admin/tournament',
    AdminTournaments: "admin/tournaments",
    adminAddTournament: "admin/add_tournament",
    Admin: 'admin',
    AdminCourses: 'admin/courses',
    AdminCourse: 'admin/course/:cid'
  },
};

const Stack = createStackNavigator();

export const LGStackNavigator = () => { return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen name="Home" component={HomeScreen}/>
        <Stack.Screen name="ScoreBoard" component={ScoreBoardScreen} />
        <Stack.Screen name="Score" component={ScoreScreen} />
        <Stack.Screen name="Login" component={LoginScreen} />
        <Stack.Screen name="Recover" component={RecoverScreen} />
        <Stack.Screen name="Register" component={RegisterScreen} />
        <Stack.Screen name="Verification" component={VerificationScreen} />
        <Stack.Screen name="Admin" component={AdminScreen} />
        <Stack.Screen name="AdminTournaments" component={AdminTournamentsScreen} />
        <Stack.Screen name="AdminTournament" component={AdminTournamentScreen} />
        <Stack.Screen name="AdminAddTournament" component={AdminAddTournamentScreen} />
        <Stack.Screen name="AdminCourses" component={AdminCoursesScreen} />
        <Stack.Screen name="AdminCourse" component={AdminCourseScreen} />
    </Stack.Navigator>
)}