import { NativeStackScreenProps } from '@react-navigation/native-stack';
import * as React from 'react';
import { Dimensions, ScaledSize, EmitterSubscription, VirtualizedList, ListRenderItemInfo, Modal, TouchableOpacity } from 'react-native';
import { RootStackParamList } from '../../navigation/LGStackNavigator';
import * as AuthService from '../../services/AuthService';
import { CourseService, CourseService_GetCourse_CC_Data, CourseService_GetCourse_Data } from '../../services/CourseService';
import { LGAdminHeader } from '../../components/LGAdminHeader';
import * as AuthTypes from '../../types/AuthTypes';

import { Button, Input, Stack, Text } from 'tamagui';
import { LGText } from '../../styles/StyledText';
import { LGUnit } from '../../types/types';
import _ from "lodash";
import { CourseCombiInput, HoleInput } from 'livegolf_shared/lib/generated/graphql';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { LGColors } from '../../constants/colors';
import AddCourseCombiDataModal from '../../components/Admin/AddCourseCombiDataModal';
import AddHolesModal from '../../components/Admin/AddHolesModal';
import { CourseCombiService } from '../../services/CourseCombiService';

type Props = NativeStackScreenProps<RootStackParamList, 'AdminCourse'>;

type State = {
  authState: AuthTypes.AuthState;
  windowDims: ScaledSize;
  loadError?: string;
  courseData?: CourseService_GetCourse_Data;
  hasChanges: boolean;
  showAddCourseCombiData: boolean;
  courseCombiData: CourseCombiInput;
  showAddHoles: boolean;
  addCCHoleCount: number;
  renderCourseCombis: number;
}

export default class AdminCoursesScreen extends React.Component<Props, State> {
  private fetchingCourseData = false;
  private focusListener;
  private dimsSubcription: EmitterSubscription;
  private savedCourseData: CourseService_GetCourse_Data | null = null;

  constructor(props) {
    super(props);

    this.state= { 
      authState: 'not_logged_in', 
      windowDims: Dimensions.get('window'),
      hasChanges: false,
      showAddCourseCombiData: false,
      courseCombiData: null,
      showAddHoles: false,
      addCCHoleCount: 18,
      renderCourseCombis: 1
    };
  }

  componentDidMount(): void {
    
    this.dimsSubcription = Dimensions.addEventListener("change", this.onDimsChanged.bind(this));

    const { navigation } = this.props;
    this.focusListener = navigation.addListener("focus", () => {
      console.log("focus");
      this.getUserData();
    });  

    AuthService.addAuthListener(() => {
      this.setState({ authState: AuthService.getLoginState()});

      if(AuthService.getLoginState() == 'verified') {
        this.getCourseData();
      }
    });

    if(this.state.authState == 'verified') {
      this.getCourseData();
    }
  }

  onDimsChanged({ window, screen }) {
    //console.log("dims changed: ", window);
    this.setState({ windowDims: Dimensions.get('window') }, () => {
      this.forceUpdate();
    });
  }

  getUserData() {
    this.setState({ authState: AuthService.getLoginState()});
  }

  componentWillUnmount(): void {
    if(this.focusListener) {
      if(this.focusListener.remove) {
        this.focusListener.remove();
      }
      this.focusListener = null;
    }
  }
 

  getCourseData() {
    console.log("Fetch course data");
    if(this.fetchingCourseData) 
    {
      console.log("Already fetching course data");
      return;
    }

    this.fetchingCourseData = true;
    let cid = this.props.route.params.cid;
    if (typeof cid == 'string') {
      cid = parseInt(cid);
    }

    this.setState({hasChanges: false});

    CourseService.getCourse(cid).then(
      (courseData) => 
      {
        console.log("CourseData: ", courseData);

        this.savedCourseData = _.cloneDeep(courseData);

        this.setState({ courseData: courseData, renderCourseCombis: this.state.renderCourseCombis+1 }, () => {
          this.fetchingCourseData = false;
          
        });
      },
      (err) => {
        this.setState({ loadError: err});
        this.fetchingCourseData = false;
      });
  }

  onCourseNameChanged(newName: string) {
    this.state.courseData.name = newName;
    this.setState({ courseData: this.state.courseData });

    this.checkForChanges();
  }

  onCourseURLChanged(newURL: string) {
    this.state.courseData.cluburl = newURL;
    this.setState({ courseData: this.state.courseData });
    
    this.checkForChanges();
  }

  checkForChanges() {
      this.setState( { hasChanges: !_.isEqual(this.savedCourseData, this.state.courseData) });
  }

  onCancelChangesPressed() {
    this.setState({ courseData: this.savedCourseData }, () => {
      this.checkForChanges();
    });
  }

  onSaveChangesPressed()
  {
    CourseService.updateCourse(this.state.courseData.id, this.state.courseData).then((id) => {
      this.getCourseData();
    },
    (err) => {
      console.log(err);
      this.getCourseData();
    });
  }

  onEditCourseCombiPressed( courseCombi: CourseService_GetCourse_CC_Data)
  {
    console.log("Edit CC: ", courseCombi);
  }

  onAddCourseCombiPressed()
  {
    this.setState({ showAddCourseCombiData: true });
  }

  renderCourseCombiTable() {
    if(!this.state.courseData) {
      return;
    }

    let courseCombiTable = [];

    for(let i=0; i<this.state.courseData.course_combis.length; i++)
    {
      const courseCombi = this.state.courseData.course_combis[i];

      courseCombiTable.push(
        <Stack key={i}>
          <Stack flexDirection="row" borderWidth={1}>
            <LGText w={200} pl={4} borderRightWidth={1}>{courseCombi.tee_name}</LGText>
            <LGText w={80} borderRightWidth={1} textAlign='center'>{courseCombi.holes.length}</LGText>
            <LGText w={80} borderRightWidth={1} textAlign='center'>{courseCombi.tee_id}</LGText>
            <LGText w={80} borderRightWidth={1} textAlign='center'>{courseCombi.gender_id == 1 ? "M" : "F"}</LGText>
            <LGText w={80} borderRightWidth={1} textAlign='center'>{ CourseService.getCoursePar(courseCombi)}</LGText>
            <LGText w={80} borderRightWidth={1} textAlign='center'>{ courseCombi.cr}</LGText>
            <LGText w={80} borderRightWidth={1} textAlign='center'>{ courseCombi.slope }</LGText>
            <LGText w={80} textAlign='center' borderRightWidth={1}>{ CourseService.getCourseLength(courseCombi, LGUnit.meters) }</LGText>
            <Stack w={80} alignItems='center'><TouchableOpacity onPress={() => { this.onEditCourseCombiPressed(courseCombi); }}><MaterialCommunityIcons name="square-edit-outline" size={24} color={LGColors.textPrimary} /></TouchableOpacity></Stack>
          </Stack>
        </Stack>
      )
    }


    return (
      <Stack alignContent='center'>
        <Stack flexDirection="row" borderWidth={1}>
          <LGText w={200} pl={4} borderRightWidth={1}>Tee name</LGText>
          <LGText w={80} borderRightWidth={1} textAlign='center'>Holes</LGText>
          <LGText w={80} borderRightWidth={1} textAlign='center'>Tee ID</LGText>
          <LGText w={80} borderRightWidth={1} textAlign='center'>Gender</LGText>
          <LGText w={80} borderRightWidth={1} textAlign='center'>Par</LGText>
          <LGText w={80} borderRightWidth={1} textAlign='center'>CR</LGText>
          <LGText w={80} borderRightWidth={1} textAlign='center'>Slope</LGText>
          <LGText w={80} borderRightWidth={1} textAlign='center'>Length</LGText>
          <LGText w={80} textAlign='center'>Edit</LGText>
        </Stack>
        
        {courseCombiTable}

        <Stack flexDirection="row" borderLeftWidth={1} borderBottomWidth={1} borderRightWidth={1} alignItems='center' justifyContent='center'>
          <TouchableOpacity onPress={this.onAddCourseCombiPressed.bind(this)}><LGText w="100%">Add Course Combi</LGText></TouchableOpacity>
        </Stack>

      </Stack>
    );
  }

  onCourseCombiDataSubmitted(courseCombiData: CourseCombiInput, holeCount: number)
  {
    //console.log("CourseCombiData: ", courseCombiData);
    this.setState({ showAddCourseCombiData: false, courseCombiData: courseCombiData, showAddHoles: true, addCCHoleCount: holeCount });
  }

  onHolesSubmitted(holes: Array<HoleInput>)
  {
    console.log("Holes: ", holes);
    this.setState({ showAddHoles: false });

    CourseCombiService.addCourseCombi(this.state.courseCombiData, holes).then(
      (courseCombi) => {
        console.log("CourseCombi: ", courseCombi);
        this.getCourseData();
      }
    )
  }

  render() {
    

    return (
      <Stack bg="#FEE" h={this.state.windowDims.height}>
        <LGAdminHeader title="Admin: Course" navigation={this.props.navigation} /> 
        <AddCourseCombiDataModal 
          isVisible={this.state.showAddCourseCombiData}
          onDataSubmitted={(courseCombiData, holeCount) => this.onCourseCombiDataSubmitted(courseCombiData, holeCount)}
          courseData={this.state.courseData}
          onCanceled={() => this.setState({ showAddCourseCombiData: false })}
        />
        <AddHolesModal 
          isVisible={this.state.showAddHoles} 
          courseCombiData={this.state.courseCombiData} 
          onCanceled={() => this.setState({ showAddHoles: false })} 
          initialIndex={0}
          onSubmitHoles={(holes) => this.onHolesSubmitted(holes)} courseData={this.state.courseData}
          numberOfHoles={this.state.addCCHoleCount}
        />
        

        <Stack alignSelf="center" w="80%">
          { this.state.courseData &&
            <Stack>
              <Stack flexDirection='row'>
                <LGText w={200}>Course name</LGText>
                <Input w={400} value={this.state.courseData.name} onChangeText={(text) => { this.onCourseNameChanged(text); }} />
              </Stack>
              <Stack flexDirection='row'>
                <LGText w={200}>Course URL</LGText>
                <Input w={400} value={this.state.courseData.cluburl} onChangeText={(text) => { this.onCourseURLChanged(text); }} />
              </Stack>
              { this.state.hasChanges &&
                <Stack pt={8} pb={8} flexDirection="row">
                  <Button onPress={() => { this.onCancelChangesPressed(); }}>Cancel</Button>
                  <Button onPress={() => { this.onSaveChangesPressed(); }}>Save</Button>
                </Stack>
              }
              <Stack pt={16} alignSelf='center'>
                { this.state.renderCourseCombis && this.renderCourseCombiTable()}
              </Stack>
              
            </Stack>
          }
          
        </Stack>
      </Stack>
    );
  }
}
