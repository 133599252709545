import  { Stack, XStack, YStack, styled } from 'tamagui';

export const LGCenter = styled(Stack, {
  alignItems: 'center',
  justifyContent: 'center'
});

export const LGXStack = styled(XStack,{});

export const LGYStack = styled(YStack, {});

export const LGStack = styled(Stack, {});