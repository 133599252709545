import * as React from 'react';
//import { Text } from 'react-native';
import  { Text, styled } from 'tamagui';

export const LGText = styled(Text, {
  color: "$textPrimary",
  fontSize: "$7",
  fontWeight: "$3",
  fontFamily: "$body"
});

export function MonoText(props) {
  return <Text {...props} style={[props.style, { fontFamily: 'space-mono' }]} />;
}
