import { AdminTournamentData, AdminTournamentGroupDiff, AdminTournamentGroupEdit, AdminTournamentGroupEditBase, AdminTournamentGroupPlayer, AdminTournamentGroupPlayerDiff, AdminTournamentGroupPlayerInputEdit, AdminTournamentRound, AdminTournamentRoundDiff, AdminTournamentRoundEdit, AdminTournamentRoundEditBase } from "../types/AdminTypes";

export namespace AdminTournamentService {

   export function getTournamentDiff(origData: AdminTournamentData, newData: AdminTournamentData): Partial<AdminTournamentData> {
      let diff: Partial<AdminTournamentData> = {};

      Object.keys(origData).forEach(key => {
         if (key != 'rounds' && key != 'owner' && origData[key] !== newData[key]) {
            diff[key] = newData[key];
         }
      })

      return diff;
   }

   export function getRoundDiffs(origData: AdminTournamentData, newData: AdminTournamentData): AdminTournamentRoundDiff {

      let diff: AdminTournamentRoundDiff = {};

      // deleted rounds
      if (origData.rounds?.length > 0) {
         for (let origIndex = 0; origIndex < origData.rounds.length; ++origIndex) {
            const origRound = origData.rounds[origIndex];
            let bFound = false;
            if (newData.rounds?.length > 0) {
               for (let newIndex = 0; newIndex < newData.rounds.length; ++newIndex) {
                  const newRound = newData.rounds[newIndex];
                  if (origRound.id == newRound.id) {
                     let roundDiff : AdminTournamentRoundEditBase = { id: origRound.id};
                     Object.keys(origRound).forEach(
                        key => {
                           if (key != 'id' && key != 'course' && key != 'groups') {
                              if (origRound[key] != newRound[key]) {
                                 roundDiff[key] = newRound[key];
                              }
                           }
                        }
                     );
                     if(Object.keys(roundDiff).length > 1) {
                        
                        if(!diff.rounds_edit) {
                           diff.rounds_edit = [];
                        }
                        diff.rounds_edit.push(roundDiff);
                     }
                     bFound = true;
                     break;
                  }
               }
            }

            if (!bFound) {
               if (!diff.rounds_deleted) {
                  diff.rounds_deleted = [];
               }
               diff.rounds_deleted.push(origRound.id);
            }
         }
      }

      if (newData.rounds?.length > 0) {
         for (let newIndex = 0; newIndex < newData.rounds.length; ++newIndex) {
            const newRound = newData.rounds[newIndex];
            let bFound = false;
            if (origData.rounds?.length > 0) {
               for (let origIndex = 0; origIndex < origData.rounds.length; ++origIndex) {
                  if (newData.rounds[newIndex].id == origData.rounds[origIndex].id) {
                     bFound = true;
                     break;
                  }
               }
            }

            if (!bFound) {
               if (!diff.rounds_new) {
                  diff.rounds_new = [];
               }
               diff.rounds_new.push(newRound);
            }
         }
      }

      return diff;
   }

   export function getGroupDiffs(origData: AdminTournamentData, newData: AdminTournamentData): AdminTournamentGroupDiff {
      const origEntries = [];
      origData.rounds?.forEach((round) => {
         round.groups?.forEach((group) => {
            origEntries.push(group);
         })
      })

      const newEntries = [];
      newData.rounds?.forEach((round) => {
         round.groups?.forEach((group) => {
            newEntries.push(group);
         })
      })

      let diff: AdminTournamentGroupDiff = {};

      // deleted rounds
      if (origEntries.length > 0) {
         for (let origIndex = 0; origIndex < origEntries.length; ++origIndex) {
            const origEntry = origEntries[origIndex];
            let bFound = false;
            if (newEntries.length > 0) {
               for (let newIndex = 0; newIndex < newEntries.length; ++newIndex) {
                  const newEntry = newEntries[newIndex];
                  if (origEntry.id == newEntry.id) {
                     let entryDiff : AdminTournamentGroupEditBase = { id: origEntry.id};
                     Object.keys(origEntry).forEach(
                        key => {
                           if (key != 'id' && key != 'group_players') {
                              if (origEntry[key] != newEntry[key]) {
                                 entryDiff[key] = newEntry[key];
                              }
                           }
                        }
                     );
                     if(Object.keys(entryDiff).length > 1) {
                        
                        if(!diff.groups_edit) {
                           diff.groups_edit = [];
                        }
                        diff.groups_edit.push(entryDiff);
                     }
                     bFound = true;
                     break;
                  }
               }
            }

            if (!bFound) {
               if (!diff.groups_deleted) {
                  diff.groups_deleted = [];
               }
               diff.groups_deleted.push(origEntry.id);
            }
         }
      }

      if (newEntries.length > 0) {
         for (let newIndex = 0; newIndex < newEntries.length; ++newIndex) {
            const newEntry = newEntries[newIndex];
            let bFound = false;
            if (origEntries?.length > 0) {
               for (let origIndex = 0; origIndex < origEntries.length; ++origIndex) {
                  if (newEntries[newIndex].id == origEntries[origIndex].id) {
                     bFound = true;
                     break;
                  }
               }
            }

            if (!bFound) {
               if (!diff.groups_new) {
                  diff.groups_new = [];
               }
               diff.groups_new.push(newEntry);
            }
         }
      }

      return diff;
   }

   export function getGroupPlayerDiffs(origData: AdminTournamentData, newData: AdminTournamentData): AdminTournamentGroupPlayerDiff {
      const origEntries: Array<AdminTournamentGroupPlayer> = [];
      origData.rounds?.forEach((round) => {
         round.groups?.forEach((group) => {
            group.group_players?.forEach((groupPlayer) => {
               origEntries.push(groupPlayer);
            })
         })
      }
      )


      const newEntries: Array<AdminTournamentGroupPlayer> = [];
      newData.rounds?.forEach((round) => {
         round.groups?.forEach((group) => {
            group.group_players?.forEach((groupPlayer) => {
               newEntries.push(groupPlayer);
            })
         })
      }
      )

      let diff: AdminTournamentGroupPlayerDiff = {};

      // deleted rounds
      if (origEntries.length > 0) {
         for (let origIndex = 0; origIndex < origEntries.length; ++origIndex) {
            const origEntry = origEntries[origIndex];
            let bFound = false;
            if (newEntries.length > 0) {
               for (let newIndex = 0; newIndex < newEntries.length; ++newIndex) {
                  const newEntry = newEntries[newIndex];
                  if (origEntry.group_id == newEntry.group_id && origEntry.player.id == newEntry.player.id) {
                     let entryDiff : AdminTournamentGroupPlayerInputEdit = { player_id: origEntry.player.id, group_id: origEntry.group_id };
                     Object.keys(origEntry).forEach(
                        key => {
                           if (key != 'player' && key != 'group_id') {
                              if (origEntry[key] != newEntry[key]) {
                                 entryDiff[key] = newEntry[key];
                              }
                           }
                        }
                     );
                     if(Object.keys(entryDiff).length > 2) {
                        
                        if(!diff.groupPlayers_edit) {
                           diff.groupPlayers_edit = [];
                        }
                        diff.groupPlayers_edit.push(entryDiff);
                     }
                     bFound = true;
                     break;
                  }
               }
            }

            if (!bFound) {
               if (!diff.groupPlayers_deleted) {
                  diff.groupPlayers_deleted = [];
               }
               diff.groupPlayers_deleted.push({ player_id: origEntry.player.id, group_id: origEntry.group_id });
            }
         }
      }

      if (newEntries.length > 0) {
         for (let newIndex = 0; newIndex < newEntries.length; ++newIndex) {
            const newEntry = newEntries[newIndex];
            let bFound = false;
            if (origEntries?.length > 0) {
               for (let origIndex = 0; origIndex < origEntries.length; ++origIndex) {
                  if (newEntry.group_id == origEntries[origIndex].group_id && newEntry.player.id == origEntries[origIndex].player.id) {
                     bFound = true;
                     break;
                  }
               }
            }

            if (!bFound) {
               if (!diff.groupPlayers_new) {
                  diff.groupPlayers_new = [];
               }
               diff.groupPlayers_new.push(newEntry);
            }
         }
      }

      return diff;
   }
}