import React, { Profiler, Suspense } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import getEnvVars from './environment';
import SimpleBackdrop from './components/Backdrop';
import { LGStackNavigator, LGNavigationConfig } from './navigation/LGStackNavigator';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TamaguiProvider } from 'tamagui';
import tamaconfig from './tamagui.config';

console.log(getEnvVars);
console.log(getEnvVars().AuthBaseUrl);

const linking = {
  prefixes: ['http://localhost:19006', 'https://live-golf.de', 'http://192.168.128.107', 'http://192.168.128.107:19006', "https://golf.runi.de"],
  LGNavigationConfig,
};

export const apollo_client = new ApolloClient({
  uri: `${getEnvVars().ApiUrl}/graphql`,
  cache: new InMemoryCache(),
  credentials: 'include'
});

/*const nb_config = {
  dependencies: {
    'linear-gradient': LinearGradient,
  },
};*/

function onRender(id, phase, actualDuration, baseDuration, startTime, commitTime) {
  console.log("Actual Duration: ", actualDuration);
}

function App(props) {
  //const [isLoadingComplete, setLoadingComplete] = React.useState(false);

  // Load any resources or data that we need prior to rendering the app

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Suspense fallback={SimpleBackdrop()}>
        <NavigationContainer linking={linking}>
          <TamaguiProvider config={tamaconfig}>
            <LGStackNavigator />
          </TamaguiProvider>
        </NavigationContainer>
      </Suspense>
    </LocalizationProvider>
  );
}

export default App;

/*
<NativeBaseProvider config={nb_config}>
              <LGStackNavigator />
            </NativeBaseProvider>
*/

/*
<Profiler id="Sidebar" onRender={onRender}>
</Profiler>
*/