import { CommonActions } from "@react-navigation/native";
import { Component } from "react";
import { Pressable, StyleSheet } from "react-native";
import * as AuthService from '../services/AuthService';
import { MaterialIcons } from '@expo/vector-icons';
import { Button, Separator, Stack, Text } from 'tamagui';
import { LGColors } from "../constants/colors";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { globals } from "../global";
import * as Types from "../types/types";
import { LGCenter } from "../styles/StyledStacks";
import { LGText } from "../styles/StyledText";

type State = {
  showMenu: boolean;
  user: Types.LGUser | null;
}

type Props = {
  navigation: any;
  title: string;
}

export class LivegolfHeader extends Component<Props, State> {

  unsubscribeFocus;

  constructor(props: Props) {
    super(props);
    this.state = { showMenu: false, user: null }
  }

  componentDidMount(): void {
    //this.onAuthChanged();
    this.unsubscribeFocus = this.props.navigation.addListener('focus', () => {
      AuthService.addAuthListener(this.onAuthChanged.bind(this));
    });
    AuthService.fetchUserData().then(() => {
      // do nothing here
    },
      (err) => {
        // do nothing
      });
  }

  componentWillUnmount(): void {
    if (this.unsubscribeFocus) {
      this.unsubscribeFocus();
      this.unsubscribeFocus = null;
    }
    AuthService.removeAuthListener(this.onAuthChanged.bind(this));
  }

  onAuthChanged() {
    if (!this.isLoggedIn()) {
      this.setState({user: null});
      this.props.navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            { name: 'Home' }
          ],
        })
      );
      //this.props.navigation.navigate("Home");
    } else {
      this.setState({ showMenu: false, user: globals.userData });
      this.forceUpdate();
    }

  }

  toggleMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  toggleUserMenu() {
    //this.setState({ showMenu: !this.state.showMenu });
  }

  /*
  getMenuText() {
    const authState = AuthService.getLoginState();
    if (authState == 'verified') {
      return globals.sessionData.identity.traits.email;
    }

    if (authState == 'unverified') {
      return globals.sessionData.identity.traits.email + '(unv.)';
    }

    return "Login/Register";
  }
  */

  isLoggedIn() {
    const authState = AuthService.getLoginState();

    if (authState == 'unverified' || authState == 'verified') {
      return true;
    }

    return false;
  }

  onLogin() {
    this.props.navigation.navigate('Login');
  }

  onVerification() {
    this.props.navigation.navigate('Verification');
  }

  onLogout() {
    AuthService.logout().then(() => {
      this.setState({ showMenu: false });
      this.forceUpdate();
    })
  }

  onRegister() {
    this.props.navigation.navigate('Register');
  }

  onHiveClicked() {
    this.props.navigation.navigate('Home');
  }

  onAdminPushed() {
    this.props.navigation.navigate("AdminTournaments");
  }

  renderMenu() {
    /*
   menuBody: {
    padding: 10,
    zIndex: 1200
  },
  },
    */
    return (
      <Stack position="absolute" w="120px" right={0} top={50} bg={LGColors.primary2} p="2px" borderWidth={1} borderColor="#ddd" zIndex={1000}>
        <Stack bg={LGColors.primary}>

          {this.isLoggedIn() ? (
            <Stack p="8px" zIndex={1100}>
              {this.state.user.roles.includes("admin") &&
                <>
                <Text style={styles.menuItemText} onPress={this.onAdminPushed.bind(this)}>Admin</Text>
                <Separator alignSelf="stretch" vertical />
                </>
              }
              <Text style={styles.menuItemText} onPress={this.onLogout.bind(this)}>Logout</Text>
            </Stack>
          ) : (
            <Stack p="8px" space="2" zIndex={1100}>

              <Text fontSize="$7" onPress={this.onLogin.bind(this)}>Login</Text>

              
              <Text fontSize="$7" onPress={this.onRegister.bind(this)}>Register</Text>
              
              <Separator alignSelf="stretch" vertical />
              

              <Text fontSize="$7" onPress={this.toggleMenu.bind(this)}>Close</Text>

              
            </Stack>
          )}
        </Stack>

      </Stack>
    )
  }

  render() {
    
    return (
      <>
        <Stack h={40} bg={LGColors.primary2}>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center" flex={1}>
            <Pressable onPress={this.onHiveClicked.bind(this)}>
              <LGCenter flexDirection="row" w="48px">
                  <MaterialCommunityIcons name="golf-tee" size={24} color={LGColors.textPrimary} />
              </LGCenter>
            </Pressable>
            <Stack flex={1} alignItems="center" justifyContent="center">
              <LGText color={LGColors.textPrimary} fontSize="$8">{this.props.title}</LGText>
            </Stack>

            <Stack justifyContent="flex-end" flexDirection="row">
              <Stack alignItems="center" flexDirection="row">
                {this.state.user?.verified &&
                  <Pressable onPress={this.toggleUserMenu.bind(this)}>
                    <LGCenter flexDirection="row" w="48px">
                      <MaterialIcons size={24} name="account-circle" />
                    </LGCenter>
                  </Pressable>
                }
                {this.state.user && !this.state.user.verified &&
                  <Button chromeless onPress={this.toggleUserMenu.bind(this)}>
                    <MaterialIcons size={24} name="account-circle" />
                  </Button>
                }
                <Pressable onPress={this.toggleMenu.bind(this)}>
                  <LGCenter flexDirection="row" w="48px">
                    <MaterialIcons size={24} color={LGColors.textPrimary} name="menu" />
                  </LGCenter>
                </Pressable>
              </Stack>

            </Stack>


          </Stack>
        </Stack>
        {this.state.showMenu && (
          this.renderMenu()
        )}
      </>
    );
  }
}



const styles = StyleSheet.create({


  menuHeader: {
    padding: 10
  },
  closeButton: {
    alignSelf: 'flex-end',
    marginTop: 10
  },
  closeButtonText: {
    fontSize: 20
  },

  menuItem: {
    padding: 10
  },
  menuItemText: {
    fontSize: 16,
    color: LGColors.textPrimary
  },
  separator: {
    height: 1,
    backgroundColor: '#ddd',
    marginVertical: 10
  }
})

/*{

            linearGradient: {
              colors: [LGColors.primary2, LGColors.primary],
              start: [0, 0],
              end: [1, 0]
            }
          }*/