import React, { Component } from 'react';
import { Dimensions, Pressable, StyleSheet, View } from 'react-native';
import { Stack, Text, XStack } from 'tamagui';
import { LGColors } from '../constants/colors';
import { LGText } from '../styles/StyledText';

type State = {
  
}

type Props = {
  count: number;
  selected: number;
  width: number;
  onChange: (value: number) => void;
}

export default class LGPagination extends Component<Props, State> {

  

  constructor(props) {
    super(props);

    this.state = {
      
    }

  }

  /*componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if(prevProps != this.props) {
      this.forceUpdate();
    }
  }*/

  selectPage(value: number) {
    if(value < this.props.count && value >= 0) {
      this.props.onChange(value);
    }
  }

  render() {

    const boxWidth = 30;
    const { count, selected, onChange, width } = this.props;

    const pagWidth = Math.min(width, Dimensions.get('window').width);

    //console.log("width: ", width);

    const numBoxes = Math.min(count, Math.floor(pagWidth / boxWidth)-2);

    //console.log("NumBoxes: ", numBoxes);
    var numArray : Array<number> = [];

    if(numBoxes >= count) {
      for(var i=0; i<count; ++i) {
        numArray.push(i+1);
      }
    }
    else if(numBoxes < 9) {
      
    }
    else
    {
      if(selected < 4 || selected > count-4) {
        for(var i=0; i<5; ++i) {
          numArray.push(i+1);
        }
        numArray.push(0);
        for(var i=count-5; i<count; ++i) {
          numArray.push(i+1);
        }
      }
      else if(selected == 4) {
        for(var i=0; i<7; ++i) {
          numArray.push(i+1);
        }
        numArray.push(0);
        
        numArray.push(count-2);
        numArray.push(count-1);
        numArray.push(count);
      }
      else if(selected == 5) {
        for(var i=0; i<8; ++i) {
          numArray.push(i+1);
        }
        numArray.push(0);
        
        numArray.push(count-1);
        numArray.push(count);
      }
      else if(selected == count-6) {
        numArray.push(1);
        numArray.push(2);
        numArray.push(0);
        for(var i=count-8; i<count; ++i) {
          numArray.push(i+1);
        }
      }
      else if(selected == count-5) {
        numArray.push(1);
        numArray.push(2);
        numArray.push(3);
        numArray.push(0);
        for(var i=count-7; i<count; ++i) {
          numArray.push(i+1);
        }
      }
      else if(selected == count-4) {
        numArray.push(1);
        numArray.push(2);
        numArray.push(3);
        numArray.push(4);
        numArray.push(0);
        for(var i=count-6; i<count; ++i) {
          numArray.push(i+1);
        }
      }
      else
      {
        numArray.push(1);
        numArray.push(2);
        numArray.push(0);
        for(var i=selected-2; i<selected+3; ++i) {
          numArray.push(i+1);
        }
        numArray.push(0);
        numArray.push(count-1);
        numArray.push(count);
      }
      // 1,2,...,6,7,8,...,10,11
    }

    var boxArray = [];

    for(var i=0; i<numArray.length; ++i) {
      const curNum : number = numArray[i];
      const isSelected = ((curNum-1) == selected);

      if(curNum == 0) {
        boxArray.push(
          <Stack width={boxWidth} height={boxWidth} borderColor={LGColors.textPrimary} borderWidth={1} bg={isSelected ? "#AAA" : LGColors.tableBackgroundEven}>
            <Stack justifyContent="center" alignItems="center">
              <LGText>...</LGText>
            </Stack>
          </Stack>
        );
      }
      else {
        boxArray.push(
          <Pressable onPress={() => this.selectPage(curNum-1)}>
            <Stack width={boxWidth} height={boxWidth} borderColor={LGColors.textPrimary} borderWidth={1} bg={isSelected ? "#AAA" : LGColors.tableBackgroundEven}>
              <Stack justifyContent="center" alignItems="center">
                <LGText fontWeight={isSelected ? "bold" : "normal"}>{curNum}</LGText>
              </Stack>
            </Stack>
          </Pressable>
        );
      }
    }
    const left = "<";
    const right = ">";
    return (
      <Stack w={pagWidth} my="8px">
        <XStack>
          <Pressable onPress={() => this.selectPage(selected-1)}>
            <Stack width={boxWidth} height={boxWidth} borderColor={LGColors.textPrimary} borderWidth={1} bg={LGColors.tableBackgroundEven}>
              <Stack justifyContent="center" alignItems="center">
                <LGText fontWeight="bold">{left}</LGText>
              </Stack>
            </Stack>
          </Pressable>
          {boxArray}
          <Pressable onPress={() => this.selectPage(selected+1)}>
            <Stack width={boxWidth} height={boxWidth} borderColor={LGColors.textPrimary} borderWidth={1} bg={LGColors.tableBackgroundEven}>
              <Stack justifyContent="center" alignItems="center">
                <LGText fontWeight="bold">{right}</LGText>
              </Stack>
            </Stack>
          </Pressable>
        </XStack>
      </Stack>
    )
  }
}
