import { apollo_client } from "../App";
import { gql } from '@apollo/client';
import { GroupInput, GroupPlayerData, ScoreData } from "../types/types";
import { AdminTournamentGroupEdit, AdminTournamentGroupInputNew } from "../types/AdminTypes";

const getGroupPlayersWithScoresGQL = gql`
    query ($id: String!) {
        group(id: $id) {
          starting_time
          tee
          round_id
          round {
            id
            tournament {
              id
              name
              active_round_index
              mode
              status
            }
          }
          group_players {
            group {
              id
              tee
            }
            start_index
            tee_id
            status
            hcp
            gender_id
            score_type
            team_id
            course_combi {
              id
              name
              holes {
                par
                hcp
                length
              }
              course_id
              tee_id
              tee_name
              cr
              slope
              gender_id
              unit
            }
            player {
              firstname
              lastname
              id
            }
            scores {
              hole
              score
              extra_data
            }
          }
        }        
    }
`;

// add group graphql query
const createGroupGQL = gql`
    mutation ($group: GroupInput!) {
      addGroup(groupData: $group) {
            id
        }
    }
`

const modifyGroupGQL = gql`
    mutation ($group: GroupModify!) {
      modifyGroup(groupData: $group) {
            id
        }
    }
`;

const deleteGroupGQL = gql`
    mutation ($id: String!) {
        deleteGroup(id: $id)
    }
`;

export namespace GroupService {
  export async function getGroupPlayersWithScores(gid: string) : Promise<GroupPlayerData[]> {
    const {data} = await apollo_client.query({query: getGroupPlayersWithScoresGQL, variables: { id: gid}})

    return data.group;
  }

  export async function createGroup(group: GroupInput) {
    const {data} = await apollo_client.mutate({mutation: createGroupGQL, variables: {group}})

    return Promise.resolve(data.addGroup.id);
  }

  export async function modifyGroup(group: AdminTournamentGroupEdit) {
    const {data} = await apollo_client.mutate({mutation: modifyGroupGQL, variables: {group}})

    return Promise.resolve(data.modifyGroup);
  }

  export async function deleteGroup(groupId: string) {
    await apollo_client.mutate({mutation: deleteGroupGQL, variables: {id: groupId}});
  }
}