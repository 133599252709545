import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { PlayerService } from '../../services/PlayerService';
import { AdminPlayer } from '../../types/AdminTypes';
import { Stack, Text, Input, Spinner, Button } from 'tamagui';
import { FlatList, GestureResponderEvent, Modal, Pressable } from 'react-native';

const PlayerModal = ({ isVisible, onClose, onPlayerSelect, resetOnClose = false }) => {
   const [searchText, setSearchText] = useState('');
   const [matchingPlayers, setMatchingPlayers] = useState(Array<AdminPlayer>);
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      const debouncedFetchPlayers = debounce(fetchPlayers, 1000);
      debouncedFetchPlayers(searchText); // Call fetchPlayers with the initial searchText
      return () => {
         debouncedFetchPlayers.cancel(); // Cancel the debounced function on component unmount
      }
   }, [searchText]);

   // Debounce the API call to avoid excessive requests
   function fetchPlayers(text) {
      if (text.length < 3) {
         setIsLoading(false);
         setMatchingPlayers([]);
         return;
      }
      console.log("Fetch Players: ", text);
      setIsLoading(true);

      PlayerService.getPlayersLike(0, 40, text).then((data) => {
         setMatchingPlayers(data);
         setIsLoading(false);
      })
         .catch(error => {
            console.error(error);
            setIsLoading(false);
         });
   };

   const handleSearchInputChange = (text) => {
      setSearchText(text);
   }

   const handlePlayerSelect = (event: GestureResponderEvent, playerId) => {

      event.preventDefault();
      for (let i = 0; i < matchingPlayers.length; ++i) {
         if (matchingPlayers[i].id == playerId) {
            onPlayerSelect(matchingPlayers[i]);
            break;
         }
      }

      setIsLoading(false);
      if (resetOnClose) {
         setSearchText("");
      }
      onClose();
   }

   return (
      <Modal animationType="slide" transparent={true} visible={isVisible} onRequestClose={onClose}>
         <Stack flex={1}>
          <Stack flex={1} justifyContent="center" alignItems="center">
            <Stack bg="#EEE" p={4} w={600} h={300} borderRadius={6} borderWidth={1} shadowOffset={{ width: 3, height: 3 }} shadowColor="#000" shadowOpacity={0.25} shadowRadius={4} alignItems='center'>
               <Stack justifyContent='center' alignItems='center'>
                  <Input
                     placeholder="Enter player name"
                     autoFocus={true}
                     value={searchText}
                     onChangeText={handleSearchInputChange}
                     w="300px"
                  />
                  {isLoading && <Spinner color='blue' />
                     ||
                     <Stack p="2px" flexDirection='row'><Text w="200px">Name</Text><Text w="60px">Hcp</Text></Stack>
                  }
                  { !isLoading && matchingPlayers.length == 0 &&
                     <Stack justifyContent='center' alignItems='center'><Text>No players found</Text></Stack>
                  }
                  { !isLoading && matchingPlayers.length > 0 &&
                     <FlatList style={{ maxHeight: 200}} data={matchingPlayers} renderItem={({ item }) =>
                        <Pressable key={item.id} onPress={(event) => handlePlayerSelect(event, item.id)}>
                           <Stack p="2px" flexDirection='row'><Text w="200px" fontSize="$6">{`${item.firstname} ${item.lastname}`}</Text> <Text w="60px" fontSize="$6">{`${item.last_hcp}`}</Text></Stack>
                        </Pressable>
                     }
                     />
                  }

               </Stack>
            </Stack>
            <Button onPress={onClose}>
               <Text>Close</Text>
            </Button>
         </Stack>
         </Stack>
      </Modal>
   );
};

export default PlayerModal;


/*
export const ChoosePlayerModal = function() {
    return (
        <Modal isOpen={showAddPlayerModal} onClose={() => setShowAddPlayerModal(false)} size="lg">
        <Modal.Content maxH="600">
          <Modal.CloseButton />
          <Modal.Header>Add Player</Modal.Header>
          <Modal.Body>
          
            <ScrollView h="400px">
              <HStack>
                <Center w="100px"><Text alignSelf="center" fontSize="md">Player:</Text></Center>

                <Input
                  h="36px"
                  bg={LGColors.inputSecondary}
                  borderColor="#AAA"
                  fontSize="md"
                  w="300px"
                  value={modalPlayerInput}
                  onChangeText={(value) => { onChange(value); }}
                  placeholder='Enter search string...'
                />
                
                
              </HStack>
              <ScrollView ml="100px" w="300px" h="200px" bg="#F9F">
                  {playerResult}
                  { !playerResult && !searchingPlayers &&
                    <Text>Enter search string...</Text>
                  }
                  { playerResult && playerResult.length == 0 &&
                    <Text>No results found!</Text>
                  }
                  { searchingPlayers &&
                    <Spinner size="sm" />
                  }
                </ScrollView>
            </ScrollView>
            
          </Modal.Body>
          <Modal.Footer>
            
              <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                setShowAddPlayerModal(false);
              }}>
                Cancel
              </Button>
            
          </Modal.Footer>
          
        </Modal.Content>
        
      </Modal>
    )
}
*/