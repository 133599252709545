import { FlexAlignType, StyleProp, TextStyle } from "react-native";
import i18n from "../i18n";
import { TournamentPlayer } from 'livegolf_shared';



export class ScoreBoardColumn {
    protected _label: string;
    protected _width: number;
    protected _align: FlexAlignType = 'center';
    protected t;

    constructor(width: number) {
        this._width = width;

        
    }

    label() {
        return i18n.t(this._label);
    }

    width() {
        return this._width;
    }

    align() {
        return this._align;
    }

    adjustFontSizeToFit() : boolean {
        return false;
    }

    text(index: number, playerData: TournamentPlayer, tied: number) {
        return "***text***";
    }
}

export class ScoreBoardColumnThru extends ScoreBoardColumn {
    protected _label: string = "ScoreBoardColumnLabelThru";

    text(index: number, playerData: TournamentPlayer, tied: number) {
        if(playerData.isDQ()) {
            return "DQ";
        }
        if(playerData.hasMissedCut()) {
            return "MC";
        }

        return playerData.tournamentPlayerData.through == 17 ? "F" : ((typeof playerData.tournamentPlayerData.through == 'number') ? (playerData.tournamentPlayerData.through+1).toString() : playerData.tournamentPlayerData.through);
    }
}

export class ScoreBoardColumnPos extends ScoreBoardColumn {
    protected _label: string = 'ScoreBoardColumnLabelPos';

    text(index: number, playerData: TournamentPlayer, tied: number) {
        return tied ? `T${tied}` : (index+1).toString();
    }
}

export class ScoreBoardColumnName extends ScoreBoardColumn {
    protected _label: string = 'ScoreBoardColumnLabelName';
    protected _align: FlexAlignType = 'flex-start';

    adjustFontSizeToFit() : boolean {
        return true;
    }

    text(index: number, playerData: TournamentPlayer, tied: number) {
        return playerData.tournamentPlayerData.firstname + " " + playerData.tournamentPlayerData.lastname;
    }
}

export class ScoreBoardColumnTotal extends ScoreBoardColumn {
    protected _label: string = 'ScoreBoardColumnLabelTotal';

    text(index: number, playerData: TournamentPlayer, tied: number) {
        return playerData.tournamentPlayerData.total_to_par.toString();
    }
}

export class ScoreBoardColumnScore extends ScoreBoardColumn {
    protected _label: string = 'ScoreBoardColumnLabelScore';

    text(index: number, playerData: TournamentPlayer, tied: number) {
        return playerData.tournamentPlayerData.totalscore.toString();
    }
}

export class ScoreBoardColumnNet extends ScoreBoardColumn {
    protected _label: string = "ScoreBoardColumnLabelNet";

    text(index: number, playerData: TournamentPlayer, tied: number) {
        return playerData.tournamentPlayerData.points_net.toString();
    }
}

export class ScoreBaordColumnGross extends ScoreBoardColumn {
    protected _label: string = "ScoreBoardColumnLabelGross";

    text(index: number, playerData: TournamentPlayer, tied: number) {
        return playerData.tournamentPlayerData.points_gross.toString();
    }
}


export class ScoreBoardColumnHcp extends ScoreBoardColumn {
    protected _label: string = "ScoreBoardColumnLabelHcp";

    text(index: number, playerData: TournamentPlayer, tied: number) {
        return playerData.tournamentPlayerData.hcp.toString();
    }
}

export class ScoreBoardColumnPCHcp extends ScoreBoardColumn {
    protected _label: string = "ScoreBoardColumnLabelPCHcp";

    text(index: number, playerData: TournamentPlayer, tied: number) {
        return playerData.tournamentPlayerData.rounds[0].pchcp.toString();
    }
}