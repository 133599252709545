import { FieldPath, Control, UseFormGetValues, Controller } from "react-hook-form";
import { AdminTournamentData } from "../../types/AdminTypes";
import { Stack, Text, Input } from "tamagui";

export const TIntInput = ({ name, control, getValues, label, minValue, maxValue, labelW, inputW, bg, flexDirection }:
    {
      name: FieldPath<AdminTournamentData>,
      control: Control<AdminTournamentData, any>,
      getValues: UseFormGetValues<AdminTournamentData>,
      label: string,
      minValue?: number,
      maxValue?: number,
      labelW: string,
      inputW: string,
      bg: string,
      flexDirection?: "row" | "column"
    }) => {
  
    let initialValue = getValues(name);
  
    const validateInput = function (value, allowNull = false) {
      let newValue = parseInt(value);

      if(allowNull && value == "") {
        return value;
      }
  
      if (isNaN(newValue) || newValue < minValue || newValue > maxValue) {
        let lastValue = parseInt(initialValue.toString());
        if(isNaN(lastValue) || lastValue < minValue || lastValue > maxValue)
        {
          return minValue;
        }
        return initialValue;
      }
  
      if (minValue) {
        newValue = Math.max(newValue, minValue);
      }
  
      if (maxValue) {
        newValue = Math.min(newValue, maxValue);
      }
      initialValue = newValue;
  
      return newValue;
    }
  
    return (
      <Stack alignItems="center" flexDirection={flexDirection}>
        <Text fontSize="$6" w={labelW} fontWeight={"bold"}>{label}</Text>
        <Controller control={control} name={name}
          render={({ field }) => (
            <Input
              h="36px"
              bg={bg}
              borderColor="#AAA"
              fontSize="$6"
              w={inputW}
              value={String(field.value)}
              onChangeText={(value) => field.onChange(validateInput(value, true))}
              onBlur={(event) => { field.onChange(validateInput(event.nativeEvent.text)) }}
            />
          )}
        />
      </Stack>
    )
  }