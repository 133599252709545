import { TournamentPlayer } from "livegolf_shared";
import React, { Component } from "react";
import { FlexAlignType, TouchableOpacity, View, Text, ScrollView } from "react-native";
import i18n from "../i18n";
import { CourseService } from "../services/CourseService";
import { getFormattedScoreTime, getScoreName } from "../services/ScoreService";
import { styles } from "../styles/styles";
import * as Types from "../types/types";
import { ScoreBoardColumn } from "./ScoreBoardColumns";
import Themes from "./ScoreTableThemes";
import { Dimensions } from 'react-native';
import { CourseCombiService } from "../services/CourseCombiService";
import { ClientCourseCombi } from "../classes/CourseCombiClass";

let windowWidth = Dimensions.get('window').width;

type Props = {
    scoreBoardColumns: Array<ScoreBoardColumn>;
    themeName: string;
    playerData: TournamentPlayer;
    scoreBoardIndex: number;
    tied: number;
}

type State = {
    playerDetailVisible: boolean;
    course_combis: Array<ClientCourseCombi>;
    course_combis_loaded: boolean;
}

export class ScoreBoardPlayerRow extends Component<Props, State> {

    constructor(props) {
      super(props);

      this.state= {
          playerDetailVisible: false,
          course_combis: [],
          course_combis_loaded: false
      }
    }

    componentDidMount(): void {
      Dimensions.addEventListener("change", this.onScreenChanged.bind(this))
      this.fetchCourseCombis();        
    }

    onScreenChanged({window, screen}) {
      windowWidth = window.width;
      this.forceUpdate();
      return {window, screen};
    }

    async fetchCourseCombis() {
        this.setState({course_combis_loaded: false});
        for(let i=0; i<this.props.playerData.tournamentPlayerData.rounds.length; ++i) {
            if(this.getCourseCombi(this.props.playerData.tournamentPlayerData.rounds[i].course_combi_id) == null) {
                await CourseCombiService.getCourseCombi(this.props.playerData.tournamentPlayerData.rounds[i].course_combi_id).then((course_combi) => {
                    if(course_combi) {
                        this.state.course_combis.push(course_combi)
                    }
                }) 
            }
        }

        this.setState({ course_combis_loaded: true});
    }

    getCourseCombi(course_combi_id: number) {
        if(this.state.course_combis) {
            for(let i=0; i<this.state.course_combis.length; ++i) {
                if(this.state.course_combis[i].id === course_combi_id) {
                    return this.state.course_combis[i];
                }
            }
        }

        return null;
    }

    getTimeFront9(playerId: number, roundIndex: number) {
        return 0;
    }

    getTimeBack9(playerId: number, roundIndex: number) {
        return 0;
    }

    getCourseSummaryData(courseData: ClientCourseCombi, startIndex: number, count: number) : Types.ScoreTableCourseData {
        let coursePar = 0;
        let courseLength = 0;
        for(let i=startIndex; i<startIndex+count; ++i) {
            coursePar += courseData.holes[i].par;
            courseLength += courseData.getHoleLength(i, Types.LGUnit.meters);
        }

        return { par: coursePar, length: courseLength };
    }

    renderHoleScores() {
        const tPlayerData = this.props.playerData;
        
        const currentTheme = Themes[this.props.themeName];
        const cellStyle = [ styles.detailCell, { borderColor: currentTheme.ScoreDetailBorderColor, borderRightWidth: 0, borderBottomWidth: 0 }];
        const textStyle = [ styles.tableText, { color: currentTheme.ScoreDetailTextColor}];

        const roundRows = [];
        const showTime = tPlayerData.tournamentPlayerData.rounds.length == 1;
        const showCourseData = showTime;
        for(let i=0; i<tPlayerData.tournamentPlayerData.rounds.length; ++i) {
          roundRows.push(
            <View key={`round_${i}`} style={ cellStyle }>
                <Text style={ textStyle }>{`${i18n.t("Round")} ${i+1}`}</Text>
            </View>
          )
        }

        var rowNameColumn = (
          <View key={this.props.playerData.tournamentPlayerData.player_id} style={{backgroundColor: currentTheme.ScoreDetailLabelBackgroundColor}}>
            <View style={ cellStyle }>
              <Text style={ textStyle }>Hole</Text>
            </View>
            { showCourseData &&
              <>
                <View style={ cellStyle }>
                  <Text style={ textStyle }>Par</Text>
                </View>
                <View style={ cellStyle }>
                  <Text style={ textStyle }>Length</Text>
                </View>
              </>
            }
            { roundRows }
            { showTime && 
              <View style={ cellStyle }>
                <Text style={ textStyle }>Time</Text>
              </View>
            }
          </View>
        );

        
        const courseData : ClientCourseCombi = this.getCourseCombi(tPlayerData.tournamentPlayerData.rounds[0].course_combi_id);
        const columns0_9 = this.renderScoreColumns(0, 9, tPlayerData, cellStyle, textStyle);
        const columns0_9_summary = this.renderScoreColumnsSummary(0,9,tPlayerData, courseData, currentTheme, columns0_9.totalRoundScores, "Out");
        const columns10_18 = this.renderScoreColumns(9,18,tPlayerData, cellStyle, textStyle);
        const columns10_18_summary = this.renderScoreColumnsSummary(9,18,tPlayerData, courseData, currentTheme, columns10_18.totalRoundScores, "In");

        const totalRoundScores = [];
        for(let i=0; i<columns0_9.totalRoundScores.length+columns10_18.totalRoundScores.length; ++i) {
          totalRoundScores.push(columns0_9.totalRoundScores[i] + columns10_18.totalRoundScores[i]);
        }
        const columnsTotal_summary = this.renderScoreColumnsSummary(0,18,tPlayerData, courseData, currentTheme, totalRoundScores, "Tot");
    
        return (
          <ScrollView contentContainerStyle={{maxWidth: windowWidth}} horizontal={true}>
            <View style={{ backgroundColor: Themes[this.props.themeName].ScoreDetailBackgroundColor, flexDirection: 'row'}}>
              <View style={{ 
                height: '100%',
                borderTopWidth: 1, 
                borderLeftWidth: 1, 
                width: this.props.scoreBoardColumns[0].width(), 
                backgroundColor: Themes[this.props.themeName].ScoreDetailLeftColumnColor}}>
              </View>
      
              <View style={{ flexDirection: 'row', borderRightWidth: 1, borderBottomWidth: 1, borderColor: Themes[this.props.themeName].ScoreDetailBorderColor}}>
                { rowNameColumn }
                { columns0_9.columns }
                { columns0_9_summary }
                { columns10_18.columns }
                { columns10_18_summary }
                { columnsTotal_summary }
              </View>
            </View>
          </ScrollView>
        )
      }

      renderScoreColumns(startIndex: number, endIndex: number, playerData: TournamentPlayer, cellStyle, textStyle) : {columns: Array<JSX.Element>, totalRoundScores: Array<number>}{
        const scoreColumns = [];
        const totalScores = [];
        
        const showTime =  playerData.tournamentPlayerData.rounds.length == 1;
        const showCourseData = showTime;
        
        const courseData : ClientCourseCombi = this.getCourseCombi(playerData.tournamentPlayerData.rounds[0].course_combi_id);

        for(var i=startIndex; i<endIndex; ++i) {
          const roundScores = [];
          let curScore = playerData.getScoreForRoundAndHole(0, i);

          for(let rs=0; rs<playerData.tournamentPlayerData.rounds.length; ++rs) {
            const curCourseData : ClientCourseCombi = this.getCourseCombi(playerData.tournamentPlayerData.rounds[rs].course_combi_id);
            curScore = playerData.getScoreForRoundAndHole(rs, i);
            const scorename = getScoreName(curScore ? curScore.score : 0, curCourseData.holes[i].par);
            const scoreColor = Themes[this.props.themeName].ScoreColors[scorename];
            
            if(curScore) {
              if(totalScores.length <= rs){
                  totalScores.push(0);
              }
              totalScores[rs] += curScore.score;
            }
            else {
              totalScores.push(0);
            }
            roundScores.push(
              <View key={`round_${rs}`} style={ [ cellStyle, { backgroundColor: scoreColor.background} ] }>
                <Text style={ [ textStyle, { color: scoreColor.text } ] }>{ curScore ? curScore.score : '--' }</Text>
              </View>
            )
          }
          // TODO: show time for all rounds? Or hide if more than 1 round?
          scoreColumns.push(
            (
              <View key={`holescorecolumn${startIndex}_${endIndex}_${this.props.playerData.tournamentPlayerData.player_id}_${i}`} style={{ alignItems: 'center', width: 48}}>
                <View style={ cellStyle }>
                  <Text style={ textStyle }>{i+1}</Text>
                </View>
                { showCourseData &&
                  <>
                    <View style={ cellStyle }>
                      <Text style={ textStyle }>{ courseData.holes[i].par }</Text>
                    </View>
                    <View style={ cellStyle }>
                      <Text style={ textStyle }>{ courseData.getHoleLength(i, Types.LGUnit.meters) }</Text>
                    </View>
                  </>
                }
                { roundScores }
                { showTime && 
                  <View style={ cellStyle }>
                    <Text style={ textStyle }>{ getFormattedScoreTime(curScore?.time_created) }</Text>
                  </View>
                }
              </View>
            )
          )
        }

        return { columns: scoreColumns, totalRoundScores: totalScores };
      }

      renderScoreColumnsSummary(startIndex: number, endIndex: number, playerData: TournamentPlayer, courseData: ClientCourseCombi, currentTheme, summaryScores: Array<number>, label: string) : JSX.Element {
        const cellStyle = [ styles.detailCell, { borderColor: currentTheme.ScoreDetailBorderColor, borderRightWidth: 0, borderBottomWidth: 0 }];
        const textStyle = [ styles.tableText, { color: currentTheme.ScoreDetailTextColor}];
        const roundTotalScoreRows = [];
        const showTime = playerData.tournamentPlayerData.rounds.length == 1;
        const showCourseData = showTime;
        for(let i=0; i<playerData.tournamentPlayerData.rounds.length; ++i) {
            roundTotalScoreRows.push(
                <View key={`rSCS_${playerData.tournamentPlayerData.player_id}_${i}`} style={ cellStyle }>
                    <Text style={ textStyle }>{ summaryScores[i] }</Text>
                </View>
            );
        }
    
        var back9data = this.getCourseSummaryData(courseData, startIndex, endIndex-startIndex);
        return (
          <View key={`holescorecolumn_${startIndex}_${endIndex}_${this.props.playerData.tournamentPlayerData.player_id}`} style={{ alignItems: 'center', backgroundColor: currentTheme.ScoreDetailLabelBackgroundColor}}>
            <View style={ cellStyle }>
              <Text style={ textStyle }>{label}</Text>
            </View>
            { showCourseData &&
              <>
                <View style={ cellStyle }>
                  <Text style={ textStyle }>{ back9data.par }</Text>
                </View>
                <View style={ cellStyle }>
                  <Text style={ textStyle }>{ back9data.length }</Text>
                </View>
              </>
            }
            { roundTotalScoreRows }
            { showTime && 
              <View style={ cellStyle }>
                <Text style={ textStyle }>{ this.getTimeBack9(playerData.tournamentPlayerData.player_id, 0) }</Text>
              </View>
            }
          </View>
        )
      }
      render() {

        const columns = [];
        
        for(var i=0; i<this.props.scoreBoardColumns.length; ++i) {
            const column = this.props.scoreBoardColumns[i];
            columns.push(
            (
                <View key={`scpr_${this.props.playerData.tournamentPlayerData.player_id}_${i}`} style={
                    { flex: column.width() ? null : 1, 
                      justifyContent: 'center',
                      alignItems: column.align(),
                      borderLeftWidth: 1,
                      borderColor: Themes[this.props.themeName].BorderColor,
                      borderTopWidth: 1,
                      width: column.width(),
                      backgroundColor: Themes[this.props.themeName].PlayerCellColor,
                      borderRightWidth: i == this.props.scoreBoardColumns.length-1 ? 1 : 0,
                      height: 32,
                    }}
                >
                  <Text numberOfLines={1} style={styles.nameText }>{ column.text(this.props.scoreBoardIndex, this.props.playerData, this.props.tied) }</Text>
                </View>
            )
            )
        }
        return (
            <View key={`SBPR_${this.props.playerData.tournamentPlayerData.player_id}`}>
                <TouchableOpacity key={`sdjfh_${this.props.playerData.tournamentPlayerData.player_id}`} onPress={ () => { this.setState({playerDetailVisible: !this.state.playerDetailVisible}); this.forceUpdate() }}>
                    <View style={
                        { 
                        flexDirection: 'row', 
                        justifyContent: 'center', 
                        alignContent: 'center', 
                        alignItems: 'center', 
                        //borderBottomWidth: playerIndex == this.state.playersData.length-1 ? 1 : 0, 
                        borderColor: Themes[this.props.themeName].BorderColor 
                        }}
                    >
                        { columns }
                    </View>
                </TouchableOpacity>
                { this.state.playerDetailVisible && this.state.course_combis_loaded && this.renderHoleScores() }
            </View>
        )
    }
}