import { gql } from "@apollo/client";
import { ScoreInput } from "../types/types";
import { apollo_client } from "../App";

const DoubleEagleColor = { backgroundColor: '#16a1a4', textColor: '#000000' };
const EagleColor = { backgroundColor: '#5bc236', textColor: '#000000' };
const BirdieColor = { backgroundColor: '#32b1e4', textColor: '#000000' };
const ParColor = { backgroundColor: '#dddddd', textColor: '#000000' };
const BogeyColor = { backgroundColor: '#f7b942', textColor: '#000000' };
const DoubleBogeyColor = { backgroundColor: '#ee5a00', textColor: '#000000' };
const TripleBogeyColor = { backgroundColor: '#85357c', textColor: '#000000' };
const OtherColor = { backgroundColor: '#9f5924', textColor: '#FFF' };

const sendScoresGQL = gql`
    mutation ($scores: [ScoreInput]) {
        addScores(scoreData: $scores) {
          score
        }        
    }
`;


export async function sendScores( scores: Array<ScoreInput>) {
  const { data } = await apollo_client.mutate({ mutation: sendScoresGQL, variables: {scores: scores} });

  return data;
}

export function getScoreName(score: number, par: number) {
  if(score > 0)
  {
    if(score - par == -4) {
      return 'Condor';
    }
    else if(score - par == -3) {
      return 'DoubleEagle';
    }
    else if(score - par == -2) {
      return 'Eagle';
    }
    else if(score - par == -1) {
      return 'Birdie';
    }
    else if(score == par) {
      return 'Par';
    }
    else if(score - par == 1) {
      return 'Bogey';
    }
    else if(score - par == 2) {
      return 'DoubleBogey';
    }
    else if(score - par == 3) {
      return 'TripleBogey';
    }
    else if(score -par > 3) {
        return 'Other';
    }
  }
  
  return 'default';
}

export function getScoreColor(score: number, par: number) {
    if(score - par == -3) {
      return DoubleEagleColor;
    }
    else if(score - par == -2) {
      return EagleColor;
    }
    else if(score - par == -1) {
      return BirdieColor;
    }
    else if(score == par) {
      return ParColor;
    }
    else if(score - par == 1) {
      return BogeyColor;
    }
    else if(score - par == 2) {
      return DoubleBogeyColor;
    }
    else if(score - par == 3) {
      return TripleBogeyColor;
    }
    else if(score -par > 3) {
        return OtherColor;
    }
    
    return { backgroundColor: null, textColor: null };
  }

export function getFormattedScoreTime(tc: number) {
  if(tc == null) {
    return '--:--';
  }
  const timestamp = new Date( tc );
  
  return String(timestamp.getHours()).padStart(2, '0') + ':' + String(timestamp.getMinutes()).padStart(2, '0');
  //return timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  //return timestamp.Format("dd/mm/yyyy hh:MM:ss");
}