import { Controller } from "react-hook-form"
import { SelectList } from "react-native-dropdown-select-list"
import { LGXStack } from "../../styles/StyledStacks";
import { LGText } from "../../styles/StyledText";
import React from "react";

export const ArraySelect = ({ control, valueArray, controlName, onChanged }: { control: any, valueArray: Array<{key: number, value: string}>, controlName: string, onChanged: Function }) => {

  return (
      <Controller control={control} name={controlName}
        render={({ field }) => {
          return (
          <SelectList 
              setSelected={(val) => onChanged(val)}
              data={valueArray} 
              save="key"
              search={false}
              defaultOption={{key: field.value, value: valueArray.find((valEntry) => field.value === valEntry.key).value}}
              fontFamily="$mono"
              inputStyles={{fontSize: 16}}
              placeholder="--"
              boxStyles={{borderWidth: 0}}
          />
        );
        }}
      />
  )
}
