import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { StyleSheet, View, Dimensions, NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';
import { RootStackParamList } from '../navigation/LGStackNavigator';
import { LivegolfHeader } from '../components/LivegolfHeader';
import * as AuthService from '../services/AuthService';
import { Button, Input, ScrollView, Stack, Text } from 'tamagui';


type Props = NativeStackScreenProps<RootStackParamList, 'Verification'>;

const windowHeight = Dimensions.get('window').height;

type State = {
  code: string;
  verified: boolean;
  error_msg?: string;
}

export default class VerificationScreen extends React.Component<Props, State> {

  private focusListener;

  constructor(props) {
    super(props);
    this.state = { code: "", verified: false }
  }

  componentDidMount(): void {
    this.focusListener = this.props.navigation.addListener("focus", this.onFocus.bind(this));
    this.onFocus();
  }

  componentWillUnmount(): void {
    if (this.focusListener) {
      this.focusListener();
      this.focusListener = null;
    }
  }

  onFocus() {
    this.getCodeFromFlowId();
  }

  getCodeFromFlowId() {
    const flowId = this.props.route?.params?.flow;
    if(flowId) {
        AuthService.getVerificationCode(flowId).then((response) => {
        //console.log("Response: ", response);

        if(response.ui?.nodes && response.ui?.nodes.length > 0) {
            for(let i=0; i<response.ui.nodes.length; ++i) {
            const curNode = response.ui.nodes[i];

            if(curNode.type == 'input' && curNode.attributes?.type == 'text' && curNode.attributes?.name == 'code') {
                this.setState({ code: curNode.attributes.value});
                break;
            }
            }
        }
        })
    }
  }

  onCodeChanged(e: NativeSyntheticEvent<TextInputChangeEventData>) {
    this.setState({code: e.nativeEvent.text});
  }

  async onVerifyPressed() {
    let flowId = this.props.route?.params?.flow;
    console.log("FlowID: ", flowId);
    if(!flowId) {
        flowId = await AuthService.getFlowID('verification');
        /*AuthService.verifyEmail({ method: 'code', code: this.state.code, email: 'carone@runi.de'}).then((data) => {
            console.log("Data: ", JSON.stringify(data));
            this.setState({ verified: true, error_msg: null});
        },
        (err) => {
            console.log("Data err: ", JSON.stringify(err));
            this.setState({ verified: true, error_msg: "Verification code expired?"});
        })
        */
    }
    //else {
        AuthService.postVerificationData(flowId, this.state.code).then((data) => {
        //AuthService.postVerificationData(flowId, this.state.code).then((data) => {
        console.log("Data: ", JSON.stringify(data));
        this.setState({ verified: true, error_msg: null});
        },
        (err) => {
        console.log("Data err: ", JSON.stringify(err));
        this.setState({ verified: true, error_msg: "Verification code expired?"});
        })
    //}
  }

  GotoHomeScreen() {
    this.props.navigation.navigate("Home");
  }

  render() {
    const bodyText = this.props.route?.params?.fromRegistration ? 
    "An email with a verification code has been sent to your email-address. Please enter the code from the email here." : 
    "Send the verification code to verify your email-address.";
    return (
      <View style={styles.container}>
        <LivegolfHeader title="Email Verification" navigation={this.props.navigation} />
        <ScrollView style={styles.container} contentContainerStyle={{ height: windowHeight }}>
          { !this.state.verified &&
          <>
          <Text>{bodyText}</Text>
          <View style={{ alignItems: 'center', marginTop: 32 }}>
            <Stack space={4} width={400} alignItems="center">
              <Input onChange={this.onCodeChanged.bind(this)} value={this.state.code} width="100%" placeholder="Code" />
              <Button width={140} disabled={this.state.code == ""} onPress={this.onVerifyPressed.bind(this)}>Verify</Button>
            </Stack>
          </View>
          </>
          ||
          <View>
            { this.state.error_msg && 
            <Text>{this.state.error_msg}</Text>
            ||
            <>
            <Text>You have successfully verified your email address.</Text>
              <Button onPress={this.GotoHomeScreen.bind(this)}>Go to Home Screen</Button>
            </>
            }
            
          </View>
        }
        </ScrollView>
      </View>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: '#FFF',
  },
  developmentModeText: {
    marginBottom: 20,
    color: 'rgba(255,255,255,0.4)',
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center',
  }
});
