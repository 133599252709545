import { apollo_client } from "../App";
import { gql } from '@apollo/client';
import { AdminTournamentRoundInputNew } from "../types/AdminTypes";
import { RoundModify } from "../types/types";



const modifyRoundGQL = gql`
    mutation ($roundData: RoundModify!) {
      modifyRound(roundData: $roundData) {
            id
            date
            course_id
        }
    }
`;

const addRoundGQL = gql`    
    mutation ($roundData: RoundInput!) {
      addRound(roundData: $roundData) {
            id
        }
    }
`;

const deleteRoundGQL = gql`
    mutation ($id: Int!) {
        deleteRound(id: $id)
    }
`;

export namespace RoundService {

  export async function modifyRound(roundData: RoundModify) {
    const {data} = await apollo_client.mutate({mutation: modifyRoundGQL, variables: {roundData}})

    return Promise.resolve(data.modifyRound);
  }

  export async function createRound(roundData: AdminTournamentRoundInputNew) {
    const {data} = await apollo_client.mutate({mutation: addRoundGQL, variables: {roundData}})

    return Promise.resolve(data.addRound.id);
  }

  export async function deleteRound(roundId: number) {
    await apollo_client.mutate({mutation: deleteRoundGQL, variables: {id: roundId}});
  }

}