import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    container: { flex: 1, padding: 4, marginTop: 24 },
    //header: { height: 40, backgroundColor: Themes[this.props.themeName].HeaderBackgroundColor },
    headerText: { paddingHorizontal: 8, fontSize: 16, fontFamily: 'roboto' },
    tableText: { padding: 2, paddingHorizontal: 8, fontSize: 16, fontFamily: 'roboto_light', fontWeight: '100' },
    nameText: { padding: 2, paddingHorizontal: 8, fontSize: 18, fontFamily: 'roboto_bold' },
    text: { textAlign: 'center', fontWeight: '100', fontSize: 20 },
    dataWrapper: { marginTop: -1 },
    col: { height: 28, backgroundColor: '#F00', flexDirection: 'column'},
    btn: { padding: 0, margin: 0, marginLeft: 1, backgroundColor: '#c8e1ff' },
    btnText: { textAlign: 'center', fontSize: 16 },
    detailCell: { width: '100%', borderWidth: 1, alignItems: 'center'}
  });
  