import { gql } from '@apollo/client';
import { CourseData, LGUnit } from '../types/types';
import { apollo_client } from "../App";
import { AdminTournamentCourse } from '../types/AdminTypes';
import { CourseCombi, CourseInput } from 'livegolf_shared/lib/generated/graphql';
import _ from 'lodash';

const getCoursesLikeGQL = gql`
  query courses($offset: Int!, $limit: Int!, $name: String) {
    courses(offset: $offset, limit: $limit, like: $name) {
        id
        name
        cluburl
        default_tee_male
        default_tee_female
        course_combis {
            id
            tee_id
            gender_id
            tee_name
            name
            cr
            slope
            unit
        }
    }
}`

export type CourseService_GetCourse_CC_Data = {
  id: number
  tee_id: number
  gender_id: number
  tee_name: string
  name: string
  cr: number
  slope: number
  unit: number
  holes: Array<{
    hole_index: number
    par: number
    hcp: number
    length: number
  }>
}

export type CourseService_GetCourse_Data = {
  id: number
  name: string
  default_tee_male: number
  default_tee_female: number
  cluburl: string
  course_combis: Array<CourseService_GetCourse_CC_Data>
}


const getCourseGQL = gql`
  query course($id: Int!) {
    course(id: $id) {
        id
        name
        default_tee_male
        default_tee_female
        cluburl
        course_combis {
            id
            tee_id
            gender_id
            tee_name
            name
            cr
            slope
            unit
            holes {
                hole_index
                par
                hcp
                length
            }
        }
    }
  }
`;

// name: String!
//default_tee_male: Int!
//default_tee_female: Int!
//cluburl: String!
const createCourseGQL = gql`
  mutation add_course($course: CourseInput!) {
    add_course(course: $course) {
        id
    }
  }
`;

const updateCourseGQL = gql`
  mutation update_course($courseId: Int!, $courseData: CourseInput!) {
    updateCourse(course_id: $courseId, course_data: $courseData) {
      id
    }
  }
`;

export namespace CourseService {

    export async function createCourse(courseData: CourseInput) : Promise<number> {
        const { data } = await apollo_client.mutate({ mutation: createCourseGQL, variables: { course: courseData } });
        return Promise.resolve(data.add_course.id);
    }

    export async function getCourse(courseId: number) : Promise<CourseService_GetCourse_Data> {
        const { data } = await apollo_client.query({ query: getCourseGQL, variables: { id: courseId } });
        return Promise.resolve(data.course);
    }

    export async function getCoursesLike(offset: number, limit: number, name: string) : Promise<Array<AdminTournamentCourse>> {
        const { data } = await apollo_client.query({ query: getCoursesLikeGQL, variables: { offset, limit, name} });

        if(data) {
            return Promise.resolve(data.courses);
        }
        else {
            return Promise.reject("Not found");
        }
    }

    export async function updateCourse(courseId: number, courseData: CourseInput) {

      // strip all __typename entries from courseData
      courseData = _.omit(courseData, "__typename");
      courseData = _.omit(courseData, "id");
      courseData = _.omit(courseData, "course_combis");

      const { data } = await apollo_client.mutate({ mutation: updateCourseGQL, variables: { courseId, courseData } });

      return Promise.resolve(data.updateCourse.id);
    }

    export function getCoursePar(courseCombi: CourseService_GetCourse_CC_Data)
    {
      let coursePar = 0;

      for(let i=0; i<courseCombi.holes.length; i++) {
        coursePar += courseCombi.holes[i].par;
      }

      return coursePar;
    }
    
    export function getCourseLength(courseCombi: CourseService_GetCourse_CC_Data, unit: LGUnit) {
        let courseLength = 0;
    
        for(let i=0; i<courseCombi.holes.length; i++) {
          courseLength += courseCombi.holes[i].length;
        }

        if(unit == 0 || courseCombi.unit == 0)
        {
            return 9999;
        }

        if(unit == courseCombi.unit)
        {
          return courseLength;
        }
        else if(unit == 1)
        {
          // yards to meter
          return Math.round(courseLength * 0.9144);
        }
    
        return Math.round(courseLength / 0.9144);
      }
}