import { apollo_client } from "../App";
import { gql } from '@apollo/client';
import { AdminPlayer, AdminTournamentGroupPlayer, AdminTournamentGroupPlayerInputNew } from "../types/AdminTypes";
import { Gender, GroupPlayerInput, GroupPlayerModify } from "../types/types";


const getPlayersLikeGQL = gql`
    query ($offset: Int!, $limit: Int!, $like: String!) {
        players(limit:$limit, offset:$offset, like: $like) {
          id
          firstname
          lastname
          last_hcp
          gender_id
          golfclub_id
        }        
    }
`;

const addGroupPlayerGQL = gql`
    mutation addGroupPlayer($GroupPlayer: GroupPlayerInput!) {
        addGroupPlayer(GroupPlayer: $GroupPlayer) {
          player_id
          group_id
          start_index
          tee_id
          status
          hcp
          gender_id
          score_type
          team_id
          course_combi_id
        }
    }
`

const modifyGroupPlayerGQL = gql`
    mutation modifyGroupPlayer($GroupPlayer: GroupPlayerModify!) {
        modifyGroupPlayer(GroupPlayer: $GroupPlayer) {
          player_id
          group_id
          start_index
          tee_id
          status
          hcp
          gender_id
          score_type
          team_id
          course_combi_id
        }
    }
`

/*
  
*/
export namespace PlayerService {
  export async function getPlayersLike(offset: number, limit: number, like: string) : Promise<Array<AdminPlayer>>{

    const { data } = await apollo_client.query({ query: getPlayersLikeGQL, variables: { offset, limit, like } })

    console.log("Players: ", data);
    return data.players;
  }

  export async function createGroupPlayer(groupPlayer: GroupPlayerInput) : Promise<AdminTournamentGroupPlayer>{
    const { data } = await apollo_client.mutate({ mutation: addGroupPlayerGQL, variables: { GroupPlayer: groupPlayer } });

    return Promise.resolve(data.addGroupPlayer);
  }

  export async function modifyGroupPlayer(groupPlayer: GroupPlayerModify) : Promise<void>{
    const { data } = await apollo_client.mutate({ mutation: modifyGroupPlayerGQL, variables: { GroupPlayer: groupPlayer } });

    return Promise.resolve();
  }
}