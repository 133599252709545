import { NativeStackScreenProps } from '@react-navigation/native-stack';
import * as React from 'react';
import { Dimensions, ScaledSize, EmitterSubscription, VirtualizedList, ListRenderItemInfo, Modal, TouchableOpacity } from 'react-native';
import { RootStackParamList } from '../../navigation/LGStackNavigator';
import * as AuthService from '../../services/AuthService';
import { CourseService } from '../../services/CourseService';
import { LGAdminHeader } from '../../components/LGAdminHeader';
import * as AuthTypes from '../../types/AuthTypes';
import { LGColors } from '../../constants/colors';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { AdminTournamentCourse } from '../../types/AdminTypes';
import { Button, Input, Stack, Text } from 'tamagui';
import { LGText } from '../../styles/StyledText';

type AdminCourseList = Array<AdminTournamentCourse>;
type Props = NativeStackScreenProps<RootStackParamList, 'AdminCourses'>;

type State = {
  authState: AuthTypes.AuthState;
  courseList: AdminCourseList;
  windowDims: ScaledSize;
  showAddCourseModal: boolean;
  newCourseName: string;
  newCourseURL: string;
}

export default class AdminCoursesScreen extends React.Component<Props, State> {
  private fetchingCourseList = false;
  private focusListener;
  private dimsSubcription: EmitterSubscription;
  private pendingFetchOffset: number = 0;
  private pendingFetchLimit: number = 0;
  private currentCoursesFetched: number = 0;
  private ref = React.createRef();

  constructor(props) {
    super(props);

    this.state= { 
      authState: 'not_logged_in', 
      courseList: [], 
      windowDims: Dimensions.get('window'),
      showAddCourseModal: false,
      newCourseName: "",
      newCourseURL: ""
    };
  }

  componentDidMount(): void {
    
    this.dimsSubcription = Dimensions.addEventListener("change", this.onDimsChanged.bind(this));

    const { navigation } = this.props;
    this.focusListener = navigation.addListener("focus", () => {
      this.getUserData();
    });  

    this.getUserData();
  }

  onDimsChanged({ window, screen }) {
    //console.log("dims changed: ", window);
    this.setState({ windowDims: Dimensions.get('window') }, () => {
      this.forceUpdate();
    });
  }

  getUserData() {
      AuthService.fetchUserData().then(() => {
        this.setState({ authState: AuthService.getLoginState()});

        this.tryFetchCourseList(0, 20);
      },
      (err) => {
        this.setState({ authState: 'not_logged_in'});
        this.tryFetchCourseList(0, 20);
      });    
  }

  componentWillUnmount(): void {
    if(this.focusListener) {
      if(this.focusListener.remove) {
        this.focusListener.remove();
      }
      this.focusListener = null;
    }
  }

  tryFetchCourseList(offset: number, limit: number) {
    if(this.fetchingCourseList) {
      if(offset > this.pendingFetchOffset) {
        console.log("Queue fetch");
        this.pendingFetchOffset = offset;
        this.pendingFetchLimit += limit;
      }
    }
    else {
      this.fetchCourseList(offset, limit);
    }
  }

  fetchCourseList(offset: number, limit: number) {
    if(this.fetchingCourseList) {
      return;
    }
    this.pendingFetchLimit = 0;
    this.pendingFetchOffset = 0;
    this.fetchingCourseList = true;
    CourseService.getCoursesLike(offset, limit, null).then((courseList) => {

      const newCourseList : AdminCourseList = (courseList != null && courseList.length > 0) ? this.state.courseList.concat(courseList) : this.state.courseList;

      if(newCourseList.length == 0) {
        this.setState({ courseList: [] }, () => {
          this.forceUpdate();
        });

        return;
      }

      newCourseList.sort((a,b) => { return a.id - b.id }); //.reverse();

      let currentID = -1;
      const orderedFiltered = newCourseList.filter((item) => {
        if(item.id != currentID) {
          currentID = item.id;
          return true;
        }
        return false;
      })

      

      this.currentCoursesFetched = offset + limit;
      this.setState({ courseList: orderedFiltered }, () => {
        this.forceUpdate();
      });
      this.afterFetchedCourses();
    },
    (err) => {
      this.afterFetchedCourses();
    })
  }

  afterFetchedCourses() {
    this.fetchingCourseList = false;

      if(this.pendingFetchOffset > 0) {
        this.fetchCourseList(this.pendingFetchOffset, this.pendingFetchLimit);
      }
  }

  onRequestVerificationPressed() {

  }

  onCoursePressed(cid: number) {
    this.props.navigation.navigate("AdminCourse", { cid: cid});
  }

  courseListEndReached(info) {
    console.log("End reached");

    
    this.tryFetchCourseList(this.currentCoursesFetched, 20);
  }

  renderCourseItem(data : ListRenderItemInfo<AdminTournamentCourse>) {
    
    const item : AdminTournamentCourse = data.item;
    
    const bgColor = "#FFE";
    let courseName = "***";
    //let dateMillis = Date.now();

    
    if(item.course_combis && item.course_combis.length > 0) {
      //if(item.course_combis[0].course?.name) {
      //  courseName = item.rounds[0].course.name;
      //}
    }

    
    //console.log(data);
    return (
      <TouchableOpacity style={{ borderBottomWidth: 1}} onPress={() => { this.onCoursePressed(item.id)}} >
      <Stack bg={bgColor} flexDirection='row' h={32} alignItems='center'>
        <Stack flex={1}>
          <LGText px={8} fontSize="$7" fontWeight={'$3'}>{item.name}</LGText>
        </Stack>
        
        <Stack width={300} h="100%" justifyContent='center' alignItems='center' borderLeftWidth={1}>
          <LGText textAlign="center" w="100%" fontSize="$7">{item.cluburl}</LGText>
        </Stack>
        <Stack width={120} h="100%" justifyContent='center' alignItems='center' borderLeftWidth={1}>
          <LGText textAlign="center" w="100%" fontSize="$7">{item.course_combis.length}</LGText>
        </Stack>
        <Stack w={40} h="100%" justifyContent='center' alignItems='center' borderLeftWidth={1}>
          <Stack><MaterialCommunityIcons name="delete-forever" size={24} color={LGColors.textPrimary} /></Stack>
        </Stack>
      </Stack>
      </TouchableOpacity>
    );
  }

  getItem(data: any, index: number): AdminTournamentCourse {
    return data[Object.keys(data)[index]];
  }

  getItemCount() {
    return Object.keys(this.state.courseList).length;
  }

  keyExtractor(item: AdminTournamentCourse, index: number) : string {
    return String(index);
  }

  renderNoCourses() {
    return (<Stack justifyContent="center" alignItems="center"><Text fontSize="$7" fontWeight="$3">No courses found!</Text></Stack>)
  }

  showAddCourseModal(show: boolean) {
    this.setState({showAddCourseModal: show});

  }

  onAddClicked() {
    this.showAddCourseModal(false);

    if(this.state.newCourseName != "" && this.state.newCourseURL != "") {
      CourseService.createCourse({ name: this.state.newCourseName, cluburl: this.state.newCourseURL, default_tee_female: 0, default_tee_male: 0}).then((course_id) => {
        
        this.setState({ courseList: [], newCourseName: "", newCourseURL: ""}, () => {
          //this.tryFetchCourseList(0, 20);
          this.onCoursePressed(course_id);
          
        });        
      });
    }
  }

  onCancelClicked() {
    this.showAddCourseModal(false);
  }

  render() {
    

    return (
      
      <Stack bg="#FEE" h={this.state.windowDims.height}>
        <LGAdminHeader title="Admin: Courses" navigation={this.props.navigation} /> 
        <Button onPress={ () => { this.showAddCourseModal(true)}}>Add Course</Button> 
        <Stack alignSelf="center" w="80%" flex={1} >
          <Stack h={32}>
            <Stack w="100%" h={32} bg="#88F" flex={1} alignItems="center" flexDirection="row">
              <Stack flex={1} h={32} justifyContent='center' borderBottomWidth={1}>
                <LGText pl={8}  fontSize="$7" fontWeight="$3">Name</LGText>
              </Stack>
              <Stack w={300} h={32} justifyContent="center" alignItems="center" borderBottomWidth={1} borderLeftWidth={1}>
                <LGText textAlign="center" w="100%" fontSize="$7" fontWeight="$3">Homepage</LGText>
              </Stack>
              <Stack w={120} h={32} justifyContent="center" alignItems="center" borderBottomWidth={1} borderLeftWidth={1}>
                <LGText textAlign="center" w="100%" fontSize="$5" fontWeight="$3">CourseCombis</LGText>
              </Stack>
              <Stack w={40} h={32} justifyContent="center" alignItems="center" borderBottomWidth={1} borderLeftWidth={1}>
                <Text textAlign="center" w="100%" fontSize="$8"><MaterialCommunityIcons name="delete-forever" size={24} color={LGColors.textPrimary} /></Text>
              </Stack>
            </Stack>
          </Stack>
          <VirtualizedList
            initialNumToRender={10}
            data={this.state.courseList}
            renderItem={this.renderCourseItem.bind(this)}
            
            getItemCount={this.getItemCount.bind(this)}
            getItem={this.getItem.bind(this)}
            keyExtractor={this.keyExtractor.bind(this)}
            ListEmptyComponent={this.renderNoCourses.bind(this)}
            onEndReachedThreshold={0.4}
            onEndReached={this.courseListEndReached.bind(this)}
            
          />
        </Stack>
        <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.showAddCourseModal}
        onRequestClose={() => {
          //Alert.alert('Modal has been closed.');
          this.showAddCourseModal(false);
        }}>
          <Stack flex={1}>
          <Stack flex={1} justifyContent="center" alignItems="center">
            <Stack bg="#EEE" w={600} h={300} borderRadius={6} borderWidth={1} shadowOffset={{ width: 3, height: 3 }} shadowColor="#000" shadowOpacity={0.25} shadowRadius={4}>
              <Stack w="100%" h={48} borderTopRightRadius={6} borderTopLeftRadius={6} justifyContent="center" alignItems="center" bg="#CCDDCC" borderBottomColor={"#AABBAA"} borderBottomWidth={1}>
                <LGText>Add Course</LGText>
              </Stack>
              <Stack p={16} flex={1}>
                <Stack flexDirection="row" h={32}>
                  <LGText w={200}>Course Name</LGText>
                  <Input flex={1} h={32} value={this.state.newCourseName} onChange={(e) => this.setState({newCourseName: e.nativeEvent.text})} />
                </Stack>
                <Stack flexDirection="row" h={32} mt={8}>
                  <LGText w={200}>URL</LGText>
                  <Input flex={1} h={32} value={this.state.newCourseURL} onChange={(e) => this.setState({newCourseURL: e.nativeEvent.text})} />
                </Stack>
              </Stack>
              <Stack justifyContent="flex-end" h={48} bg="#CCDDCC" alignItems='center' flexDirection="row" borderBottomLeftRadius={6} borderBottomRightRadius={6} borderTopColor={"#AABBAA"} borderTopWidth={1}>
                <Button w={120} h={32} onPress={() => this.onCancelClicked()} marginRight={8}>Cancel</Button>
                <Button w={120} h={32} disabled={this.state.newCourseName == "" || this.state.newCourseURL == ""} onPress={() => this.onAddClicked()} marginRight={8}>Add</Button>
              </Stack>
            
            </Stack>
          </Stack>
          </Stack>
      </Modal>

            
          
      
      </Stack>
    );
  }
}
