import { CourseCombi } from "livegolf_shared/lib/generated/graphql";
import { LGUnit } from "../types/types";


export type ClientCourseCombi = CourseCombi & 
{
    getHoleLength(holeIndex: number, unit: LGUnit): number;
}

export const createClientCourseCombi = (courseCombi: CourseCombi) : ClientCourseCombi => {

    const ccObject = {
        ...courseCombi,
        getHoleLength(holeIndex: number, unit: LGUnit): number
        {
            if(courseCombi.unit == LGUnit.yards)
            {
                return unit == LGUnit.yards ? courseCombi.holes[holeIndex].length : Math.round(courseCombi.holes[holeIndex].length * 0.9144);
            } 
            
            // meters
            return unit == LGUnit.yards ? Math.round(courseCombi.holes[holeIndex].length / 0.9144 ) : courseCombi.holes[holeIndex].length;
        }
    }

    return ccObject;
}

