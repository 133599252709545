
import { FieldPath, Control, UseFormGetValues, Controller } from "react-hook-form";
import { AdminTournamentData } from "../../types/AdminTypes";
import { Input } from "tamagui";
import { LGStack } from "../../styles/StyledStacks";
import { LGText } from "../../styles/StyledText";

export const TStringInput = ({ name, control, getValues, minChars = 0, inputW, bg }:
    {
      name: FieldPath<AdminTournamentData>,
      control: Control<AdminTournamentData, any>,
      getValues: UseFormGetValues<AdminTournamentData>,
      minChars?: number,
      inputW: string,
      bg: string
    }) => {
  
    let initialValue = getValues(name);
  
    const validateInput = function (value) {
      if (value.length >= minChars) {
        initialValue = value;
      }
      else {
        value = initialValue;
      }
  
      return value;
    }
  
    return (
      <Controller control={control} name={name}
        render={({ field }) => (
          <Input
            h="36px"
            bg={bg}
            borderColor="#AAA"
            fontSize="$5"
            w={inputW}
            value={String(field.value)}
            onChangeText={(value) => field.onChange(value)}
            onBlur={(event) => { field.onChange(validateInput(event.nativeEvent.text)) }}
          />
        )}
      />
    )
  }