import { CommonActions } from "@react-navigation/native";
import { Component } from "react";
import {Pressable, StyleSheet,  TouchableOpacity, View } from "react-native";
import * as AuthService from '../services/AuthService';
import { MaterialIcons } from '@expo/vector-icons';

import { LGColors } from "../constants/colors";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { AuthState } from "../types/AuthTypes";
import { Button, Stack, Text } from "tamagui";
import { LGText } from "../styles/StyledText";

type State = {
  showMenu: boolean;
  authState: AuthState;
}

type Props = {
  navigation: any;
  title: string;
}

export class LGAdminHeader extends Component<Props, State> {

  unsubscribeFocus;

  constructor(props: Props) {
    super(props);
    this.state = { showMenu: false, authState: 'not_logged_in' }
  }

  componentDidMount(): void {
    //this.onAuthChanged();
    this.unsubscribeFocus = this.props.navigation.addListener('focus', () => {
      AuthService.addAuthListener(this.onAuthChanged.bind(this));
    });

    AuthService.fetchUserData().then((userData) => {
      if(!userData) {
        this.props.navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [
              { name: 'Home' }
            ],
          })
        );  
      }
    },
      (err) => {
        // TODO: not logged in not okay in admin. Could there be a popup for logging back in?
        this.props.navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [
              { name: 'Home' }
            ],
          })
        );
      });
  }

  componentWillUnmount(): void {
    if (this.unsubscribeFocus) {
      this.unsubscribeFocus();
      this.unsubscribeFocus = null;
    }
    AuthService.removeAuthListener(this.onAuthChanged.bind(this));
  }

  onAuthChanged() {
    console.log("OnAuthChanged");
    if (!this.isLoggedIn()) {
      this.props.navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            { name: 'Home' }
          ],
        })
      );
      //this.props.navigation.navigate("Home");
    } else {
      this.setState({ showMenu: false, authState: AuthService.getLoginState() });
      this.forceUpdate();
    }

  }

  toggleMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  toggleUserMenu() {
    //this.setState({ showMenu: !this.state.showMenu });
  }

  isLoggedIn() {
    const authState = AuthService.getLoginState();

    if (authState == 'unverified' || authState == 'verified') {
      return true;
    }

    return false;
  }

  onLogout() {
    AuthService.logout().then(() => {
      this.setState({ showMenu: false });
      this.forceUpdate();
    })
  }

  onCoursesPressed()
  {
    this.props.navigation.navigate('AdminCourses');
  }

  onHomeClicked() {
    this.props.navigation.navigate('AdminTournaments');
  }

  render() {
    //console.log(this.state.authState);
    return (
      <>
        <Stack h={40} w="100%" bg={LGColors.primary2}>
          <Stack justifyContent="space-between" alignItems="center" flex={1} flexDirection="row">
            <Stack flex={1} justifyContent="space-between" alignItems="center" flexDirection="row">
              <Button chromeless onPress={this.onHomeClicked.bind(this)}>
                <MaterialCommunityIcons name="golf-tee" size={24} color={LGColors.textPrimary} />
              </Button>
            </Stack>
            
            <Stack flex={1} alignItems="center" justifyContent="center" flexDirection="row">
              <Text numberOfLines={1} color={LGColors.textPrimary} fontSize="$8" fontFamily="$body">{this.props.title}</Text>
            </Stack>
  
            <Stack flex={1} justifyContent="flex-end" alignItems="center" flexDirection="row">
            
              <Stack alignItems="center" flexDirection="row">
              
                {this.state.authState == 'verified' &&
                  <Button chromeless onPress={this.toggleUserMenu.bind(this)}>
                    <MaterialCommunityIcons name="account-circle" size={24} color={LGColors.textPrimary} />
                    
                  </Button>
                }
                {this.state.authState == 'unverified' &&
                  <Button chromeless onPress={this.toggleUserMenu.bind(this)}>
                    <MaterialCommunityIcons name="account-circle" size={24} color={LGColors.textPrimary} />
                  </Button>
                }
                <Button chromeless onPress={this.toggleMenu.bind(this)}>
                  <MaterialCommunityIcons name="menu" size={24} color={LGColors.textPrimary} />
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {this.state.showMenu && (
          <View style={styles.menuContainer}>
            <View style={styles.menu}>
              <View style={styles.menuBody}>
                {this.isLoggedIn() ? (
                  <Stack>
                    <Pressable style={styles.menuItem} onPress={this.onCoursesPressed.bind(this)}>
                      <LGText style={styles.menuItemText}>Courses</LGText>
                    </Pressable>
                    <Pressable style={styles.menuItem} onPress={this.onLogout.bind(this)}>
                      <LGText style={styles.menuItemText}>Logout</LGText>
                    </Pressable>
                  </Stack>
                ) : (
                  <>
                    <View style={styles.separator} />
                    <TouchableOpacity style={styles.menuItem} onPress={this.toggleMenu.bind(this)}>
                      <Text style={styles.menuItemText}>Close</Text>
                    </TouchableOpacity>
                  </>
                )}
              </View>
            </View>
          </View>
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
    menuContainer: {
      position: 'absolute',
      right: 0,
      top: 50,
      backgroundColor: LGColors.primary2,
      padding: 2,
      borderWidth: 1,
      borderColor: '#ddd',
      zIndex: 1000
    },
    menu: {
      backgroundColor: LGColors.primary
    },
    menuHeader: {
      padding: 10
    },
    closeButton: {
      alignSelf: 'flex-end',
      marginTop: 10
    },
    closeButtonText: {
      fontSize: 20
    },
    menuBody: {
      padding: 10,
      zIndex: 1200
    },
    menuItem: {
      padding: 10
    },
    menuItemText: {
      fontSize: 16,
      color: LGColors.textPrimary
    },
    separator: {
      height: 1,
      backgroundColor: '#ddd',
      marginVertical: 10
    }
    })