import { AdminTournamentData, AdminTournamentGroup, AdminTournamentGroupInputNew, AdminTournamentGroupPlayer, AdminTournamentGroupPlayerInputNew, AdminTournamentRound } from "../types/AdminTypes";
import { GroupModify, TournamentInput, GroupPlayerModify, RoundModify } from "../types/types";
import { GroupService } from "./GroupService";
import { PlayerService } from "./PlayerService";
import { RoundService } from "./RoundService";
import { TournamentService } from "./TournamentService";

export namespace SaveTournamentService {
    export async function saveModifiedTournament(oldTournamentData: AdminTournamentData, newTournamentData: AdminTournamentData): Promise<boolean> {

        return new Promise(async (resolve, reject) => {
            await updateTournament(oldTournamentData, newTournamentData);
            if (newTournamentData.rounds?.length > 0) {
                for (let newRoundIndex = 0; newRoundIndex < newTournamentData.rounds.length; newRoundIndex++) {
                    const newRound = newTournamentData.rounds[newRoundIndex];
                    let bFound = false;
                    for (let oldRoundIndex = 0; oldRoundIndex < oldTournamentData.rounds.length; oldRoundIndex++) {
                        if (newRound.id == oldTournamentData.rounds[oldRoundIndex].id) {
                            await handleExistingRound(oldTournamentData.rounds[oldRoundIndex], newRound);
                            bFound = true;
                            break;
                        }
                    }
                    if (!bFound) {
                        // new round, create it
                        const addedRound = await RoundService.createRound(newRound);
                        if (addedRound) {
                            newRound.id = addedRound.id;

                            if (newRound.groups?.length > 0) {
                                for (let groupIndex = 0; groupIndex < newRound.groups.length; groupIndex++) {
                                    const group = newRound.groups[groupIndex];
                                    group.round_id = addedRound.id;
                                    await addNewGroup(newRound, group);
                                }
                            }
                        }

                    }
                }
            }
            // delete all rounds that are in oldTournanemtData but not in newTournamentData
            for (let oldRoundIndex = 0; oldRoundIndex < oldTournamentData.rounds.length; oldRoundIndex++) {
                const oldRound = oldTournamentData.rounds[oldRoundIndex];
                if (!newTournamentData.rounds?.some(newRound => newRound.id == oldRound.id)) {
                    await RoundService.deleteRound(oldRound.id);
                }
            }
            resolve(true);
        });
    }

    async function handleExistingRound(oldRound: AdminTournamentRound, newRound: AdminTournamentRound) {
        return new Promise(async (resolve, reject) => {
            await updateRound(oldRound, newRound);
            if (newRound.groups?.length > 0) {
                for (let groupIndex = 0; groupIndex < newRound.groups.length; groupIndex++) {
                    const newGroup = newRound.groups[groupIndex];
                    let bFound = false;
                    for (let oldGroupIndex = 0; oldGroupIndex < oldRound.groups.length; ++oldGroupIndex) {
                        if (newGroup.id == oldRound.groups[oldGroupIndex].id) {
                            await handleExistingGroup(oldRound.groups[oldGroupIndex], newGroup);
                            bFound = true;
                            break;
                        }
                    }
                    if (!bFound) {
                        await addNewGroup(newRound, newGroup);
                    }
                }
            }

            // delete all rounds that are in oldTournanemtData but not in newTournamentData
            for (let oldGroupIndex = 0; oldGroupIndex < oldRound.groups.length; oldGroupIndex++) {
                const oldGroup = oldRound.groups[oldGroupIndex];
                if (!newRound.groups?.some(newGroup => newGroup.id == oldGroup.id)) {
                    await GroupService.deleteGroup(oldGroup.id);
                }
            }

            resolve(true);
        });
    }

    async function handleExistingGroup(oldGroup: AdminTournamentGroup, newGroup: AdminTournamentGroup) {
        await updateGroup(oldGroup, newGroup);
        if (newGroup.group_players?.length > 0) {
            for (let groupPlayerIndex = 0; groupPlayerIndex < newGroup.group_players.length; groupPlayerIndex++) {
                const groupPlayer = newGroup.group_players[groupPlayerIndex];
                let bFound = false;
                for (let oldGroupPlayerIndex = 0; oldGroupPlayerIndex < oldGroup.group_players.length; ++oldGroupPlayerIndex) {
                    if (groupPlayer.player.id == oldGroup.group_players[oldGroupPlayerIndex].player.id && groupPlayer.group_id == oldGroup.group_players[oldGroupPlayerIndex].group_id) {
                        await updateGroupPlayer(oldGroup.group_players[oldGroupPlayerIndex], groupPlayer);
                        bFound = true;
                        break;
                    }
                }
                if (!bFound) {
                    await addNewGroupPlayer(newGroup, groupPlayer);
                }
            }
        }
    }

    async function addNewGroup(newRound, newGroup) {
        const addedGroup = await GroupService.createGroup({ round_id: newRound.id, starting_time: newGroup.starting_time, tee: newGroup.tee });
        console.log("Added group: ", addedGroup);
        if (addedGroup) {
            newGroup.id = addedGroup.id;

            if (newGroup.group_players?.length > 0) {
                for (let groupPlayerIndex = 0; groupPlayerIndex < newGroup.group_players.length; groupPlayerIndex++) {
                    const groupPlayer = newGroup.group_players[groupPlayerIndex];

                    await addNewGroupPlayer(newGroup, groupPlayer);
                }
            }
        }
    }

    async function addNewGroupPlayer(newGroup, groupPlayer) {
        const addedGroupPlayer = await PlayerService.createGroupPlayer({
            group_id: newGroup.id,
            score_type: groupPlayer.score_type,
            hcp: groupPlayer.hcp,
            start_index: groupPlayer.start_index,
            tee_id: groupPlayer.tee_id,
            status: groupPlayer.status,
            gender_id: groupPlayer.gender_id,
            team_id: groupPlayer.team_id,
            player_id: groupPlayer.player.id
        });


        console.log("Added group player: ", addedGroupPlayer);
    }

    async function updateTournament(oldTournamentData: AdminTournamentData, newTournamentData: AdminTournamentData) {
        const newTournamentObject: TournamentInput = {
            id: oldTournamentData.id
        };

        let bChangesFound = false;
        Object.keys(oldTournamentData).forEach(key => {
            if (key != "id" && key != 'rounds' && key != 'owner') {
                if (oldTournamentData[key] && oldTournamentData[key] != newTournamentData[key]) {
                    newTournamentObject[key] = newTournamentData[key];
                    bChangesFound = true;
                }
            }
        })

        if (bChangesFound) {
            await TournamentService.updateTournament(newTournamentObject);
        }
    }

    async function updateRound(oldRoundData: AdminTournamentRound, newRoundData: AdminTournamentRound) {
        const newRoundObject: RoundModify = {
            id: oldRoundData.id
        };

        let bChangesFound = false;
        Object.keys(oldRoundData).forEach(key => {
            if (key != "id" && key != "course" && key != "groups") {
                if (oldRoundData[key] && oldRoundData[key] != newRoundData[key]) {
                    newRoundObject[key] = newRoundData[key];
                    bChangesFound = true;
                }
            }
        })

        if (bChangesFound) {
            await RoundService.modifyRound(newRoundObject);
        }
    }
    async function updateGroup(oldGroup: AdminTournamentGroup, newGroup: AdminTournamentGroup) {
        const newGroupObject: GroupModify = {
            id: oldGroup.id,
            round_id: oldGroup.round_id       
        };
        let bChangesFound = false;
        Object.keys(oldGroup).forEach(key => {
            if (key != "id" && key != "round_id" && key != "group_players") {
                if (oldGroup[key] != newGroup[key]) {
                    newGroupObject[key] = newGroup[key];
                    bChangesFound = true;
                }
            }
        })
        if (bChangesFound) {
            await GroupService.modifyGroup(newGroupObject);
        }
    }

    async function updateGroupPlayer(oldGroupPlayer: AdminTournamentGroupPlayer, newGroupPlayer: AdminTournamentGroupPlayer) {
        const newGroupPlayerObject: GroupPlayerModify = { group_id: oldGroupPlayer.group_id, player_id: oldGroupPlayer.player.id };
        let bChangesFound = false;
        Object.keys(oldGroupPlayer).forEach(key => {
            if (key != "group_id" && key != "player") {
                if (oldGroupPlayer[key] != newGroupPlayer[key]) {
                    newGroupPlayerObject[key] = newGroupPlayer[key];
                    bChangesFound = true;
                }
            }
        })
        if (bChangesFound) {
            await PlayerService.modifyGroupPlayer(newGroupPlayerObject);
        }
    }
}