import { TournamentVisibility } from "../types/types";



const tintColor = '#2f95dc';

export const LGColors = {
  background: '#022835', //'#EEEDEE',
  primary: '#99B',
  primaryLabel: '#AAC',
  primary2: '#BAC',
  textPrimary: '#111',
  tableBackgroundEven: '#eee',
  tableBackgroundOdd: '#fff',
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  errorIcon: '#F00',
  inputSecondary: "#FED",
  inputPrimary: "#BBF"
};

export const visColors = {
  [TournamentVisibility.hidden]: '#F97',
  [TournamentVisibility.friends]: '#FFA',
  [TournamentVisibility.public]: '#DDF',
  [TournamentVisibility.restricted]: '#FB2',
  [TournamentVisibility.private]: '#F77'
}


