import React, { Component, createRef, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { DatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { TournamentVisibility, TournamentMode, TournamentStatus } from '../../types/types';
import { AdminTournamentCourse, AdminTournamentData } from '../../types/AdminTypes';
import {
   Controller,
   FormProvider,
   useForm} from "react-hook-form";
import { GroupPlayers } from './AdminTournamentScreen';
import { Dimensions, EmitterSubscription, Modal, Pressable, ScaledSize } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../navigation/LGStackNavigator';
import { LGAdminHeader } from '../../components/LGAdminHeader';
import { LGColors } from '../../constants/colors';
import { CourseService } from '../../services/CourseService';
//import { TournamentModeSelect } from '../../components/Admin/TournamentModeSelect';
//import { TournamentVisibilitySelect } from '../../components/Admin/TournamentVisibilitySelect';
import ChooseCourseModal from '../../components/Admin/ChooseCourseModal';
import { TournamentService } from '../../services/TournamentService';
import { RoundService } from '../../services/RoundService';
import { GroupService } from '../../services/GroupService';
import { PlayerService } from '../../services/PlayerService';
import { Button, Input, ScrollView, Separator, XStack, YStack } from 'tamagui';
import { LGText } from '../../styles/StyledText';
import { LGCenter, LGStack, LGXStack } from '../../styles/StyledStacks';
import { TournamentEnumSelect } from '../../components/Admin/TournamentEnumSelect';
import { createElement } from 'react';
import { valueFromAST } from 'graphql';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

type Props = NativeStackScreenProps<RootStackParamList, 'AdminAddTournament'>;

type State = {
   windowDims: ScaledSize;
   tournamentData?: AdminTournamentData;
   parentSubmit: boolean;
   hasChanges: boolean;
   formError: string;
   submitted: boolean;
   submitting: boolean;
}

export default class AdminAddTournamentScreen extends Component<Props, State> {


   private formRef;

   private dimsSubcription: EmitterSubscription;

   private submitted: boolean = false;

   constructor(props) {
      super(props);

      this.state = {
         windowDims: Dimensions.get('window'),
         parentSubmit: false,
         hasChanges: false,
         formError: "",
         submitted: false,
         submitting: false
      }

      this.formRef = createRef();
   }

   componentDidMount() {
      this.dimsSubcription = Dimensions.addEventListener("change", this.onDimsChanged.bind(this));
   }


   async onSubmitted(values: AdminTournamentData) {

      if(this.submitted)
      {
         return;
      }

      this.submitted = true;
      this.setState({submitted: true, submitting: true});

      
      console.log("onSubmitted");
      console.log("values: ", values);
      try {
         TournamentService.createTournament({ name: values.name, visibility: values.visibility, mode: values.mode }).then((tid) => {
            if (tid) {
               RoundService.createRound({ course_id: values.rounds[0].course_id, date: values.rounds[0].date, round_index: 0, tournament_id: tid }).then((id) => {

                  if(!values.rounds[0].groups ||values.rounds[0].groups.length == 0)
                  {
                     this.onTournamentSubmitted(tid);
                     return;
                  }

                  let playerPromises = [];

                  values.rounds[0].groups.forEach((group) => {
                     GroupService.createGroup({ round_id: id, starting_time: group.starting_time, tee: group.tee }).then((gid) => {
                        if (gid) {
                           if(group.group_players.length == 0)
                           {
                              this.onTournamentSubmitted(tid);
                              return;
                           }
                           group.group_players.forEach((player) => {
                              playerPromises.push(PlayerService.createGroupPlayer({ group_id: gid, player_id: player.player.id, tee_id: player.tee_id, score_type: player.score_type, hcp: player.hcp, gender_id: player.gender_id, status: player.status }));
                           })

                           Promise.all(playerPromises).then(() => {
                              this.onTournamentSubmitted(tid);
                           })
                        }
                     })
                  })
                  
               })
            }
         });
      } catch (e) {
         console.log(e);
      }
   }

   onTournamentSubmitted(tid: number)
   {
      this.setState({submitted: false, submitting: false}, () => {
         this.props.navigation.navigate("AdminTournament", { tid: tid });
      });
   }

   onValuesChanged(values: AdminTournamentData) {
      console.log("onValuesChanged");
      if (!values.name || values.name == "") {
         this.setState({ formError: "Tournament Name is required" });
      }
      else if (values.name.length < 3) {
         this.setState({ formError: "Tournament Name must be at least 3 characters" });
      }
      else {
         this.setState({ formError: "" });
      }
   }

   onDimsChanged({ window, screen }) {
      this.setState({ windowDims: Dimensions.get('window') }, () => {
         this.forceUpdate();
      });
   }

   onCancelPressed() {
      this.props.navigation.goBack();
   }

   render() {
      return (
         <LGStack bg="#FEE" h={this.state.windowDims.height}>
            <LGAdminHeader title="Live-Golf Admin: Add Tournament" navigation={this.props.navigation} />
            <ScrollView pt="16px" flex={1} alignSelf="center" w="80%" bg="#DDF">
               <AddTournamentForm onValuesChanged={this.onValuesChanged.bind(this)} onSubmit={(tdata) => this.onSubmitted(tdata)} ref={this.formRef} />
            </ScrollView>
            <LGStack h="40px" w="100%" bg={LGColors.primary2}>
               <LGXStack flex={1}>
                  <LGText w="200px"></LGText>
                  <LGCenter flex={1}>
                     <LGText fontSize="$7" color="#F00" fontWeight="bold">{this.state.formError}</LGText>
                  </LGCenter>
                  <Button disabled={this.state.formError != "" || this.state.submitted} w="200px" onPress={() => { console.log("onPress submit"); this.formRef.current.submitForm()}}>Save Tournament</Button>
               </LGXStack>
            </LGStack>
            <Modal animationType="slide" transparent={true} visible={this.state.submitting} onRequestClose={() => {}}>
               <LGCenter w="100%" h="100%" bg="rgba(0,0,0,0.5)">
                  <LGCenter>
                     <LGText>Submitting...</LGText>
                  </LGCenter>
               </LGCenter>
            </Modal>
         </LGStack>
      )
   }
}


const tournamentDataIn: AdminTournamentData = {
   name: "",
   active_round_index: 0,
   aggregate_points: 0,
   best_ball_points: 0,
   birdie_points: 0,
   longest_drive_points: 0,
   nearest_pin_points: 0,
   status: TournamentStatus.InProgress,
   mode: TournamentMode.Strokeplay,
   visibility: TournamentVisibility.private,
   rounds: [{
      date: Date.now(),
      course_id: 1,
      hcp_factor: 1,
      cut_playercount: 0,
      points_are_money: 0,
      round_index: 0,
      groups: [{
         starting_time: "9:00",
         tee: 1,
      }]
   }]
}

const useConstructor = (callBack = () => { }) => {
   const hasBeenCalled = useRef(false);
   if (hasBeenCalled.current) return;
   callBack();
   hasBeenCalled.current = true;
}

type MyFormRef = {
   submitForm: () => void;
};

type MyFormProps = {
   onSubmit: (values) => void;
   onValuesChanged: (values) => void;
};

const AddTournamentForm = forwardRef<MyFormRef, MyFormProps>(({ onSubmit, onValuesChanged }, ref) => {

   const form = useForm<AdminTournamentData>({
      defaultValues: tournamentDataIn
   }
   );

   const { control, getValues, setValue, watch } = form;
   const [showChooseCourseModal, setShowChooseCourseModal] = useState(false);
   const [course_name, setCourseName] = useState("");

   useConstructor(() => {
      CourseService.getCourse(1).then((course) => {
         onCourseChanged(course);
      })
   });

   React.useEffect(() => {
      const subscription = watch((value, { name, type }) => onValuesChanged(getValues()));
      return () => subscription.unsubscribe();
   }, [watch]);

   const submitRef = useRef(null);

   useImperativeHandle(ref, () => ({

      submitForm() {
         if (submitRef.current) {
            submitRef.current.click();
         }
      }
   }));

   function onCourseChanged(course: AdminTournamentCourse) {
      setCourseName(course.name);
      setValue("rounds.0.course.course_combis", course.course_combis);
      setValue("rounds.0.course_id", course.id);

      const courseCombiTeeIds = course.course_combis.map(x => x.tee_id);
      const groups = getValues("rounds.0.groups");
      groups.forEach((group, groupIndex) => {
         group.group_players.forEach((player, playerIndex) => {
            if (!courseCombiTeeIds.includes(player.tee_id)) {
               for (let i = 0; i < course.course_combis.length; i++) {
                  // find first course combi with matching gender id
                  if (course.course_combis[i].gender_id == player.gender_id) {
                     setValue(`rounds.0.groups.${groupIndex}.group_players.${playerIndex}.course_combi_id`, course.course_combis[i].id, { shouldDirty: false });
                     setValue(`rounds.0.groups.${groupIndex}.group_players.${playerIndex}.tee_id`, course.course_combis[i].tee_id, { shouldDirty: false });
                     break;
                  }
               }
            }
         })
      })

   }
   const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();
      // Handle form submission logic here
      onSubmit(getValues());
    };

   function setCourse(course: AdminTournamentCourse) {
      console.log("setCourse: ", course);
      onCourseChanged(course);
   }

   return (
      <LGStack px="8px">
         <ChooseCourseModal isVisible={showChooseCourseModal} onCourseSelect={(course) => setCourse(course)} onClose={() => { setShowChooseCourseModal(false) }} />
         <FormProvider {...form}>
            <form onSubmit={handleSubmit}>
               <YStack space="8px">
                  <XStack>
                     <LGText alignSelf="center" w="200px" fontSize="$7" fontWeight="bold">Tournament Name</LGText>
                     <Controller control={control} name="name" render={({ field }) => {
                        return (
                           <Input
                              w="400px"
                              fontSize="$7"
                              placeholder='Tournament Name'
                              value={field.value}
                              onChange={(value) => field.onChange(value)}
                           />
                        )
                     }} />
                  </XStack>
                  <XStack>
                     <LGText alignSelf="center" w="200px" fontSize="$7" fontWeight="bold">Date</LGText>
                     <LGStack w="200px">
                        <Controller control={control} name="rounds.0.date" render={({ field }) => {
                           return (
                              createElement('input', {
                                 type: 'date',
                                 value:  dayjs(field.value).format("YYYY-MM-DD"),
                                 onInput: (value) => { field.onChange(value.target.value + " T12:00:00"); console.log("Date on change: ", value.target.value) }
                              })
                           )
                              
                        }} />
                     </LGStack>
                  </XStack>
                  <XStack>
                     <LGText alignSelf="center" w="200px" fontSize="$7" fontWeight="bold">Course</LGText>
                     <Pressable onPress= { () => setShowChooseCourseModal(true)}>
                        <LGStack bg={LGColors.inputPrimary} p="8px" w="300px">
                           <LGText>{course_name}</LGText>
                        </LGStack>
                     </Pressable>
                  </XStack>
                  <LGStack>
                     <TournamentEnumSelect enumObject={TournamentMode} control={control} controlName="mode"/>
                  </LGStack>
                  <LGStack>
                     <TournamentEnumSelect enumObject={TournamentVisibility} control={control} controlName="visibility" />
                  </LGStack>
                  <Separator borderColor={LGColors.textPrimary} />
                  <LGStack>
                     <Controller control={control} name={`rounds.0.groups.0.starting_time`}

                        render={({ field }) => (
                           <XStack alignItems="center">
                              <LGText fontSize="$7" w="200px" fontWeight="bold">Time</LGText>
                              <LGStack bg="#FED" w="80px">
                                 <MobileTimePicker
                                    format="HH:mm"
                                    value={dayjs("2022-01-01T" + field.value)}
                                    onChange={(newTime) => field.onChange(newTime.format("HH:mm"))}
                                    slotProps={{ textField: { color: 'secondary', size: 'small' } }}
                                 />
                              </LGStack>
                           </XStack>
                        )}
                     />
                  </LGStack>
                  <Separator borderColor={LGColors.textPrimary}/>
                  <LGStack>
                     <GroupPlayers roundIndex={0} groupIndex={0} getValues={getValues} setValue={setValue}/>
                  </LGStack>
               </YStack>
               <button ref={submitRef} style={{ display: "none" }} hidden={true} type="submit">Submit</button>
            </form>
         </FormProvider>
      </LGStack>
   );
});

/*
<DatePicker
                                 value={dayjs(field.value)}
                                 onChange={(value) => field.onChange(value.utc(false).set("hour", 12).unix() * 1000)}
                              />
                           )
                           */