import MaterialIcons from '@expo/vector-icons/build/MaterialIcons';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { StyleSheet, View, Dimensions, NativeSyntheticEvent, TextInputChangeEventData, Pressable } from 'react-native';
import { RootStackParamList } from '../navigation/LGStackNavigator';
import { LivegolfHeader } from '../components/LivegolfHeader';
import * as AuthService from '../services/AuthService';
import { isValidEmail } from '../services/ValidateHelper';
import { Button, Image, Input, ScrollView, Stack, Text } from 'tamagui';
type Props = NativeStackScreenProps<RootStackParamList, 'Login'>;

const windowHeight = Dimensions.get('window').height;

type State = {
  email: string;
  password: string;
  showPassword: boolean;
  dataValid: boolean;
}

export default class LoginScreen extends React.Component<Props, State> {

  emailTimeout;

  constructor(props) {
    super(props);
    this.state = { email: "", password: "", showPassword: false, dataValid: false }

    this.onEmailChanged = this.onEmailChanged.bind(this);
  }

  onLoginPressed() {
    AuthService.loginUser({ email: this.state.email, password: this.state.password }).then(
      () => {
        this.props.navigation.replace('Home');
      }
    );
  }

  onRecoverPressed() {
    this.props.navigation.navigate("Recover");
  }

  toggleShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  onEmailChanged(event: NativeSyntheticEvent<TextInputChangeEventData>) {
    this.setState({ email: event.nativeEvent.text }, () => {
      if (this.emailTimeout) {
        clearTimeout(this.emailTimeout);
      }
      this.emailTimeout = setTimeout(() => { this.emailTimeout = null; this.checkDataValid() }, 500);
    }
    );
  }

  onPasswordChanged(event: NativeSyntheticEvent<TextInputChangeEventData>) {
    this.setState({ password: event.nativeEvent.text });
    this.checkDataValid();
  }

  emailValid() {
    return isValidEmail(this.state.email);
  }

  passwordValid() {
    return this.state.password != "";
  }

  checkDataValid() {
    this.setState({ dataValid: (this.emailValid() && this.passwordValid()) });
  }

  sudmitEditingPassword() {
    if(this.emailValid && this.passwordValid()) {
      this.onLoginPressed();
    }
  }

  render() {
    
    //return ( <View> <LivegolfHeader title="Login" navigation={this.props.navigation} /></View>);
    return (
      <View style={styles.container}>
        <LivegolfHeader title="Login" navigation={this.props.navigation} />
        <ScrollView style={styles.container} contentContainerStyle={{ height: windowHeight }}>
          <View style={{ alignItems: 'center', marginTop: 32 }}>
            
            <Stack width={400} alignItems="center">
            
              <Input onChange={this.onEmailChanged} value={this.state.email} width="100%" size={5} color="muted.400" placeholder="Email" />
             
              <Input onChange={this.onPasswordChanged.bind(this)} onSubmitEditing={this.sudmitEditingPassword.bind(this)} value={this.state.password} width="100%" />
                <MaterialIcons name={this.state.showPassword ? "visibility" : "visibility-off"} />

              <Pressable>
                <Text fontSize="$5" fontWeight="500" color="indigo.500" onPress={this.onRecoverPressed.bind(this)} alignSelf="flex-end">
                  Forgot Password?
                </Text>
              </Pressable>
            
              <Button width={140} disabled={!this.state.dataValid} onPress={this.onLoginPressed.bind(this)}>Login</Button>
            </Stack>
            
          </View>
            
        </ScrollView>
      </View>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: '#FFF'
  },
  developmentModeText: {
    marginBottom: 20,
    color: 'rgba(255,255,255,0.4)',
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center',
  }
});
