import { gql } from '@apollo/client';
import { apollo_client } from "../App";
import { CourseCombi, CourseCombiInput, HoleInput } from 'livegolf_shared/lib/generated/graphql';
import _ from 'lodash';
import { ClientCourseCombi, createClientCourseCombi } from '../classes/CourseCombiClass';

const getCourseCombiDataGQL = gql`
  query course_combi($id: Int!){
    course_combi(id: $id) {
        id
        creator_id
        course_id
        name
        cr
        slope
        tee_id
        tee_name
        gender_id
        unit
        holes {
            tee_id
            hole_index
            par
            hcp
            length
        }
    }
}`

const addCourseCombiGQL = gql`
  mutation addCourseCombi($courseCombi: CourseCombiInput!, $holes: [HoleInput!]!) {
    addCourseCombi(course_combi: $courseCombi, holes: $holes) {
        id
        name
        cr
        slope
        tee_id
        tee_name
        gender_id
        unit
        holes {
            tee_id
            hole_index
            par
            hcp
            length
        }
    }
}`

const updateCourseCombiGQL = gql`
  mutation updateCourseCombi($courseCombiId: Int!, $courseCombi: CourseCombiInput!) {
    updateCourseCombi(id: $courseCombiId, courseCombi: $courseCombi) {
        id
        name
        cr
        slope
        tee_id
        tee_name
        gender_id
        unit
        holes {
            tee_id
            hole_index
            par
            hcp
            length
        }
    }
}`

export namespace CourseCombiService {

    export async function getCourseCombis(ccids: Array<number>) : Promise<Array<ClientCourseCombi>> {

        const courseCombiArray = [];
        for(let i=0; i<ccids.length; ++i) {
            
            const courseCombi = await getCourseCombi(ccids[i]);
            if(courseCombi) {
                courseCombiArray.push(courseCombi);
            }
        }

        return Promise.resolve(courseCombiArray);
    }

    export async function getCourseCombi(courseCombiId: number) : Promise<ClientCourseCombi>{
        const { data } = await apollo_client.query({ query: getCourseCombiDataGQL, variables: {id: courseCombiId} });

        if(data) {
            return Promise.resolve(createClientCourseCombi(data.course_combi));
            //return Promise.resolve(data.course_combi);
        }
        else {
            return Promise.reject("Not found");
        }
    }

    export async function addCourseCombi(courseCombiData: CourseCombiInput, holes: Array<HoleInput>) : Promise<CourseCombi> {
      const { data } = await apollo_client.mutate({ mutation: addCourseCombiGQL, variables: { courseCombi: courseCombiData, holes } });
      
      return Promise.resolve(data.addCourseCombi);
    }

    export async function updateCourseCombi(courseCombiId: number, courseCombiData: CourseCombiInput) {

      // strip all __typename entries from courseData
      courseCombiData = _.omit(courseCombiData, "__typename");
      courseCombiData = _.omit(courseCombiData, "id");
      courseCombiData = _.omit(courseCombiData, "course_combis");

      const { data } = await apollo_client.mutate({ mutation: updateCourseCombiGQL, variables: { courseCombiId, courseCombiData } });

      return Promise.resolve(data.updateCourse.id);
    }
  }
