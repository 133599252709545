import { StackScreenProps } from '@react-navigation/stack';
import * as React from 'react';
import { Component } from 'react';
import { StyleSheet, View, Dimensions, EmitterSubscription, ScaledSize } from 'react-native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '../navigation/LGStackNavigator';
import ScoreTable from '../components/ScoreTable';
import { TournamentService } from '../services/TournamentService';
import * as Types from '../types/types';
import { LGColors } from '../constants/colors';
import { ScrollView, Stack, Text, XStack, YStack } from 'tamagui';
import { LGText } from '../styles/StyledText';

type Props = NativeStackScreenProps<RootStackParamList, 'ScoreBoard'>;

type State = {
  refreshScoreTable: boolean;
  scoreTableTheme: string;
  windowDims: ScaledSize;
  tournamentData?: TournamentService.ScoreBoardTournamentData;
  courses: Array<Types.ScoreBoardCourse>;
  fetchingTournamentData: boolean;
  checkingForTournamentUpdates: boolean;
}

export default class ScoreScreen extends Component<Props, State> {

  private dimsSubcription: EmitterSubscription;
  private focusListener;
  private focusInterval;
  private blurListener;

  constructor(props) {
    super(props);

    this.state= {
      refreshScoreTable: false,
      scoreTableTheme: 'default',
      windowDims: Dimensions.get('window'),
      courses: [],
      fetchingTournamentData: false,
      checkingForTournamentUpdates: false
    }
  }

  componentDidMount() {
    this.dimsSubcription = Dimensions.addEventListener("change", this.onDimsChanged.bind(this));
    const { navigation } = this.props;
    this.focusListener = navigation.addListener("focus", this.onFocus.bind(this));
    this.blurListener = navigation.addListener("blur", this.onBlur.bind(this));
    //this.onFocus();

    this.fetchTournamentData();
  }

  componentWillUnmount() {
    if(this.focusListener) {
      this.focusListener();
    }

    if(this.blurListener) {
      this.blurListener();
    }

    if(this.focusInterval) {
      clearInterval(this.focusInterval);
      this.focusInterval = null;
    }
  }

  onFocus() {
    //console.log("Focus");
    this.focusInterval = setInterval(() => {
      this.updateTick();
      //console.log("FocInt: ", this.focusInterval);
    }, 10000);
  }

  onBlur() {
    if(this.focusInterval) {
      //console.log("Blur: focusInterval");
      clearInterval(this.focusInterval);
      this.focusInterval = null;
    }
  }

  async fetchTournamentData() {
    if(this.state.fetchingTournamentData) {
      return;
    }

    this.setState({fetchingTournamentData: true})
    let tid = this.props.route.params.tid;
    if(typeof tid == 'string') {
      tid = parseInt(tid);
    }

    const tdata = await TournamentService.getTournamentPlayerData(tid);

    //TODO: get course name in getCouseCombis
    //const courseCombis = await getCourseCombis(tdata.course_combi_ids);
    //console.log(courseCombis);
    
    this.setState({tournamentData: tdata, courses: tdata.courses, fetchingTournamentData: false});
  }

  updateTick() {
    if(!this.state.fetchingTournamentData) {
      this.checkForTournamentUpdates();
    }
  }

  checkForTournamentUpdates() {
    if(this.state.checkingForTournamentUpdates) {
      return;
    }
    
    let tid = this.props.route.params.tid;
    if(typeof tid == 'string') {
      tid = parseInt(tid);
    }

    this.setState({ checkingForTournamentUpdates : true});
    console.log("Check for updates");
    TournamentService.fetchTournamentScoreVersion(tid).then(
      (score_version) => {
        this.setState({ checkingForTournamentUpdates : false});
        if(score_version > this.state.tournamentData?.tournament_details.score_version) {
          this.fetchTournamentData();
        }
      },
      (err) => {
        this.setState({ checkingForTournamentUpdates : false});
      }
    )
  }

  onDimsChanged({ window, screen }) {
    this.setState({ windowDims: Dimensions.get('window') }, () => {
      this.forceUpdate();
    });
  }
  
  render() {
    //const tid = this.props.route.params.tid;

    return (
      <Stack backgroundColor={LGColors.background} height="100%" width="100%">
        <Stack backgroundColor={LGColors.background} justifyContent='center' alignItems='center'>
          <Stack justifyContent='center' alignItems='center'>
            
            { this.state.tournamentData &&
              <YStack>
                <Stack justifyContent='center' alignItems='center'><LGText fontSize="$7" color={LGColors.textPrimary} fontWeight="bold">{this.state.tournamentData?.tournament_details.name}</LGText></Stack>
                <Stack justifyContent='center' alignItems='center'><LGText fontSize="$6" color={LGColors.textPrimary}>{this.state.courses && this.state.courses.length > 0 && this.state.courses[0].name}</LGText></Stack>
                <Stack justifyContent='center' alignItems='center'><LGText fontSize="$6" color={LGColors.textPrimary}>{new Date(this.state.tournamentData.tournament_details.dateStart).toISOString().substring(0,10)} - {Types.TournamentMode[this.state.tournamentData.tournament_details.mode]}</LGText></Stack>
              </YStack>
              ||
              <YStack width={this.state.windowDims.width}>
                <LGText>Loading...</LGText>
              </YStack>
            }
            <ScrollView horizontal={true} style={[styles.container, { alignContent: 'center', maxWidth: 1200}]} contentContainerStyle={{ flex:1, maxWidth: this.state.windowDims.width}}>
              { this.state.tournamentData && 
                <ScoreTable tournamentData={this.state.tournamentData} themeName={ this.state.scoreTableTheme } width={this.state.windowDims.width}/>
              }
            </ScrollView>
          </Stack>
        </Stack>
      </Stack>
    );
  }
}

/*ScoreScreen.navigationOptions = {
  header: null
};*/


const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    alignSelf: 'center',
    backgroundColor: '#111',
  }
});
