import { NativeStackScreenProps } from '@react-navigation/native-stack';
import * as React from 'react';
import { Platform, Dimensions, Linking, ScaledSize, EmitterSubscription, VirtualizedList, ListRenderItemInfo, Pressable } from 'react-native';
import { RootStackParamList } from '../../navigation/LGStackNavigator';
import * as AuthService from '../../services/AuthService';
import { TournamentService } from '../../services/TournamentService';
import { LGAdminHeader } from '../../components/LGAdminHeader';
import * as AuthTypes from '../../types/AuthTypes';
import * as Types from '../../types/types';
import { LGColors, visColors } from '../../constants/colors';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Stack, YStack, Text, XStack, Button } from 'tamagui';

type AdminTournamentList = Array<Types.AdminTournamentListEntry>;
type Props = NativeStackScreenProps<RootStackParamList, 'AdminTournaments'>;



type State = {
  authState: AuthTypes.AuthState;
  tournamentList: AdminTournamentList;
  windowDims: ScaledSize;
  showAddTournamentModal: boolean;
}

export default class AdminTournamentsScreen extends React.Component<Props, State> {
  private fetchingTournamentList = false;
  private focusListener;
  private dimsSubcription: EmitterSubscription;
  private pendingFetchOffset: number = 0;
  private pendingFetchLimit: number = 0;
  private currentTournamentsFetched: number = 0;

  constructor(props) {
    super(props);

    this.state= { 
      authState: 'not_logged_in', 
      tournamentList: [], 
      windowDims: Dimensions.get('window'),
      showAddTournamentModal: false
    };
  }

  componentDidMount(): void {
    
    this.dimsSubcription = Dimensions.addEventListener("change", this.onDimsChanged.bind(this));

    const { navigation } = this.props;
    this.focusListener = navigation.addListener("focus", () => {
      this.getUserData();
    });  

    this.getUserData();
  }

  onDimsChanged({ window, screen }) {
    //console.log("dims changed: ", window);
    this.setState({ windowDims: Dimensions.get('window') }, () => {
      this.forceUpdate();
    });
  }

  getUserData() {
      AuthService.fetchUserData().then(() => {
        this.setState({ authState: AuthService.getLoginState()});

        this.tryFetchTournamentList(0, 20);
      },
      (err) => {
        this.setState({ authState: 'not_logged_in'});
        this.tryFetchTournamentList(0, 20);
      });    
  }

  componentWillUnmount(): void {
    if(this.focusListener) {
      if(this.focusListener.remove) {
        this.focusListener.remove();
      }
      this.focusListener = null;
    }
  }

  tryFetchTournamentList(offset: number, limit: number) {
    if(this.fetchingTournamentList) {
      if(offset > this.pendingFetchOffset) {
        console.log("Queue fetch");
        this.pendingFetchOffset = offset;
        this.pendingFetchLimit += limit;
      }
    }
    else {
      this.fetchTournamentList(offset, limit);
    }
  }

  fetchTournamentList(offset: number, limit: number) {
    if(this.fetchingTournamentList) {
      return;
    }
    this.pendingFetchLimit = 0;
    this.pendingFetchOffset = 0;
    this.fetchingTournamentList = true;
    TournamentService.getAdminTournamentList(offset, limit).then((tournamentList) => {

      const newTournamentList : AdminTournamentList = (tournamentList != null && tournamentList.length > 0) ? this.state.tournamentList.concat(tournamentList) : this.state.tournamentList;

      if(newTournamentList.length == 0) {
        this.setState({ tournamentList: [] }, () => {
          this.forceUpdate();
        });

        return;
      }

      newTournamentList.sort((a,b) => { return a.id - b.id }).reverse();

      let currentID = -1;
      const orderedFiltered = newTournamentList.filter((item) => {
        if(item.id != currentID) {
          currentID = item.id;
          return true;
        }
        return false;
      })

      

      this.currentTournamentsFetched = offset + limit;
      this.setState({ tournamentList: orderedFiltered }, () => {
        this.forceUpdate();
      });
      this.afterFetchedTournaments();
    },
    (err) => {
      this.afterFetchedTournaments();
    })
  }

  afterFetchedTournaments() {
    this.fetchingTournamentList = false;

      if(this.pendingFetchOffset > 0) {
        this.fetchTournamentList(this.pendingFetchOffset, this.pendingFetchLimit);
      }
  }

  onRequestVerificationPressed() {

  }

  onTournamentPressed(tid: number) {
    this.props.navigation.navigate("AdminTournament", { tid: tid});
  }

  tournamentListEndReached(info) {
    console.log("End reached");

    
    this.tryFetchTournamentList(this.currentTournamentsFetched, 20);
  }

  renderTournamentItem(data : ListRenderItemInfo<Types.AdminTournamentListEntry>) {
    
    const item : Types.AdminTournamentListEntry = data.item;
    
    const bgColor = visColors[item.visibility];
    let courseName = "***";
    let dateMillis = Date.now();

    
    if(item.rounds && item.rounds.length > 0) {
      if(item.rounds[0].course?.name) {
        courseName = item.rounds[0].course.name;
      }

      if(item.rounds[0].date) {
        dateMillis = item.rounds[0].date;
      }
    }

    
    //console.log(data);
    return (
      <Pressable onPress={() => { this.onTournamentPressed(item.id)}} >
      <XStack backgroundColor={bgColor}>
        <YStack paddingHorizontal="8px" flex={1}>
          <Text fontSize="$7" fontWeight="500">{item.name}</Text>
          <Text fontSize="$6">{courseName}</Text>
        </YStack>
        
        <Stack width="120px" alignItems="center" justifyContent="center">
          <Text textAlign="center" width="100%" borderLeftWidth={1} fontSize="$7">{Types.TournamentMode[item.mode]}</Text>
        </Stack>
        <Stack width="80px" alignItems="center" justifyContent="center">
          <Text textAlign="center" width="100%" borderLeftWidth={1} fontSize="$7">{item.rounds?.length}</Text>
        </Stack>
        <Stack width="120px" alignItems="center" justifyContent="center">
          <Text textAlign="center" width="100%" borderLeftWidth={1} fontSize="$7">{new Date(dateMillis).toISOString().substring(0,10)}</Text>
        </Stack>
        <Stack width="100px" alignItems="center" justifyContent="center">
          <Text textAlign="center" width="100%" borderLeftWidth={1} fontSize="$7">{Types.TournamentVisibility[item.visibility]}</Text>
        </Stack>
        <Stack width="80px" alignItems="center" justifyContent="center">
          <Text textAlign="center" width="100%" borderLeftWidth={1} fontSize="$7">{item.owner.nickname}</Text>
        </Stack>
        <Stack width="40px" alignItems="center" justifyContent="center">
          <Text textAlign="center" width="100%" borderLeftWidth={1} fontSize="$7"><MaterialCommunityIcons name="delete-forever" size={24} color={LGColors.textPrimary} /></Text>
        </Stack>
      </XStack>
      </Pressable>
    );
  }

  getItem(data: any, index: number): Types.TournamentListEntry {
    return data[Object.keys(data)[index]];
  }

  getItemCount() {
    return Object.keys(this.state.tournamentList).length;
  }

  keyExtractor(item: Types.TournamentListEntry, index: number) : string {
    return String(index);
  }

  renderNoTournaments() {
    return (<Stack alignItems="center" justifyContent="center"><Text fontSize="$7" fontWeight="500">No tournaments found!</Text></Stack>)
  }

  showAddTournamentModal() {
    this.setState({showAddTournamentModal: true});
  }
  render() {
    

    return (
      
      <Stack backgroundColor="#FEE" height={this.state.windowDims.height}>
        <LGAdminHeader title="Admin: Tournaments" navigation={this.props.navigation} /> 
        <Button onPress={ () => { this.props.navigation.navigate("AdminAddTournament")}}>Create Tournament</Button> 
        <Stack marginTop="16px" flex={1} alignSelf="center" width="80%">
          <Stack backgroundColor="#88F" width="100%" height="32px" justifyContent="center">
            <XStack>
              <Stack flex={1}>
                <Text paddingLeft="8px" width="100%" borderBottomWidth={1} fontSize="$7" fontWeight="500">Name</Text>
              </Stack>
              
              <Stack width="120px" alignItems="center" justifyContent="center">
                <Text textAlign="center" width="100%" borderBottomWidth={1} borderLeftWidth={1} fontSize="$7" fontWeight="500">Mode</Text>
              </Stack>
              <Stack width="80px" alignItems="center" justifyContent="center">
                <Text textAlign="center" width="100%" borderBottomWidth={1} borderLeftWidth={1} fontSize="$7" fontWeight="500">Rounds</Text>
              </Stack>
              <Stack width="120px" alignItems="center" justifyContent="center">
                <Text textAlign="center" width="100%" borderBottomWidth={1} borderLeftWidth={1} fontSize="$7" fontWeight="500">Date</Text>
              </Stack>
              <Stack width="100px" alignItems="center" justifyContent="center">
                <Text textAlign="center" width="100%" borderBottomWidth={1} borderLeftWidth={1} fontSize="$7" fontWeight="500">Visibility</Text>
              </Stack>
              <Stack width="80px" alignItems="center" justifyContent="center">
                <Text textAlign="center" width="100%" borderBottomWidth={1} borderLeftWidth={1} fontSize="$7" fontWeight="500">Owner</Text>
              </Stack>
              <Stack width="40px" alignItems="center" justifyContent="center">
                <Text textAlign="center" width="100%" borderLeftWidth={1} fontSize="$7"><MaterialCommunityIcons name="delete-forever" size={24} color={LGColors.textPrimary} /></Text>
              </Stack>
            </XStack>
          </Stack>
          <VirtualizedList
            initialNumToRender={10}
            data={this.state.tournamentList}
            renderItem={this.renderTournamentItem.bind(this)}
            
            getItemCount={this.getItemCount.bind(this)}
            getItem={this.getItem.bind(this)}
            keyExtractor={this.keyExtractor.bind(this)}
            ListEmptyComponent={this.renderNoTournaments.bind(this)}
            onEndReachedThreshold={0.4}
            onEndReached={this.tournamentListEndReached.bind(this)}
            
          />
        </Stack>
      </Stack>
    );
  }
}
