import React, { Component } from 'react';
import { View, ScrollView, TouchableOpacity } from 'react-native';
import { CourseCombi, TournamentMode, TournamentScoreData } from '../types/types';
import { TournamentService } from '../services/TournamentService';

import Themes from './ScoreTableThemes';
import { CourseService } from '../services/CourseService';
import { ScoreBaordColumnGross, ScoreBoardColumn, ScoreBoardColumnHcp, ScoreBoardColumnName, ScoreBoardColumnNet, ScoreBoardColumnPCHcp, ScoreBoardColumnPos, ScoreBoardColumnScore, ScoreBoardColumnThru, ScoreBoardColumnTotal } from './ScoreBoardColumns';
import { ScoreBoardPlayerRow } from './ScoreBoardPlayerRow';
import { styles } from '../styles/styles';
import { ScoreEntry } from 'livegolf_shared/lib/types';
import { TournamentPlayer } from 'livegolf_shared';
import { Stack, Text } from 'tamagui';
import { CourseCombiService } from '../services/CourseCombiService';
import { ClientCourseCombi } from '../classes/CourseCombiClass';

type Props = {
  tournamentData: TournamentService.ScoreBoardTournamentData;
  //tid: number;
  themeName: string;
  width: number;
  fetchStateCallback?: (state: string) => void;
}

type State = {
  playersData: Array<TournamentPlayer>;
  sortID: string;
  playerDetailVisible: Array<boolean>;
  scoreBoardColumns: Array<ScoreBoardColumn>;
}

export default class ScoreTable extends Component<Props, State> {

  private scoredata: TournamentScoreData | undefined;
  private sortID: string = "total_to_par";
  private sortDefault: boolean = true;

  constructor(props) {
    super(props);

 
    this.state = {
      playersData: [],
      sortID: "total_to_par",
      playerDetailVisible: [],
      scoreBoardColumns: this.createScoreBoardColumns(TournamentMode.Strokeplay)
    }

    this.renderPlayerRows = this.renderPlayerRows.bind(this);
  }

  _alertIndex(value) {
    if (this.sortID === value) {
      this.sortDefault = !this.sortDefault;
    }
    else {
      this.sortID = value;
      this.sortDefault = true;
    }
  }


  componentDidMount() {
    //Dimensions.addEventListener("change", this.onScreenChanged.bind(this));

    this.processScores();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.tournamentData != this.props.tournamentData) {
      this.processScores();
    }

    if (prevProps.themeName != this.props.themeName) {
      this.processScores();
    }
  }

  createScoreBoardColumns(mode: TournamentMode): Array<ScoreBoardColumn> {

    if (mode == TournamentMode.Stableford) {
      return [
        new ScoreBoardColumnPos(40),
        new ScoreBoardColumnName(null),
        new ScoreBoardColumnTotal(60),
        new ScoreBoardColumnThru(72),
        new ScoreBoardColumnNet(44),
        new ScoreBaordColumnGross(44),
        
        new ScoreBoardColumnPCHcp(58),
        new ScoreBoardColumnScore(52)
      ];
    }
    else if(mode == TournamentMode.StrokeStableMix) {
      return [
        new ScoreBoardColumnPos(40),
        new ScoreBoardColumnName(null),
        new ScoreBoardColumnTotal(60),
        new ScoreBoardColumnThru(72),
        new ScoreBoardColumnNet(44),
        new ScoreBaordColumnGross(44),
        //new ScoreBoardColumnHcp(60),
        new ScoreBoardColumnPCHcp(58),
        new ScoreBoardColumnScore(52)
      ];
    }
    else {
      return [
        new ScoreBoardColumnPos(40),
        new ScoreBoardColumnName(null),
        new ScoreBoardColumnTotal(60),
        new ScoreBoardColumnThru(72),
        new ScoreBaordColumnGross(44),
        new ScoreBoardColumnScore(52)
      ];
    }
  }
  async processScores() {
    if (!this.props.tournamentData) {
      return;
    }
    const tdata = this.props.tournamentData;

    
    
    this.setState({ scoreBoardColumns: this.createScoreBoardColumns(tdata.tournament_details.mode)});
    const courseCombis = await CourseCombiService.getCourseCombis(tdata.course_combi_ids);

    this.scoredata = {
      score_version: 1,
      tournamentDetails: tdata.tournament_details,
      playerdata: tdata.playerData,
      coursecombis: courseCombis
    }

    //const tournamentDetails = this.scoredata.tournamentDetails;

    let sortID = "total_to_par";
    let sortAsc = false;
    if(tdata.tournament_details.mode == TournamentMode.StrokeStableMix || tdata.tournament_details.mode == TournamentMode.Stableford) {
      sortID = "points_net";
      sortAsc = true;
    }
    
    this.scoredata.playerdata.sort((a, b) => {

        if (a.isDQ() != b.isDQ()) {
          return a.isDQ() ? 1 : -1;
        }

        if (a.hasMissedCut() != b.hasMissedCut()) {
          return a.hasMissedCut() ? 1 : -1;
        }

        let holesPlayedPlayerA = 0;
          // iterate player rounds
        for (let i = 0; i < a.tournamentPlayerData.rounds.length; ++i) {
          // get number of holes played
          holesPlayedPlayerA += a.tournamentPlayerData.rounds[i].scores.length;
        }

        let holesPlayedPlayerB = 0;
        // iterate player rounds
        for (let i = 0; i < b.tournamentPlayerData.rounds.length; ++i) {
          // get number of holes played
          holesPlayedPlayerB += b.tournamentPlayerData.rounds[i].scores.length;
        }

        if(holesPlayedPlayerA == 0 && holesPlayedPlayerB == 0) {
          let startDateA = new Date ('1/1/1999 ' + a.tournamentPlayerData.rounds[0].starting_time);
          let startDateB = new Date ('1/1/1999 ' + b.tournamentPlayerData.rounds[0].starting_time);
          return sortAsc ? startDateA.getTime() - startDateB.getTime() : startDateB.getTime() - startDateA.getTime();
        }

        if(holesPlayedPlayerA == 0 && holesPlayedPlayerB > 0) {
          return sortAsc ? 1 : -1;
        }

        if(holesPlayedPlayerB == 0 && holesPlayedPlayerA > 0) {
          return sortAsc ? -1 : 1;
        }

        if(tdata.tournament_details.mode == TournamentMode.Stableford)
        {
          // calculate average net points per hole played
          let avgNetPointsA = holesPlayedPlayerA > 0 ? a.tournamentPlayerData.points_net / holesPlayedPlayerA : 0;

          let avgNetPointsB = holesPlayedPlayerB > 0 ? b.tournamentPlayerData.points_net / holesPlayedPlayerB : 0;
          
          return avgNetPointsA < avgNetPointsB ? (sortAsc ? 1 : -1) : (sortAsc ? -1 : 1);
        }
        else {
          if (a.tournamentPlayerData[sortID] < b.tournamentPlayerData[sortID]) {
            return sortAsc ? 1 : -1;
          }
          else {
            return sortAsc ? -1 : 1;
          }
        }
      }
    );
    

    this.setState({ playersData: this.scoredata.playerdata });


  }

  /*
  async fetchScores()
  {
    let tid = this.props.tid;
    if(typeof tid == 'string') {
      tid = parseInt(tid);
    }
    
    const tdata = await TournamentService.getTournamentPlayerData(tid);

    const courseCombis = await getCourseCombis(tdata.course_combi_ids);
    
    this.scoredata = {
      score_version : 1,
      tournamentDetails: tdata.tournament_details,
      playerdata: tdata.playerData,
      coursecombis: courseCombis
    }

    this.processScores();
  }
  */

  renderPlayerRows(): JSX.Element {
    let rows: Array<JSX.Element> = [];

    let tied = 0;
    this.state.playersData.map((playerData, index) => {
      if (index < this.state.playersData.length - 1) {
        let nextScore = this.state.playersData[index + 1].tournamentPlayerData.total_to_par;
        if (tied == 0 && nextScore == playerData.tournamentPlayerData.total_to_par) {
          tied = index + 1;
        }
      }
      rows.push(this.renderPlayerRow(index, playerData, tied));
      if (index < this.state.playersData.length - 1) {
        let nextScore = this.state.playersData[index + 1].tournamentPlayerData.total_to_par;
        if (nextScore != playerData.tournamentPlayerData.total_to_par) {
          tied = 0;
        }
      }
    })

    return (<>{rows}</>);
  }

  renderPlayerRow(scoreBoardIndex: number, playerData: TournamentPlayer, tied: number) {

    return (
      <ScoreBoardPlayerRow 
        key={`sbpr_${playerData.tournamentPlayerData.player_id}`} 
        scoreBoardColumns={this.state.scoreBoardColumns} 
        themeName={this.props.themeName} 
        playerData={this.state.playersData[scoreBoardIndex]} 
        scoreBoardIndex={scoreBoardIndex} 
        tied={tied} 
      />
    )
  }

  renderHeader() {

    const columnCount = this.state.scoreBoardColumns.length;
    var columns = [];

    for (var i = 0; i < columnCount; ++i) {
      const boardColumn = this.state.scoreBoardColumns[i];

      columns.push((
        <View key={`scoretable_column_${i}`} style={
          {
            flex: boardColumn.width() > 0 ? null : 1,
            alignItems: boardColumn.align(),
            borderLeftWidth: i > 0 ? 1 : 0,
            borderColor: Themes[this.props.themeName].BorderColor,
            //borderTopWidth: 1, 
            width: boardColumn.width(),
            backgroundColor: Themes[this.props.themeName].HeaderBackgroundColor,
            borderRightWidth: 0 //i == columnCount-1 ? 1 : 0
          }}
        >
          <Text style={styles.headerText}>{this.state.scoreBoardColumns[i].label()}</Text>
        </View>
      ))
    }

    return (
      <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
        {columns}
      </View>
    )
  }

  getCourseData(playerId: number, roundIndex): ClientCourseCombi {
    var playerData: TournamentPlayer | null;
    for (var i = 0; i < this.scoredata.playerdata.length; ++i) {
      if (this.scoredata.playerdata[i].tournamentPlayerData.player_id == playerId) {
        playerData = this.scoredata.playerdata[i];
        break;
      }
    }
    if (playerData) {
      const courseCombiId = playerData.tournamentPlayerData.rounds[roundIndex].course_combi_id;

      for (var i = 0; i < this.scoredata.coursecombis.length; ++i) {
        if (this.scoredata.coursecombis[i].id == courseCombiId) {
          return this.scoredata.coursecombis[i];
        }
      }
    }

    return null;
  }

  getPlayerScores(playerId: number, roundIndex: number): Array<ScoreEntry> {

    for (var i = 0; i < this.scoredata.playerdata.length; ++i) {
      if (this.scoredata.playerdata[i].tournamentPlayerData.player_id == playerId) {

        var scores = [];

        for (var j = 0; j < 18; ++j) {
          scores.push(this.scoredata.playerdata[i].tournamentPlayerData.rounds[roundIndex].scores[j])
        }
        return scores;
      }
    }

    //return [{ hole: 0, score: 1, extra_data: ""}]
  }

  render() {

    return (
      <Stack style={{ maxWidth: 1200}}>
        <View /*contentContainerStyle={{ maxWidth: 1200 }}*/ style={{ maxWidth: 1200, borderBottomWidth: 1 }} /*showsVerticalScrollIndicator={true}*/>
          
            {this.renderHeader()}
            {this.state.playersData.length > 0 && <this.renderPlayerRows />}
          
        </View>
      </Stack>
    )
  }
}
// <View style={{ flex: 1 }}>
// </View>

