import { NativeStackScreenProps } from '@react-navigation/native-stack';
import * as React from 'react';
import { Dimensions, ScaledSize, EmitterSubscription, VirtualizedList, ListRenderItemInfo, Pressable } from 'react-native';
import { RootStackParamList } from '../navigation/LGStackNavigator';
import * as AuthService from '../services/AuthService';
import { TournamentService } from '../services/TournamentService';
import { LivegolfHeader } from '../components/LivegolfHeader';
import * as AuthTypes from '../types/AuthTypes';
import * as Types from '../types/types';
import LGPagination from '../components/LGPagination';
import { LGColors } from '../constants/colors';
import { Stack, YStack, Text, useMedia, Separator } from 'tamagui';
import { LGText } from '../styles/StyledText';
import { LGCenter, LGStack, LGYStack } from '../styles/StyledStacks';


//const media = useMedia();

type TournamentList = Array<Types.TournamentListEntry>;
type Props = NativeStackScreenProps<RootStackParamList, 'Home'>;

type State = {
  authState: AuthTypes.AuthState;
  tournamentList: TournamentList;
  windowDims: ScaledSize;
  numPages: number;
  selectedPage: number;
}

const NumPerPage = 20;

export default function HomeScreenWrapper (props: Props)
{
  const media = useMedia();

  return <HomeScreen {...props} media={media} />
}

type HSProps = Props & { media: ReturnType<typeof useMedia> };

class HomeScreen extends React.Component<HSProps, State> {
  private fetchingTournamentList = false;
  private focusListener;
  private dimsSubcription: EmitterSubscription;
  private pendingFetchOffset: number = 0;
  private pendingFetchLimit: number = 0;
  private currentTournamentsFetched: number = 0;
  private onEndReachedCalledDuringMomentum = false;

  constructor(props) {
    super(props);

    this.state= { authState: 'not_logged_in', tournamentList: [], windowDims: Dimensions.get('window'), numPages: 0, selectedPage: 0 };
  }

  componentDidMount(): void {
    
    console.log("HomeScreen: componentDidMount");
    this.dimsSubcription = Dimensions.addEventListener("change", this.onDimsChanged.bind(this));

    const { navigation } = this.props;
    this.focusListener = navigation.addListener("focus", () => {
      this.getUserData();
    });  

    this.getUserData();
  }

  onDimsChanged({ window, screen }) {
    //console.log("dims changed: ", window);
    this.setState({ windowDims: Dimensions.get('window') }, () => {
      this.forceUpdate();
    });
  }

  getUserData() {
    console.log("getUserData");
    try {
      AuthService.fetchUserData().then((userData) => {
        if(userData) {
          console.log("getUserData(): logged in");
          this.setState({ authState: AuthService.getLoginState()});
        }

        this.tryFetchTournamentList(this.state.selectedPage*NumPerPage, NumPerPage);
      },
      (err) => {
        console.log("getUserData(): not logged in");
        this.setState({ authState: 'not_logged_in'});
        this.tryFetchTournamentList(this.state.selectedPage*NumPerPage, NumPerPage);
      }).catch((e) => {
        console.log("getUserData(): catch");
      })
    }
    catch(e) {
      console.log("getUserData: catch2");
    }
  }

  componentWillUnmount(): void {
    if(this.focusListener) {
      //this.focusListener.remove();
      this.focusListener = null;
    }
  }

  tryFetchTournamentList(offset: number, limit: number) {
    if(this.fetchingTournamentList) {
      if(offset > this.pendingFetchOffset) {
        console.log("Queue fetch");
        this.pendingFetchOffset = offset;
        this.pendingFetchLimit += limit;
      }
    }
    else {
      this.fetchTournamentList(offset, limit);
    }
  }

  fetchTournamentList(offset: number, limit: number) {
    console.log("fetchTournamentList");

    if(this.fetchingTournamentList) {
      console.log("fetchTournamentList...fetching already");
      return;
    }

    if(1) {
      TournamentService.getTournamentList(offset, limit).then((tournamentList) => {
        this.setState({ tournamentList: tournamentList.rows, numPages: Math.ceil(tournamentList.count / limit) }, () => {
          this.forceUpdate();
          this.fetchingTournamentList = false;
        });
      },
      (err) => {
        console.log("fetchTournamentList error: ", err);
        this.fetchingTournamentList = false;
      });
    }
    else {

    
      var curTournamentList : TournamentList = [ ...this.state.tournamentList];
      curTournamentList.push( { id: -1} as Types.TournamentListEntry );
      this.setState({ tournamentList: curTournamentList });
      this.pendingFetchLimit = 0;
      this.pendingFetchOffset = 0;
      this.fetchingTournamentList = true;
      TournamentService.getTournamentList(offset, limit).then((tournamentList) => {

        console.log("Total tournament count: ", tournamentList.count);
        var oldTournamentList : TournamentList = [ ...this.state.tournamentList];
        while(oldTournamentList.length > 0 && oldTournamentList[oldTournamentList.length - 1].id == -1) {
          oldTournamentList.pop();
        }

        if(tournamentList.rows.length == 0) 
        {
          console.log("fetchTournamentList empty");
          this.setState({ tournamentList: oldTournamentList }, () => {
            this.forceUpdate();
            this.fetchingTournamentList = false;
          });
          
          
          return;
        };
        
        const newTournamentList : TournamentList = [...oldTournamentList, ...tournamentList.rows];

        newTournamentList.sort((a,b) => { return a.id - b.id }).reverse();

        let currentID = -1;
        const orderedFiltered = newTournamentList.filter((item) => {
          if(item.id != currentID) {
            currentID = item.id;
            return true;
          }
          return false;
        })

        this.currentTournamentsFetched = offset + limit;
        this.setState({ tournamentList: orderedFiltered, numPages: Math.ceil(tournamentList.count / limit) }, () => {
          this.forceUpdate();
        });
        
        this.fetchingTournamentList = false;

        if(this.pendingFetchOffset > 0) {
          this.fetchTournamentList(this.pendingFetchOffset, this.pendingFetchLimit);
        }
      },
      (err) => {
        console.log("fetchTournamentList error: ", err);
        this.fetchingTournamentList = false;
        if(this.pendingFetchOffset > 0) {
          this.fetchTournamentList(this.pendingFetchOffset, this.pendingFetchLimit);
        }
      })
    }
  }

  onRequestVerificationPressed() {

  }

  onTournamentPressed(tid: number) {
    this.props.navigation.navigate("ScoreBoard", { tid: tid});
  }

  tournamentListEndReached(info) {
    console.log("End reached: ", info);

    //if(info.distanceFromEnd > 0) {
      this.tryFetchTournamentList(this.currentTournamentsFetched, NumPerPage);
    //}
  }

  renderTournamentItem(data : ListRenderItemInfo<Types.TournamentListEntry>) {
    //console.log("Item: ", data);

    const item : Types.TournamentListEntry = data.item;

    if(item.id == -1)
    {
      return(
        <Text textAlign="center" w="100%" borderLeftWidth={ this.props.media.gtMd ? 1 : 0}  fontSize="$8">Loading...</Text>
      )
    }

    let courseName = "***";
    let dateMillis = Date.now();

    
    if(item.rounds && item.rounds.length > 0) {
      if(item.rounds[0].course?.name) {
        courseName = item.rounds[0].course.name;
      }

      if(item.rounds[0].date) {
        dateMillis = item.rounds[0].date;
      }
    }
    
    return (
      <Pressable onPress={() => { this.onTournamentPressed(item.id)}} >
      <LGStack pl="8px" bg={data.index % 2 ? LGColors.tableBackgroundOdd : LGColors.tableBackgroundEven} w="100%" flexDirection={this.props.media.gtSm ? 'row' : 'column'} alignItems={ this.props.media.gtSm ? 'center' : null}>
        <LGYStack flex={this.props.media.gtSm ? 1 : null} alignItems={ this.props.media.gtSm ? null : 'center'}>
          <LGText fontSize="$7" fontWeight="bold">{item.name}</LGText>
          <LGText fontSize="$5">{courseName}</LGText>
        </LGYStack>
        
        <LGStack w={ this.props.media.gtSm ? "120px" : "100%"}>
          <LGText textAlign="center" w="100%" borderLeftWidth={this.props.media.gtMd ? 1 : 0}  fontSize="$7">{Types.TournamentMode[item.mode]}</LGText>
        </LGStack>
        <LGStack w={this.props.media.gtSm ? "120px" : "100%"}>
          <LGText textAlign="center" w="100%" borderLeftWidth={this.props.media.gtMd ? 1 : 0} fontSize="$7">{new Date(dateMillis).toISOString().substring(0,10)}</LGText>
        </LGStack>
        { !this.props.media.gtSm &&
          <LGCenter w="100%">
            <Separator w="90%" h="1px" borderColor={LGColors.textPrimary}/>
          </LGCenter>
        }
      </LGStack>
      </Pressable>
    );
  }

  getItem(data: any, index: number): Types.TournamentListEntry {
    return data[Object.keys(data)[index]];
  }

  getItemCount() {
    return Object.keys(this.state.tournamentList).length;
  }

  keyExtractor(item: Types.TournamentListEntry, index: number) : string {
      return String(index);
  }

  handlePageClick(value: number) {
    console.log("handlePageClick: ", value);
    this.setState({ selectedPage: value });
    this.tryFetchTournamentList(value*NumPerPage, NumPerPage);
  }

  render() {

    return (
      <YStack bg={LGColors.background} h={this.state.windowDims.height}>
        <LivegolfHeader title="Live-Golf" navigation={this.props.navigation} />  
          
            { this.state.authState == 'unverified' &&
              <Stack>
                <Text>Email hasn't been verified yet. Please check your inbox or <Text onPress={this.onRequestVerificationPressed.bind(this)} fontWeight="bold">re-request a verification code</Text>.</Text>
              </Stack>
            }
        <Stack flex={1} alignSelf="center" w={this.props.media.gtMd ? "80%" : "100%"} justifyContent='center' alignItems="center">
          <Stack>
            <Stack>
              <LGPagination width={400} count={this.state.numPages} onChange={this.handlePageClick.bind(this)} selected={this.state.selectedPage}/>
            </Stack>
          </Stack>

          <Stack w="100%" flex={1} px="16px">
            <VirtualizedList
            
              initialNumToRender={10}
              data={this.state.tournamentList}
              renderItem={this.renderTournamentItem.bind(this)}
              
              getItemCount={this.getItemCount.bind(this)}
              getItem={this.getItem.bind(this)}
              keyExtractor={this.keyExtractor.bind(this)}
            />
          </Stack>
        </Stack>
      </YStack>
    );
  }
}
