import { Controller } from "react-hook-form"
import { SelectList } from "react-native-dropdown-select-list"
import { LGStack } from "../../styles/StyledStacks";
import { LGText } from "../../styles/StyledText";

export const TournamentEnumSelect = ({ control, enumObject, controlName }) => {

  const data = [];

  Object.keys(enumObject).filter((key) => !isNaN(Number(key))).map((key) => {
    data.push({key: Number(key), value: enumObject[key]})
  })
  
  return (
    
      <Controller control={control} name={controlName}
        render={({ field }) => (
          <SelectList 
              setSelected={(val) => field.onChange(val)} 
              data={data} 
              save="key"
              search={false}
              defaultOption={{key: field.value, value: enumObject[field.value]}}
              fontFamily="$mono"
          />
        )}
      />
    
  )
}
