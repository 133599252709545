import React, { Component, createElement, createRef, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Dimensions, EmitterSubscription, Linking, Pressable, ScaledSize } from 'react-native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../navigation/LGStackNavigator';
import { TournamentService } from '../../services/TournamentService';

import {
  Control,
  Controller,
  FieldPath,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  UseFormGetValues,
  UseFormSetValue
} from "react-hook-form";
import type { DefaultValues } from "react-hook-form";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { LGAdminHeader } from '../../components/LGAdminHeader';

import { DatePicker, MobileTimePicker } from '@mui/x-date-pickers';

import { LGColors } from '../../constants/colors';
import { GroupPlayerStatus } from 'livegolf_shared/lib/types';
import { TournamentStatus, TournamentMode, TournamentVisibility } from '../../types/types';
import { AdminPlayer, AdminTournamentCourse, AdminTournamentData, AdminTournamentGroup, AdminTournamentGroupInputNew, AdminTournamentGroupPlayer, AdminTournamentRound, AdminTournamentRoundDiff } from '../../types/AdminTypes';
import _ from 'lodash';
import ChoosePlayerModal from '../../components/Admin/ChoosePlayerModal';
import { AdminTournamentService } from '../../services/AdminTournamentService';
import ChooseCourseModal from '../../components/Admin/ChooseCourseModal';
import { TIntInput } from '../../components/Admin/TIntInput';
import { TStringInput } from '../../components/Admin/TStringInput';
import { SaveTournamentService } from '../../services/SaveTournamentService';
import { ScrollView, Button, Input, Separator, useMedia } from 'tamagui';
import { LGText } from '../../styles/StyledText';
import { LGCenter, LGXStack, LGYStack, LGStack } from '../../styles/StyledStacks';
import { TournamentEnumSelect } from '../../components/Admin/TournamentEnumSelect';
import { ArraySelect } from '../../components/Admin/ArraySelect';
import { media } from '@tamagui/config';
import { CourseCombiService } from '../../services/CourseCombiService';

dayjs.extend(utc);
dayjs.extend(timezone)

type Props = NativeStackScreenProps<RootStackParamList, 'AdminTournament'>;

type State = {
  windowDims: ScaledSize;
  tournamentData?: AdminTournamentData;
  parentSubmit: boolean;
  hasChanges: boolean;
}



export default class AdminTournamentScreen extends Component<Props, State> {

  private tournamentRounds: Array<AdminTournamentRound> | null;

  private roundsComp;

  private formRef;

  private dimsSubcription: EmitterSubscription;
  private focusListener;
  private blurListener;

  constructor(props) {
    super(props);

    this.state = {
      windowDims: Dimensions.get('window'),
      parentSubmit: false,
      hasChanges: false
    }

    this.formRef = createRef();
  }

  componentDidMount() {
    console.log("admin tournament did mount");
    this.dimsSubcription = Dimensions.addEventListener("change", this.onDimsChanged.bind(this));
    const { navigation } = this.props;
    this.focusListener = navigation.addListener("focus", () => {
      this.onFocus();
    });

    this.blurListener = navigation.addListener("blur", () => {
      this.onBlur();
    })

    this.fetchTournamentData();
  }

  componentWillUnmount(): void {
    console.log("Admin Tournament Screen WillUnmount()");
    if(this.focusListener) {
      if(this.focusListener.remove) {
        this.focusListener.remove();
      }
      this.focusListener = null;
    }

    if(this.blurListener) {
      if(this.blurListener.remove) {
        this.blurListener.remove();
      }
      this.blurListener = null;
    }
  }

  onFocus() {
    console.log("Admin Tournament Screen Focus()");
    this.fetchTournamentData();
  }

  onBlur() {
    console.log("Admin Tournament Screen Blur()");
    this.setState({tournamentData: null});
  }

  async fetchTournamentData() {

    let tid = this.props.route.params.tid;
    if (typeof tid == 'string') {
      tid = parseInt(tid);
    }

    console.log("FetchTournamentData: " + tid);
    

    const tdata = await TournamentService.getAdminTournamentData(tid);

    //console.log("TData: ", tdata);
    this.setState({ tournamentData: tdata }, () => this.forceUpdate());

  }

  async onSubmitted(values: AdminTournamentData) {
    console.log(values);

    //console.time("tdiff");

    //let diff: AdminEditTournamentData = { tournament_edit: { id: values.id, rounds_new: [], rounds_edit: [], rounds_deleted: [] } };
    SaveTournamentService.saveModifiedTournament(this.state.tournamentData, values);

    this.setState({tournamentData: values, hasChanges: false}, () => {
      this.forceUpdate();
    });


    return;
  }

  onValuesChanged(values: AdminTournamentData) {
    console.time("tdiff");

    //let diff: AdminEditTournamentData = { tournament_edit: { id: values.id, rounds_new: [], rounds_edit: [], rounds_deleted: [] } };

    let tournamentDiff = AdminTournamentService.getTournamentDiff(this.state.tournamentData, values);

    let roundDiff: AdminTournamentRoundDiff = AdminTournamentService.getRoundDiffs(this.state.tournamentData, values);

    let groupDiff = AdminTournamentService.getGroupDiffs(this.state.tournamentData, values);

    let groupPlayerDiffs = AdminTournamentService.getGroupPlayerDiffs(this.state.tournamentData, values);

    console.timeEnd("tdiff");

    const hasChanges = Object.keys(tournamentDiff).length > 0 || Object.keys(roundDiff).length > 0 || Object.keys(groupDiff).length > 0 || Object.keys(groupPlayerDiffs).length > 0;

    if(hasChanges)
    {
      console.log("tour: ", tournamentDiff, " round: ", roundDiff, " group: ", groupDiff, " groupPlayer: ", groupPlayerDiffs);
    }

    if (hasChanges != this.state.hasChanges) {
      this.setState({ hasChanges: hasChanges });
    }
  }

  submitRounds(rounds) {
    console.log("Rounds: ", rounds);
  }

  onDimsChanged({ window, screen }) {
    this.setState({ windowDims: Dimensions.get('window') }, () => {
      this.forceUpdate();
    });
  }

  onCancelPressed() {
    this.props.navigation.goBack();
  }

  render() {
    const tid = this.props.route.params.tid;

    const tournamentData = this.state.tournamentData && JSON.parse(JSON.stringify(this.state.tournamentData));
    //let rounds;
    if (tournamentData) {
      this.tournamentRounds = tournamentData.rounds;
      //tournamentData.rounds = null;
    }

    return (
      <LGStack bg="#DEF" h={this.state.windowDims.height} w={this.state.windowDims.width}>
        <LGAdminHeader title="Live-Golf Admin: Tournament Details" navigation={this.props.navigation} />
        <ScrollView>
          <LGStack flex={1} alignItems="center" >
            <ScrollView maxWidth="100%" horizontal={true} pt="16px" showsHorizontalScrollIndicator={true}>
              {tournamentData &&

                <EditTournament onValuesChanged={this.onValuesChanged.bind(this)} onSubmit={this.onSubmitted.bind(this)} tournamentDataIn={tournamentData} ref={this.formRef} windowDims={this.state.windowDims} />
              }
            </ScrollView>
          </LGStack>
        </ScrollView>
        <LGStack h="40px" w="100%" bg={LGColors.primary}>
          {this.state.hasChanges &&
            <LGCenter>
              <Button onPress={() => this.formRef.current.submitForm()}>Test</Button>
            </LGCenter>
            ||
            <LGXStack flex={1} jc="center">
              <LGCenter flex={1}>
                <LGText>No changes detected</LGText>
              </LGCenter>
              <Button w="200px" h="36px" onPress={() => this.onCancelPressed()}>Cancel</Button>
            </LGXStack>
          }
        </LGStack>
      </LGStack>
    )
  }
}



const defaultValues: DefaultValues<AdminTournamentData> = {
  rounds: []
};

type MyFormRef = {
  submitForm: () => void;
};

type MyFormProps = {
  onSubmit: (values) => void;
  onValuesChanged: (values) => void;
  tournamentDataIn: AdminTournamentData;
  windowDims: ScaledSize;
};

const EditTournament = forwardRef<MyFormRef, MyFormProps>(({ tournamentDataIn, onSubmit, onValuesChanged, windowDims }, ref) => {
  const form = useForm<AdminTournamentData>({
    defaultValues: tournamentDataIn
  }
  );
  const { control, register, watch, getValues, setValue, trigger } = form;

  const watchMode = watch("mode");

  const media = useMedia();
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => onValuesChanged(getValues()));
    return () => subscription.unsubscribe();
  }, [watch]);

  const submitRef = useRef(null);

  useImperativeHandle(ref, () => ({

    submitForm() {
      if (submitRef.current) {
        submitRef.current.click();
      }
    }
  }));

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Handle form submission logic here
    onSubmit(getValues());
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>

        <LGYStack px="16px" w="100%" space="4">
          <LGStack alignItems="center" flexDirection={media.sm ? "column" : "row"}>
            <LGText fontSize="$6" w={media.sm ? null : "200px"} fontWeight="bold">Name</LGText>
            <LGStack maxWidth={media.sm ? windowDims.width - 32 : null}>
              <TStringInput inputW='100%' control={control} name="name" getValues={getValues} bg={LGColors.inputPrimary} />
            </LGStack>
          </LGStack>
          
          <LGStack alignItems="center" flexDirection={media.sm ? "column" : "row"}>
            <LGText alignSelf="center" fontSize="$6" w={media.sm ? null : "200px"} fontWeight="bold">Status</LGText>
            <LGStack maxWidth={media.sm ? windowDims.width - 32 : null}>
              <TournamentEnumSelect control={control} controlName="status" enumObject={TournamentStatus}/>
            </LGStack>
          </LGStack>

          <LGStack alignItems="center" flexDirection={media.sm ? "column" : "row"}>
            <LGText alignSelf="center" fontSize="$6" w={media.sm ? null : "200px"} fontWeight="bold">Mode</LGText>
            <LGStack maxWidth={media.sm ? windowDims.width - 32 : null}>
              <TournamentEnumSelect control={control} controlName="mode" enumObject={TournamentMode}/>
            </LGStack>
          </LGStack>

          <LGStack alignItems="center" flexDirection={media.sm ? "column" : "row"}>
            <LGText alignSelf="center" fontSize="$6" w={media.sm ? null : "200px"} fontWeight="bold">Visibility</LGText>
            <LGStack maxWidth={media.sm ? windowDims.width - 32 : null}>
              <TournamentEnumSelect control={control} controlName="visibility" enumObject={TournamentVisibility}/>
            </LGStack>
          </LGStack>
          
          {(watchMode == TournamentMode.ZockerStrokePlay || watchMode == TournamentMode.Zockerplay) &&
            <LGStack>
              <TIntInput inputW='80px' labelW='200px' control={control} label="Best Ball Points" name="best_ball_points" getValues={getValues} bg={LGColors.inputPrimary} />
              <TIntInput inputW='80px' labelW='200px' control={control} label="Aggregate Points" name="aggregate_points" getValues={getValues} bg={LGColors.inputPrimary} />
              <TIntInput inputW='80px' labelW='200px' control={control} label="Birdie Points" name="birdie_points" getValues={getValues} bg={LGColors.inputPrimary} />
              <TIntInput inputW='80px' labelW='200px' control={control} label="Longest Drive Points" name="longest_drive_points" getValues={getValues} bg={LGColors.inputPrimary} />
              <TIntInput inputW='80px' labelW='200px' control={control} label="Nearest Pin Points" name="nearest_pin_points" getValues={getValues} bg={LGColors.inputPrimary} />
            </LGStack>
          }

        </LGYStack>
        <button ref={submitRef} style={{ display: "none" }} type="submit">Submit</button>
        <Rounds getValues={getValues} setValue={setValue} />
      </form>
    </FormProvider>
  );
});

interface RoundProps {
  roundIndex: number;
}

const Rounds = ({ getValues, setValue }: { getValues: UseFormGetValues<AdminTournamentData>, setValue: UseFormSetValue<AdminTournamentData> }) => {
  const { register, control } = useFormContext();
  const {
    fields: children,
    append: appendChild,
    remove: removeChild
  } = useFieldArray<AdminTournamentData>({

    name: `rounds`
  });

  const media = useMedia();

  const [showChooseCourseModal, setShowChooseCourseModal] = useState(-1);

  const deleteRound = function (index) {
    removeChild(index);
  }

  const setCourse = function (course: AdminTournamentCourse) {
    console.log("setCourse: ", showChooseCourseModal);
    console.log("Course: ", course);

    const groups = getValues(`rounds.${showChooseCourseModal}.groups`);

    const courseCombiTeeIds = course.course_combis.map(x => x.tee_id);
    //console.log("Course combi ids: ", courseCombiIds);
    groups.forEach((group, groupIndex) => {
      group.group_players.forEach((player, playerIndex) => {
        if (!courseCombiTeeIds.includes(player.tee_id)) {
          for (let i = 0; i < course.course_combis.length; i++) {
            // find first course combi with matching gender id
            if (course.course_combis[i].gender_id == player.gender_id) {
              setValue(`rounds.${showChooseCourseModal}.groups.${groupIndex}.group_players.${playerIndex}.course_combi_id`, course.course_combis[i].id, { shouldDirty: false });
              setValue(`rounds.${showChooseCourseModal}.groups.${groupIndex}.group_players.${playerIndex}.tee_id`, course.course_combis[i].tee_id, { shouldDirty: false });
              break;
            }
          }
        }
      })
    })
    setValue(`rounds.${showChooseCourseModal}.course`, course, { shouldDirty: false });
    setValue(`rounds.${showChooseCourseModal}.course_id`, course.id, { shouldDirty: true });
  }

  return (
    <fieldset>
      <legend style={{ fontSize: 20 }}>Rounds</legend>
      {children.map((child, index) => (

        <LGStack pt="2px" key={`${index}`} flex={1}>

          <LGYStack px="16px" flex={1} space="4">
            {children.length > 1 &&
              <LGXStack pt="2px" alignSelf="center" space="2">
                <LGText fontSize="$7" fontWeight="bold">Round {index + 1}</LGText>
                {index > 0 &&
                  <Button w="200px" onPress={() => deleteRound(index)}>Delete Round</Button>
                }
              </LGXStack>
            }

            <LGStack ai="center" zIndex={1200} flexDirection={media.sm ? "column" : "row"}>
              <LGText fontSize="$7" w={media.sm ? null : "200px"} fontWeight="bold">Date</LGText>
              <Controller control={control} name={`rounds.${index}.date`}
                render={({ field }) => {
                  console.log("Date1: ", field.value);
                  return (
                      createElement('input', {
                         type: 'date',
                         value:  dayjs(field.value).format("YYYY-MM-DD"),
                         onInput: (value) => { field.onChange(value.target.value + "T12:00:00"); console.log("Date on change: ", value.target.value) }
                      })
                  )
                }}

              />

            </LGStack>
            <LGStack ai='center' flexDirection={media.sm ? "column" : "row"}>
              <LGText fontSize="$7" w={media.sm ? null : "200px"} fontWeight="bold">Course</LGText>
              <Controller control={control} name={`rounds.${index}.course.name`}
                render={({ field }) => {
                  return (
                    <Pressable onPress={() => setShowChooseCourseModal(index)}>
                      <LGStack w="300px" h="40px" jc="center" ai="center" borderRadius={8} bg={LGColors.inputPrimary}>
                        <LGText fontSize="$7" >{field.value}</LGText>
                      </LGStack>
                    </Pressable>
                  )
                }}
              />
            </LGStack>
            <LGStack ai="center" flexDirection={media.sm ? "column" : "row"}>
              <LGText fontSize="$7" w={media.sm ? null : "200px"} fontWeight="bold">HCP Factor</LGText>
              <Controller control={control} name={`rounds.${index}.hcp_factor`}

                render={({ field }) => {
                  return (
                    <Input
                      bg={LGColors.inputPrimary}
                      fontSize="$7"
                      w="300px"
                      value={String(field.value)}
                      onChangeText={(value) => field.onChange(value)}
                      onBlur={(event) => { const newValue = parseFloat(event.nativeEvent.text); field.onChange(isNaN(newValue) ? 1 : newValue) }}

                    />
                  )
                }}
              />
            </LGStack>

            <Groups roundIndex={index} getValues={getValues} setValue={setValue} />


            {/*index == formik.values.length - 1 &&
              <Button onPress={() => addRound()}>Add Round</Button>*/
            }
          </LGYStack>
        </LGStack>

      ))}
      
      <ChooseCourseModal isVisible={showChooseCourseModal != -1} onCourseSelect={(course) => setCourse(course)} onClose={() => { console.log("on close"); setShowChooseCourseModal(-1) }} />
    </fieldset>
  )
};

const Groups = ({ roundIndex, getValues, setValue }: { getValues: UseFormGetValues<AdminTournamentData>, roundIndex: number, setValue: UseFormSetValue<AdminTournamentData> }) => {
  const { register, control } = useFormContext<AdminTournamentData>();
  const {
    fields: children,
    append: appendChild,
    remove: removeChild
  } = useFieldArray<AdminTournamentData>({

    name: `rounds.${roundIndex}.groups`
  });

  const media = useMedia();

  const deleteGroup = function (index) {
    removeChild(index);
  }

  function addGroup() {
    const newGroup: AdminTournamentGroupInputNew = {
      id: `g_${children.length + 1}`,
      tee: 1,
      starting_time: "09:00",
      round_id: getValues(`rounds.${roundIndex}.id`)
    }
    appendChild(newGroup as unknown as AdminTournamentGroup);
  }

  const getHoleCount = function() {
    const courseCombis = getValues(`rounds.${roundIndex}.course.course_combis`);
    
    if(courseCombis.length > 0 && courseCombis[0].holes)
    {
        return courseCombis[0].holes.length;
    }

    return 0;
  }

  return (
    <fieldset>
      <legend style={{ fontSize: 20 }}>Groups</legend>
      {children.map((group, index) => (
        <LGStack pt="2px" key={`group_${group.id}`}>
          {children.length > 1 &&
            <Separator borderColor="#804" borderWidth={2} />
          }
          <LGYStack bg="#FFDADA" p="8px">
            <LGCenter>
              <LGXStack flex={1} space>
                <LGCenter>
                  <LGText fontSize="$6" fontWeight="bold">Group {index + 1} </LGText>
                </LGCenter>
                <LGCenter>
                  <Pressable onPress={ () => Linking.openURL('https://golf.runi.de/Score?gid=' + getValues(`rounds.${roundIndex}.groups.${index}.id`))}>
                    <LGCenter w="180px">
                      <LGText>Open Scoring</LGText>
                    </LGCenter>
                  </Pressable>
                </LGCenter>
                <Button size="sm" onPress={() => deleteGroup(index)}>Delete</Button>
              </LGXStack>
            </LGCenter>
            <TIntInput labelW="100px" inputW="80px" control={control} label="Tee" name={`rounds.${roundIndex}.groups.${index}.tee`} minValue={1} maxValue={getHoleCount()} getValues={getValues} bg={LGColors.inputSecondary} />

            <Controller control={control} name={`rounds.${roundIndex}.groups.${index}.starting_time`}

              render={({ field }) => (
                <LGStack alignItems="center" flexDirection={media.sm ? "column" : "row"}>
                  <LGText fontSize="$6" w="100px" fontWeight="bold">Time</LGText>
                  <LGStack bg="#FED" w="80px">
                    <MobileTimePicker
                      format="HH:mm"
                      value={dayjs("2022-01-01T" + field.value)}
                      onChange={(newTime) => field.onChange(newTime.format("HH:mm"))}
                      slotProps={{ textField: { color: 'secondary', size: 'small' } }}
                    />
                  </LGStack>
                </LGStack>
              )}
            />
            <GroupPlayers roundIndex={roundIndex} groupIndex={index} getValues={getValues} setValue={setValue} />

          </LGYStack>
        </LGStack>
      ))}
      <Button onPress={() => addGroup()}>Add Group</Button>
    </fieldset>
  )
}

const groupPlayerTable = {
  index: "44px",
  tee_id: "100px",
  hcp: "70px",
  name: "200px",
  status: "100px",
  remove: "75px",
  height: "44px"
}

export const GroupPlayers = ({ roundIndex, groupIndex, getValues, setValue }: { roundIndex: number, groupIndex: number, getValues: UseFormGetValues<AdminTournamentData>, setValue: UseFormSetValue<AdminTournamentData> }) => {
  const { register, control } = useFormContext();
  const {
    fields: children,
    append: appendPlayer,
    remove: removePlayer
  } = useFieldArray<AdminTournamentData>({

    name: `rounds.${roundIndex}.groups.${groupIndex}.group_players`
  });

  const [showAddPlayerModal, setShowAddPlayerModal] = useState(false);

  const media = useMedia();

  const getCourseCombi = function (teeId: number) {
    //console.log("getCourseCombi(" + teeId + ")");
    const courseCombis = getValues(`rounds.${roundIndex}.course.course_combis`)
    if (courseCombis?.length > 0) {
      for (let i = 0; i < courseCombis.length; ++i) {
        if (courseCombis[i].tee_id == teeId) {
          //console.log("getCourseCombi: ", courseCombis[i]);
          return courseCombis[i]
        }
      }
    }
    return null;
  }

  const getAvailableTeeIds = function (gender_id) {

    const courseCombis = getValues(`rounds.${roundIndex}.course.course_combis`)

    let tee_ids = [];

    if (courseCombis?.length > 0) {
      for (let i = 0; i < courseCombis.length; ++i) {
        if (courseCombis[i].gender_id == gender_id) {
          tee_ids.push({ key: courseCombis[i].tee_id, value: courseCombis[i].tee_name });
        }
      }
    }

    return tee_ids;
  }

  const onRemovePlayer = function (playerIndex: number) {
    removePlayer(playerIndex);
    const groupPlayers = getValues(`rounds.${roundIndex}.groups.${groupIndex}.group_players`);
    for (let i = 0; i < groupPlayers.length; ++i) {
      groupPlayers[i].start_index = i;
    }
  }

  const addPlayer = function (newPlayerData: AdminPlayer) {
    console.log("New player data: ", newPlayerData);
    const groupPlayers = getValues(`rounds.${roundIndex}.groups.${groupIndex}.group_players`);
    const courseCombis = getValues(`rounds.${roundIndex}.course.course_combis`);

    let tee_id = -1;
    // check if there's already a player of the same gender and if there is, use that tee_id
    for (let i = 0; i < groupPlayers.length; ++i) {
      if (groupPlayers[i].gender_id == newPlayerData.gender_id) {
        tee_id = groupPlayers[i].tee_id;
        break;
      }
    }

    // no gender match, let's try to find a tee for this player in the course combis
    if (tee_id == -1) {

      for (let i = 0; i < courseCombis.length; ++i) {
        if (courseCombis[i].gender_id == newPlayerData.gender_id) {
          tee_id = courseCombis[i].tee_id;
          break;
        }
      }
    }

    // still no tee, handle error!!!
    if (tee_id == -1) {
      throw ("no tee found!");
    }

    const courseCombi = getCourseCombi(tee_id);

    const playerData: AdminTournamentGroupPlayer = {
      group_id: getValues(`rounds.${roundIndex}.groups.${groupIndex}.id`),
      tee_id: tee_id,
      start_index: groupPlayers.length,
      gender_id: newPlayerData.gender_id,
      hcp: newPlayerData.last_hcp,
      course_combi_id: courseCombi.id,
      player: { id: newPlayerData.id, firstname: newPlayerData.firstname, lastname: newPlayerData.lastname },
      score_type: 0,
      status: GroupPlayerStatus.active,
      team_id: 0
    }
    appendPlayer(playerData);
  }

  const onTeeChanged = function(playerField, playerIndex: number, tee_id: number) 
  {
    if(playerField)
    {
      if(playerField.value.tee_id != tee_id)
      {
        playerField.value.tee_id = tee_id;
        playerField.value.course_combi_id = getCourseCombi(tee_id).id;
        setValue(`rounds.${roundIndex}.groups.${groupIndex}.group_players.${playerIndex}.tee_id`, tee_id, { shouldDirty: true });
      }
    }
  }

  return (
    <>
      <LGStack pt="16px">
        { !media.sm &&
          <LGXStack>
            <LGCenter w={groupPlayerTable.index} borderWidth={1} bg={LGColors.primaryLabel}>
              <LGText alignSelf="center" fontSize="$6" fontWeight="bold">
                Idx
              </LGText>
            </LGCenter>
            <LGStack w={groupPlayerTable.name} jc="center" borderTopWidth={1} borderBottomWidth={1} borderRightWidth={1}>
              <LGText pl="4px" fontSize="$6" fontWeight="bold" bg={LGColors.primaryLabel}>
                Name
              </LGText>
            </LGStack>
            <LGCenter borderTopWidth={1} borderBottomWidth={1} borderRightWidth={1} w={groupPlayerTable.tee_id} bg={LGColors.primaryLabel}>
              <LGText alignSelf="center" fontSize="$6" fontWeight="bold">
                Tee ID
              </LGText>
            </LGCenter>
            <LGCenter borderTopWidth={1} borderBottomWidth={1} borderRightWidth={1} w={groupPlayerTable.hcp} bg={LGColors.primaryLabel}>
              <LGText alignSelf="center" fontSize="$6" fontWeight="bold">
                Hcp
              </LGText>
            </LGCenter>
            <LGCenter borderTopWidth={1} borderBottomWidth={1} borderRightWidth={1} w={groupPlayerTable.status} bg={LGColors.primaryLabel}>
              <LGText alignSelf="center" fontSize="$6" fontWeight="bold">
                Status
              </LGText>
            </LGCenter>
            <LGCenter borderTopWidth={1} borderBottomWidth={1} borderRightWidth={1} w={groupPlayerTable.remove} bg={LGColors.primaryLabel}>
              <LGText alignSelf="center" fontSize="$6" fontWeight="bold">
                Remove
              </LGText>
            </LGCenter>
          </LGXStack>
        }


        {children.map((groupPlayer, index) => (
          <Controller 
            key={getValues(`rounds.${roundIndex}.groups.${groupIndex}.group_players.${index}.player.id`)} 
            control={control} 
            name={`rounds.${roundIndex}.groups.${groupIndex}.group_players.${index}`}
            render={({ field }) => (
              <>
              { media.sm &&
              <LGStack>
                <LGXStack>
                  <LGText w="80px" alignSelf="center" fontSize="$6" bg={LGColors.primary2}>
                        {index + 1}
                  </LGText>
                  <LGText pl="8px" fontSize="$6">
                    {field.value.player.firstname} {field.value.player.lastname}
                  </LGText>
                </LGXStack>
                <LGXStack>
                  <LGText w="80px" bg={LGColors.primary2}>Tee</LGText>
                  <ArraySelect onChanged={(value) => onTeeChanged(field, index, value)} control={control} valueArray={getAvailableTeeIds(groupPlayer.gender_id)} controlName={`rounds.${roundIndex}.groups.${groupIndex}.group_players.${index}.tee_id`} />
                </LGXStack>
                <LGXStack>
                  <LGText w="80px"bg={LGColors.primary2}>Hcp</LGText>
                  <Controller control={control} name={`rounds.${roundIndex}.groups.${groupIndex}.group_players.${index}.hcp`}
                    render={({field}) => (
                      <Input
                        h="36px"
                        fontSize="$6"
                        w="60px"
                        value={String(field.value)}
                        onChangeText={(value) => field.onChange(value)}
                        onBlur={(event) => { field.onChange(parseFloat(event.nativeEvent.text.replace(',','.')));}}
                      />
                    )}
                  />
                </LGXStack>
                <LGXStack>
                  <LGText w="80px"bg={LGColors.primary2}>Status</LGText>
                  <LGText alignSelf="center" fontSize="$6">
                    {GroupPlayerStatus[field.value.status]}
                  </LGText>
                </LGXStack>
                <LGCenter>
                  <Pressable onPress={() => onRemovePlayer(index)}>
                    <LGCenter borderWidth={1}>
                      <LGText bg={LGColors.inputPrimary} textAlign="center" fontSize="$6">
                        Remove Player
                      </LGText>
                    </LGCenter>
                  </Pressable>
                </LGCenter>
                <Separator my="4px" borderColor={LGColors.primary} />
              </LGStack>

              ||
                <LGXStack bg={LGColors.primary} minHeight={groupPlayerTable.height}>
                  <LGCenter minHeight={groupPlayerTable.height} w={groupPlayerTable.index} borderLeftWidth={1} borderBottomWidth={1} borderRightWidth={1} >
                    <LGText alignSelf="center" fontSize="$6">
                      {index + 1}
                    </LGText>
                  </LGCenter>
                  <LGStack minHeight={groupPlayerTable.height} w={groupPlayerTable.name} jc="center" borderBottomWidth={1} borderRightWidth={1}>
                    <LGText pl="4px" fontSize="$6">
                      {field.value.player.firstname} {field.value.player.lastname}
                    </LGText>
                  </LGStack>
                  <LGCenter borderBottomWidth={1} borderRightWidth={1} minHeight={groupPlayerTable.height} w={groupPlayerTable.tee_id}>
                    <ArraySelect onChanged={(value) => onTeeChanged(field, index, value)} control={control} valueArray={getAvailableTeeIds(groupPlayer.gender_id)} controlName={`rounds.${roundIndex}.groups.${groupIndex}.group_players.${index}.tee_id`} />
                  </LGCenter>
                  <LGCenter borderBottomWidth={1} borderRightWidth={1} minHeight={groupPlayerTable.height} w={groupPlayerTable.hcp}>
                    <Controller control={control} name={`rounds.${roundIndex}.groups.${groupIndex}.group_players.${index}.hcp`}
                      render={({field}) => (
                        <Input
                          h="36px"
                          fontSize="$6"
                          w="100%"
                          value={String(field.value)}
                          onChangeText={(value) => field.onChange(value)}
                          onBlur={(event) => { field.onChange(parseFloat(event.nativeEvent.text.replace(',','.')));}}
                        />
                      )}
                    />
                  </LGCenter>

                  <LGCenter borderBottomWidth={1} borderRightWidth={1} minHeight={groupPlayerTable.height} w={groupPlayerTable.status}>
                    <LGText alignSelf="center" fontSize="$6">
                      {GroupPlayerStatus[field.value.status]}
                    </LGText>
                  </LGCenter>
                  <Pressable onPress={() => onRemovePlayer(index)}>
                    <LGCenter h="100%" borderBottomWidth={1} borderRightWidth={1} w={groupPlayerTable.remove}>
                      <LGText textAlign="center" fontSize="$6">
                        X
                      </LGText>
                    </LGCenter>
                  </Pressable>
                </LGXStack>
                
              }
              </>
            )}
          />
        ))}
        { media.sm &&
          <Pressable onPress={() => setShowAddPlayerModal(true)}>
            <LGXStack bg="#FFD0D0">
              <LGStack w={groupPlayerTable.name} h={groupPlayerTable.height} jc="center"><LGText pl="4px" fontSize="$6">Add player</LGText></LGStack>
            </LGXStack>
          </Pressable>
        ||
          <Pressable onPress={() => setShowAddPlayerModal(true)}>
            <LGXStack bg="#FFD0D0">
              <LGCenter h={groupPlayerTable.height} w={groupPlayerTable.index} borderLeftWidth={1} borderBottomWidth={1} borderRightWidth={1} ><LGText alignSelf="center" fontSize="$6">+</LGText></LGCenter>
              <LGStack w={groupPlayerTable.name} h={groupPlayerTable.height} jc="center" borderBottomWidth={1} borderRightWidth={1}><LGText pl="4px" fontSize="$6">Add player</LGText></LGStack>
            </LGXStack>
          </Pressable>
        }
      </LGStack>
      <ChoosePlayerModal isVisible={showAddPlayerModal} onPlayerSelect={(player) => addPlayer(player)} onClose={() => { console.log("on close"); setShowAddPlayerModal(false) }} />
    </>
  )
}




/*<DatePicker
                      value={dayjs(field.value)}
                      onChange={(value) => field.onChange(value.utc(false).set("hour", 12).unix() * 1000)}
                  />*/