import { Gender } from "../types/types";

export namespace HoleHelper {

    export function getSuggestedParFromLength(length: number, gender: Gender): number {

        if(gender == Gender.female) {
            if(length < 200) {
                return 3;
            } else if(length > 366) {
                return 5;
            } else {
                return 4;
            }
        }
        else
        {
            if(length < 230) {
                return 3;
            } else if(length > 430) {
                return 5;
            } else {
                return 4;
            }
        }
    }
}
