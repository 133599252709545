import React, { Component } from 'react';
import { StyleSheet, View, ScrollView, TouchableOpacity, Text, Linking, Dimensions, ActivityIndicator } from 'react-native';
import { URL } from 'react-native-url-polyfill';
import * as Font from 'expo-font';
import ScoreTable from './ScoreTable';
import { getScoreName } from '../services/ScoreService';
import Svg, { Polyline } from 'react-native-svg';
import { GroupService } from '../services/GroupService';
import * as ScoreService from '../services/ScoreService';

import Themes from './EnterScoreThemes';
import { TournamentData, ScoreTableDataEntry, ScoreEditData, HoleData, ScoreData, CourseData, ScoreInput, GroupPlayerData, LGUnit } from '../types/types';
import { TournamentService } from '../services/TournamentService';
import _ from 'lodash';
import { PlayerService } from '../services/PlayerService';
import { GroupPlayerStatus } from 'livegolf_shared/lib/types';


type State = {
  tournamentdata?: TournamentService.ScoreBoardTournamentData;
  playersData : Array<any>;
  previousHole: number;
  currentHole: number;
  scoreTableData: Array<ScoreTableDataEntry> | null;
  enterActive: ScoreEditData | null;
  fontsLoaded: boolean;
  currentHoleData: { [n:string] : HoleData};
  scoreTableTheme: string;
  sendingScores: boolean;
  sendStateColor: 'green' | 'red' | 'black';
  receiveStateColor: 'green' | 'red' | 'black' | 'white';
  loadingScores: boolean;
}

export default class EnterScore extends Component<{gid: string}, State> {

  private scoredata : ScoreData | null = null;
  private coursedata : CourseData | null = null;
  private pendingScores : Array<ScoreInput> = [];
  private tickInterval : NodeJS.Timer;
  private currentThemeName : string = 'dark';
  private groupStartTee : number = 0;

  constructor(props) {
    super(props);

    this.state = {
      playersData: [],
      previousHole: 0,
      currentHole: 1,
      scoreTableData: null,
      enterActive: null,
      fontsLoaded: false,
      currentHoleData: {},
      scoreTableTheme: 'dark',
      sendingScores: false,
      sendStateColor: 'green',
      receiveStateColor: 'green',
      loadingScores: false
    }

    this.renderScoreInput = this.renderScoreInput.bind(this);
    this.renderScoreTable = this.renderScoreTable.bind(this);
  }
 
  async loadFonts() {
    await Font.loadAsync({
      'LCDMono': require('../assets/fonts/alarmclock.ttf'),
      'roboto': require('../assets/fonts/roboto/Roboto-Regular.ttf'),
      'roboto_light': require('../assets/fonts/roboto/Roboto-Light.ttf'),
      'roboto_bold': require('../assets/fonts/roboto/Roboto-Bold.ttf'),
    });

    this.setState({ fontsLoaded: true });
  }

  async componentDidMount()
  {
    this.loadFonts();
    await this.fetchScoresData();
    this.processScores();

    this.startTick();
  }

  componentWillUnmount(): void {
    this.stopTick();
  }

  startTick()
  {
    this.tickInterval = setInterval(this.tick.bind(this), 5000);
  }

  stopTick()
  {
    if(this.tickInterval)
    {
      clearInterval(this.tickInterval);
      this.tickInterval = null;
    }    
  }

  tick() {
    //console.log("Tick: ", this.pendingScores?.length);
    //console.log("Tick2: ", this.state.sendingScores);
    if(this.pendingScores?.length > 0 && !this.state.sendingScores) {
      this.trySendPendingScores();
    }
  }

  async fetchScoresData()
  {
    this.setState({ loadingScores: true});
    let newURL = await Linking.getInitialURL();

    if(newURL) {
      //const url = new URL(newURL);
      const group_id = this.props.gid; //url.searchParams.get('gid');
      const group = await GroupService.getGroupPlayersWithScores(group_id);
      this.scoredata = JSON.parse(JSON.stringify(group));
      console.log("Scoredata: ", this.scoredata);
      this.groupStartTee = this.scoredata.group_players[0].group.tee-1;
      this.fetchTournamentData();
    }    
    this.setState({ loadingScores: false})
  }

  getHoleCount()
  {
    if(this.scoredata?.group_players?.length > 0) {
      return this.scoredata.group_players[0].course_combi.holes.length;
    }
    
    return 0;
  }

  async fetchTournamentData() {
    const tdata = await TournamentService.getTournamentPlayerData(this.scoredata.round.tournament.id);
    if(tdata) {
      this.setState({ tournamentdata: tdata});
    }    
  }

  getScoreColor(score: number, par: number) {
    const scorename = getScoreName(score, par);
    return Themes[this.currentThemeName].ScoreColors[scorename];
  }

  processScores() {
    const currentHole = this.getCurrentHole();
    this.jumpToHole(currentHole);
  }

  jumpToHole(holeIndex: number) {
    if(holeIndex < 0) {
      holeIndex = this.getHoleCount()-1;
    }

    if(holeIndex > this.getHoleCount()-1) {
      holeIndex = 0;
    }

    this.setState({ 
      currentHole: holeIndex, 
      previousHole: holeIndex == 0 && this.groupStartTee > 0 ? this.getHoleCount()-1 : holeIndex-1,
      scoreTableData: this.getScoreTableData(holeIndex),
      currentHoleData: this.getCurrentHoleData(holeIndex) //this.coursedata.holes[`${ holeIndex+1 }`]
    })
  }

  getCurrentHoleData(holeIndex: number) : { [n:string] : HoleData} {
    
    const holeData : {[n:string] : HoleData} = {};
    this.scoredata?.group_players.map((playerData) => {

      if(!playerData.course_combi) {
        return;
      }

      const curHole = _.cloneDeep(playerData.course_combi.holes[holeIndex]);
      
      if(playerData.course_combi.unit == LGUnit.yards)
      {
        curHole.length = Math.round(curHole.length * 0.9144);
      }
      holeData[playerData.player.id] = curHole;
    });

    return holeData;
  }

  addPendingScore(playerId: number, holeIndex: number, score: number) {

    this.pendingScores.map((scoreEntry) => {
      if(scoreEntry.player_id == playerId && scoreEntry.hole == holeIndex+1) {
        scoreEntry.score = score;
        scoreEntry.time_changed = Date.now();
        return;
      }
    })

    this.pendingScores.push({ group_id: this.props.gid, player_id: playerId, hole: holeIndex+1, score: score, extraData: "", time_created: Date.now()});
  }

  getCurrentHole() : number {

    let startIndex = this.groupStartTee;

    if(this.scoredata 
      && this.scoredata.group_players 
      && this.scoredata.group_players.length > 0)
      {
        let holesChecked = 0;

        console.log("Group Startindex: ", startIndex);
        //let curIndex = startIndex;

        while(this.allPlayersHaveScoresAtHole(startIndex) && startIndex < this.scoredata.group_players[0].course_combi.holes.length) {
          startIndex++;
          if(startIndex == this.scoredata.group_players[0].course_combi.holes.length) {
            startIndex = 0;
          }          
          holesChecked++;
          console.log("Holes Checked: ", holesChecked, " - ", startIndex);
          if(holesChecked == this.scoredata.group_players[0].course_combi.holes.length) {
            break;
          }
        }

        /*for(var i = 0; i < this.scoredata.group_players[0].scores.length; ++i)

        && this.scoredata.group_players[0].scores
        && this.scoredata.group_players[0].scores.length > 0
        ) {
          
          return Math.min(17, this.scoredata.group_players[0].scores.length);
        }*/
      }
      
      console.log("CurrentHole: ", startIndex);
    return startIndex;
  }

  getPreviousHole(currentHole) : number{
    if(currentHole == 0)
    {
      return this.getHoleCount()-1;
    }

    return currentHole-1;
  }

  allPlayersHaveScoresAtHole(holeIndex: number) : boolean {
    for(var i = 0; i < this.scoredata.group_players.length; ++i) {
      if(!this.getPlayerScore(this.scoredata.group_players[i], holeIndex)) {
        return false;
      }
    }
    return true;
  }

  getScoreTableData(currentHole: number) : Array<ScoreTableDataEntry> {
    var scoreTableData : Array<ScoreTableDataEntry> = [];
    
    this.scoredata?.group_players.map((playerData) => {
      var curEntry : ScoreTableDataEntry = { holeIndex: currentHole, playerId: playerData.player.id, name: `${playerData.player.firstname} ${playerData.player.lastname}`, previousScore: this.getPlayerScore(playerData, this.getPreviousHole(currentHole)), currentScore: this.getPlayerScore(playerData, currentHole)};
      scoreTableData.push(curEntry);
    });

    return scoreTableData;
  }

  getPlayerScore(playerData: GroupPlayerData, holeIndex: number) : number {
    if(holeIndex >= 0 && holeIndex < playerData.course_combi.holes.length && playerData.scores) {
      for(var i = 0; i < playerData.scores.length; ++i) {
        if(playerData.scores[i].hole-1 == holeIndex) {
          return playerData.scores[i].score;
        }
      }
      //return playerData.scores[holeIndex].score;
    }
    return 0;
  }

  startEnterPreviousScore(holeIndex: number, playerId: number, score: number) {
    if(this.state.enterActive) {
      this.setState({ enterActive: null });
    }
    else {
      this.startEnterScore(score, holeIndex, playerId);
    }
  }

  startEnterCurrentScore(holeIndex: number, playerId: number, score: number) {
    if(this.state.enterActive) {
      this.setState({ enterActive: null });
    }
    else {
      this.startEnterScore(score, holeIndex, playerId);
    }
  }

  renderNameRows() {
    var rows : Array<JSX.Element> = [];

    if(this.state.scoreTableData?.length)
    {
      
      this.state.scoreTableData.map((data, index) => {

        var backgroundColor = '#000';
        var textColor = '#0FF';
        var previousBackgroundColor = '#000';
        var previousTextColor = '#0FF';
        var currentBackgroundColor = '#000';
        var currentTextColor = '#0FF';

        if(this.state.enterActive) {
          if(this.state.enterActive.player_id == data.playerId) {
            backgroundColor = '#0FF';
            textColor = '#000';

            if(this.state.enterActive.hole == data.holeIndex-1) {
              previousBackgroundColor = '#0FF';
              previousTextColor = '#000';
            }
            else if(this.state.enterActive.hole == data.holeIndex) {
              currentBackgroundColor = '#0FF';
              currentTextColor = '#000';
            }
          }
        }

        rows.push((
          <View key={`name_row_${index}`} style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, backgroundColor: backgroundColor, borderColor: '#BBB', borderBottomWidth: 1, borderLeftWidth: 1 }}>
              <Text numberOfLines={1} style={[styles.scoreTableText, { color: textColor}]}>{data.name}</Text>
            </View>
            <TouchableOpacity onPress={() => { if(data.previousScore > 0) { this.startEnterPreviousScore(data.holeIndex-1, data.playerId, data.previousScore)}}}>
              <View style={{ justifyContent: 'center', alignItems: 'center', width: 48, backgroundColor: previousBackgroundColor, borderColor: '#BBB', borderBottomWidth: 1, borderLeftWidth: 1 }}>
                <Text style={[styles.scoreTableText, { color: previousTextColor}]}>{data.previousScore > 0 ? data.previousScore : '-'}</Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { if(data.previousScore > 0 || data.holeIndex == this.groupStartTee || data.holeIndex == 0) { this.startEnterCurrentScore(data.holeIndex, data.playerId, data.currentScore)}}}>
              <View style={{ justifyContent: 'center', alignItems: 'center', width: 48, borderColor: '#BBB', backgroundColor: currentBackgroundColor, borderBottomWidth: 1, borderLeftWidth: 1, borderRightWidth: 1 }}>
                <Text style={[styles.scoreTableText, { color: currentTextColor}]}>{ data.currentScore > 0 ? data.currentScore : '-'}</Text>
              </View>
            </TouchableOpacity>
          </View>
        ))
        
      })
    }
    return rows;
  }

  renderScoreTable() {
    const prevHole = this.state.previousHole == -1 ? '-' : this.state.previousHole+1;
    return (
      <View style={{ minWidth: 280 }}>
      <View style={{ flexDirection: 'row', width: '100%'}}>
        <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, borderColor: '#BBB', borderBottomWidth: 1, borderTopWidth: 1, borderLeftWidth: 1 }}>
          <Text style={styles.headerText}>Hole</Text>
        </View>
        <TouchableOpacity onPress={ () => this.jumpToHole(this.state.currentHole-1)}>
          <View style={{ justifyContent: 'center', alignItems: 'center', width: 48, borderColor: '#BBB', borderBottomWidth: 1, borderTopWidth: 1, borderLeftWidth: 1 }}>
            <Text style={styles.headerText}>{prevHole}</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={ () => this.jumpToHole(this.state.currentHole+1)}>
          <View style={{ justifyContent: 'center', alignItems: 'center', width: 48, borderColor: '#BBB', borderBottomWidth: 1, borderTopWidth: 1, borderLeftWidth: 1, borderRightWidth: 1 }}>
            <Text style={styles.headerText}>{ this.state.currentHole+1 }</Text>
          </View>
        </TouchableOpacity>
      </View>
        { this.renderNameRows() }
      
      </View>
    )
  }

  onScorePressed(score: number | string) {

    if(typeof score == 'number') {
      this.addPendingScore(this.state.enterActive.player_id, this.state.enterActive.hole, score);

      const curPlayerId = this.state.enterActive.player_id;
      const curHoleIndex = this.state.enterActive.hole;
      const isCurrentHole = curHoleIndex == this.state.scoreTableData[0].holeIndex;

      this.setPlayerScore(curPlayerId, curHoleIndex, score);
      this.setState({scoreTableData: this.getScoreTableData(isCurrentHole ? curHoleIndex : curHoleIndex + 1)}, () =>
      {
        this.gotoNextPlayer();
      });
    }
    else if(typeof score == 'string') {
      if(score == 'wd') {
        console.log("WD: ", this.state.enterActive.player_id);
        this.setPlayerWD(this.state.enterActive.player_id);
        this.gotoNextPlayer();
      }
    }
  }

  setPlayerScore(playerId: number, holeIndex: number, score: number) {
    //console.log("ScoreData: ", this.scoredata);
    var bFound = false;
    for(var i=0; i<this.scoredata.group_players.length; ++i) {
      if(this.scoredata.group_players[i].player.id == playerId) {
        if(this.scoredata.group_players[i].scores) {
          for(var j=0; j<this.scoredata.group_players[i].scores.length; ++j) {
            if(this.scoredata.group_players[i].scores[j].hole == holeIndex+1) {
              this.scoredata.group_players[i].scores[j].score = score;
              bFound = true;
              break;
            }
          }
          //this.scoredata.group_players[i].scores[holeIndex].score = score;
        }
        break;
      }
    }

    if(!bFound)
    {
      if(!this.scoredata.group_players[i].scores) {
        this.scoredata.group_players[i].scores = [];
      }
      this.scoredata.group_players[i].scores.push({ score: score, hole: holeIndex+1, extra_data: "" });
    }
  }

  setPlayerWD(playerId: number) {
    // TODO: resend if fails
    PlayerService.modifyGroupPlayer({ group_id: this.scoredata.group_players[0].group.id, player_id: playerId, status: GroupPlayerStatus.withdrawn});
  }

  gotoNextPlayer() {
    const curHoleIndex = this.state.enterActive.hole;
    const nextPlayer = this.getNextPlayer();
    this.setState({ enterActive : null}, () => {
      //console.log("Next Player: ", nextPlayer);
      if(nextPlayer) {
        
        this.startEnterScore(0, curHoleIndex, nextPlayer);
      }
      else {
        this.processScores();
        console.log("Pending: ", this.pendingScores);
        this.trySendPendingScores();
      }
    });
  }

  startEnterScore(currentScore: number, holeIndex: number, playerId: number) {
    const customScoreColor = this.getScoreColor(16, 4);
    
    console.log("Enter Score: ", currentScore, holeIndex, playerId);

    this.setState({ enterActive: { group_id: this.props.gid, score: currentScore, hole: holeIndex, player_id: playerId, extraData: "", customScore: 16, customBackgroundColor: customScoreColor.background, customTextColor: customScoreColor.text, customBorderWidth: 2 }});
  }

  getNextPlayer() : number | null {
    const curPlayerId = this.state.enterActive.player_id;
    const curHoleIndex = this.state.enterActive.hole;
    const isCurrentHole = curHoleIndex == this.state.scoreTableData[0].holeIndex;

    console.log("getNextPlayer: ", curPlayerId, curHoleIndex, isCurrentHole);
    console.log("scoreTableData: ", this.state.scoreTableData);

    for(var i=0; i<this.state.scoreTableData.length; ++i) {
      const playerData = this.state.scoreTableData[i];
        
      if(playerData.playerId != curPlayerId) {
          
        if(isCurrentHole && playerData.currentScore == 0
          || !isCurrentHole && playerData.previousScore == 0)
        {
          //console.log("ret ", playerData.playerId);
          return playerData.playerId;
        }
      }
    }
    
    return null;
    
  }

  async trySendPendingScores() {
    if(this.state.sendingScores || this.pendingScores.length == 0) {
      return;
    }

    this.setState({ sendingScores: true, sendStateColor: 'black'})
    const sendingScores : Array<ScoreInput> = JSON.parse(JSON.stringify(this.pendingScores));
    this.pendingScores = [];
    
    this.stopTick();

      /*var payload = {
        groupid: group_id,
        players: {}
      }
    }*/

    ScoreService.sendScores(sendingScores).then(() => {
      this.setState({ sendingScores: false, sendStateColor: 'green' });
      this.fetchTournamentData();
      this.startTick();
    },
    (err) => {
      console.log("Failed sending scores: ", err);
      const tempPendingScores = JSON.parse(JSON.stringify(this.pendingScores));
      this.pendingScores = sendingScores;
      this.pendingScores.push(...tempPendingScores);
      this.setState({ sendingScores: false, sendStateColor: 'red'});
      this.startTick();
    })
    
    /*sendingScores.map((scoreData) => {
      const playerIdString : string = scoreData.playerId.toString();
      const holeIndexString : string = scoreData.holeIndex.toString();

      if(Object.hasOwn(payload.players, playerIdString)) {
        payload.players[playerIdString][holeIndexString] = { s: scoreData.currentScore };
      }
      else {
        payload.players[playerIdString] = { [holeIndexString] : { s: scoreData.currentScore}};
      }
    })


    // TODO update score with graphql
    fetch('https://live-golf.de/submitscores.php', {
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify(payload)
     }).then(
      (res) => {
        if(res.status == 200) {
          // refresh score table
          
        }
        else {
          
        }

        
      },
      (err) => {
        console.log("Failed sending scores: ", err);
        const tempPendingScores = JSON.parse(JSON.stringify(this.pendingScores));
        this.pendingScores = sendingScores;
        this.pendingScores.push(tempPendingScores);
        this.setState({ sendingScores: false, sendStateColor: 'red'});
      }
     );
*/
    //console.log("payload: ", payload);
  }

  renderScoreInput() {

    var scoreRows : Array<JSX.Element> = [];

    const holeData = this.state.currentHoleData[this.state.enterActive.player_id];
    //const holeIndex = this.state.enterActive.holeIndex + 1;
    const holePar = holeData.par;

    for(var i=2; i<16; ++i) {

      var borderColor = '#888';
      var borderWidth = 2;

      const holeColor = this.getScoreColor(i, holePar);

      if(this.state.enterActive.score == i) {
        borderColor = '#FF0';
        borderWidth = 6;
      }
      scoreRows.push(
        
        <ScoreInputComponent 
          backgroundColor={holeColor.background} 
          borderColor={borderColor} 
          borderWidth={borderWidth} 
          input={i} 
          label={i.toString()} 
          onPressed={this.onScorePressed.bind(this)} 
          textColor={holeColor.text}
          fontFamily='LCDMono'
          fontSize={70}
        />
        
      )
    }

    return (
      <View style={{ height: '100%'}}>
        <ScrollView>
          <View style={{ flexDirection: 'column', alignSelf: 'flex-end', width: this.state.enterActive ? 80 : 0, height: '100%', backgroundColor: '#000'}}>
            { scoreRows }
            <ScoreInputComponent 
              backgroundColor="#000"
              borderColor={borderColor}
              borderWidth={2}
              input=""
              label="+"
              onPressed={() => {
                  const newScore = this.state.enterActive.customScore + 1;
                  const customColor = this.getScoreColor(newScore, holePar);
                  this.setState({ enterActive: { ...this.state.enterActive, customScore: newScore, customBackgroundColor: customColor.background, customTextColor: customColor.text }});
                }
              }
              textColor="#FFF"
              fontFamily='roboto'
              fontSize={48}
            />
            <ScoreInputComponent 
              backgroundColor={ this.state.enterActive.customBackgroundColor}
              borderColor={borderColor}
              borderWidth={2}
              input={this.state.enterActive.customScore}
              label={this.state.enterActive.customScore.toString()}
              onPressed={this.onScorePressed.bind(this)}
              textColor={ this.state.enterActive.customTextColor}
              fontFamily='LCDMono'
              fontSize={60}
            />
            <ScoreInputComponent 
              backgroundColor="#000"
              borderColor={borderColor}
              borderWidth={2}
              input=""
              label="-"
              onPressed={() => {
                  const newScore = Math.max(1, this.state.enterActive.customScore - 1);
                  const customColor = this.getScoreColor(newScore, holePar);
                  this.setState({ enterActive: { ...this.state.enterActive, customScore: newScore, customBackgroundColor: customColor.background, customTextColor: customColor.text}});
                }
              }
              textColor="#FFF"
              fontFamily='roboto'
              fontSize={48}
            />
            <ScoreInputComponent 
              backgroundColor="#000" 
              borderColor={borderColor} 
              borderWidth={2} 
              input="wd" 
              label="WD" 
              onPressed={this.onScorePressed.bind(this)} 
              textColor="#FFF"
              fontFamily='roboto'
              fontSize={48}
            />
            <ScoreInputComponent 
              backgroundColor="#000"
              borderColor={borderColor}
              borderWidth={2}
              input="dq"
              label="DQ"
              onPressed={this.onScorePressed.bind(this)}
              textColor="#FFF"
              fontFamily='roboto'
              fontSize={48}
            />  
          </View>
        </ScrollView>
      </View>
    )
  }

  onFetchStateChange(state: string) {
    if(state == 'fetching') {
      this.setState({receiveStateColor: 'black'});
    }
    else if(state == 'success') {
      this.setState({receiveStateColor: 'green'});
    }
    else if(state == 'error') {
      this.setState({receiveStateColor: 'red'});
    }
    else {
      this.setState({receiveStateColor: 'white'});
    }
  }

  onThemePressed() {
    if(this.state.scoreTableTheme == 'dark') 
    { 
      this.setState({ scoreTableTheme: 'light' }) ;
      this.currentThemeName = 'light';
      this.forceUpdate();
    }
    else {
      this.setState({ scoreTableTheme: 'dark' }) ;
      this.currentThemeName = 'dark';
      this.forceUpdate();
    }
  }

  render() {    

    const windowWidth = Dimensions.get('window').width;

    if(!this.state.fontsLoaded) {
      return null;
    }
    
    return (
      
      <View style={[ styles.container, {backgroundColor: Themes[this.currentThemeName].ContainerBackgroundColor}]}>
        <ScrollView>
        <View style={{ flex: 1, alignContent: 'center', alignItems: 'center'}}>
          { this.state.loadingScores &&
            <ActivityIndicator size="large" />
          }
          { !this.state.loadingScores && 
          <>
            <View>
              { this.state.tournamentdata && 
                <Text style={ styles.titleText }>{ this.state.tournamentdata.tournament_details.name }</Text>
              }
            </View>
            <View style={{ alignSelf: 'center', backgroundColor: '#000'}}>
              <this.renderScoreTable />
            </View>
            <View>
              { Object.keys(this.state.currentHoleData).length > 0 && 
              <Text style={styles.courseDataText}>
                { `Hole ${this.state.currentHole + 1} - Par ${this.state.currentHoleData[Object.keys(this.state.currentHoleData)[0]].par} - ${this.state.currentHoleData[Object.keys(this.state.currentHoleData)[0]].length}m` }
              </Text>
              }
            </View>
            </>
          }
          { this.state.tournamentdata &&
            <ScrollView horizontal={true} contentContainerStyle={{ maxWidth: windowWidth }} style={{ maxWidth: 1200}}>
              <ScoreTable 
                width={windowWidth}
                tournamentData={this.state.tournamentdata} 
                themeName={ this.state.scoreTableTheme } 
                fetchStateCallback={this.onFetchStateChange.bind(this)}
              />
            </ScrollView>
          }
        </View>
        <View style={{ alignSelf: 'center', marginTop: 16, flexDirection: 'row'}}>
          <Svg height="64" width="24" viewBox="0 0 30 60">
            <Polyline stroke={this.state.sendStateColor} fill={this.state.sendStateColor} points="19,60 19,25 29,28 16,2 2,28 12,25 12,60"></Polyline>
            </Svg>
          <Svg height="64" width="24" viewBox="0 0 30 60">
            <Polyline stroke={this.state.receiveStateColor} fill={this.state.receiveStateColor} points="19,2 19,36 29,33 16,60 2,33 12,36 12,2"></Polyline>
          </Svg>
        </View>
        <View style={{ alignSelf: 'center', marginTop: 16}}>
          <TouchableOpacity onPress={ this.onThemePressed.bind(this) }><Text style={{ color: '#FFF'}}>Test</Text></TouchableOpacity>
        </View>
        </ScrollView>
        { this.state.enterActive && 
          <this.renderScoreInput />
        }
      </View>
      
    )
  }
}

const TopPadding = 30;

const styles = StyleSheet.create({
  container: { flex: 1, width: '100%', height: '100%', paddingTop: TopPadding, backgroundColor: '#333', flexDirection: 'row' },
  header: { height: 50, backgroundColor: '#537791' },
  text: { textAlign: 'center', fontWeight: '100', fontSize: 20 },
  titleText: { color: '#FFF', fontWeight: '400', fontSize: 32, paddingVertical: 16 },
  headerText: { color: '#FFF', fontWeight: '400', fontSize: 28, paddingHorizontal: 12, paddingVertical: 4 },
  scoreTableText: { fontFamily: 'roboto', color: '#0FF', fontWeight: '300', fontSize: 28, paddingHorizontal: 8, paddingVertical: 4 },
  dataWrapper: { marginTop: -1 },
  row: { height: 40, backgroundColor: '#E7E6E1' },
  col: { height: 40, backgroundColor: '#F00', flexDirection: 'column'},
  btn: { width: 58, height: 16, marginLeft: 1, backgroundColor: '#c8e1ff', borderRadius: 2 },
  btnText: { textAlign: 'center' },
  courseDataText: { fontFamily: 'roboto',color: '#FFF', fontWeight: '400', fontSize: 22, paddingVertical: 4 }
});

type ScoreInputProps = {
  input: string | number;
  label: string;
  onPressed: (input: string | number) => void;
  backgroundColor: string;
  textColor: string;
  borderWidth: number;
  borderColor: string;
  fontFamily: string;
  fontSize: number;
}

class ScoreInputComponent extends React.Component<ScoreInputProps> {
  render() {

    return (
      <TouchableOpacity key={`scoreinput_${this.props.input}`} onPress={() => this.props.onPressed(this.props.input)}>
        <View style={{ marginTop: 2, height: 80, justifyContent: 'center', alignItems: 'center', backgroundColor: this.props.backgroundColor, borderRadius: 4, borderWidth: this.props.borderWidth, borderColor: this.props.borderColor}}>
          <Text style={{ fontFamily: this.props.fontFamily, fontSize: this.props.fontSize, color: this.props.textColor, textAlign: 'center' }}>{this.props.label}</Text>
        </View>
      </TouchableOpacity>
    )
  }
}
