import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { CourseService } from '../../services/CourseService';
import { AdminTournamentCourse } from '../../types/AdminTypes';
import { FlatList, GestureResponderEvent, Modal, Pressable } from 'react-native';
import { Stack, Text, Input, Spinner, Button } from 'tamagui';

const CourseModal = ({ isVisible, onClose, onCourseSelect, resetOnClose = false }) => {
   const [searchText, setSearchText] = useState('');
   const [matchingCourses, setMatchingCourses] = useState(Array<AdminTournamentCourse>);
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      const debouncedFetchCourses = debounce(fetchCourses, 1000);
      debouncedFetchCourses(searchText);
      return () => {
         debouncedFetchCourses.cancel(); // Cancel the debounced function on component unmount
      }
   }, [searchText]);

   // Debounce the API call to avoid excessive requests
   function fetchCourses(text) {
      if (text.length < 3) {
         setIsLoading(false);
         setMatchingCourses([]);
         return;
      }
      console.log("Fetch Courses: ", text);
      setIsLoading(true);

      CourseService.getCoursesLike(0, 40, text).then((data) => {
         setMatchingCourses(data);
         setIsLoading(false);
      })
         .catch(error => {
            console.error(error);
            setIsLoading(false);
         });
   };

   const handleSearchInputChange = (text) => {
      setSearchText(text);
   }

   const handleCourseSelect = (event: GestureResponderEvent, courseId: number) => {

      event.preventDefault();

      for (let i = 0; i < matchingCourses.length; ++i) {
         if (matchingCourses[i].id == courseId) {
            onCourseSelect(matchingCourses[i]);
            break;
         }
      }

      setIsLoading(false);
      if (resetOnClose) {
         setSearchText("");
      }
      onClose();
   }

   return (
      <Modal animationType="slide" transparent={true} visible={isVisible} onRequestClose={onClose}>
         <Stack flex={1}>
          <Stack flex={1} justifyContent="center" alignItems="center">
            <Stack bg="#EEE" p={4} w={600} h={300} borderRadius={6} borderWidth={1} shadowOffset={{ width: 3, height: 3 }} shadowColor="#000" shadowOpacity={0.25} shadowRadius={4} alignItems='center'>
            
               <Text>Select Course</Text>
            
            <Stack maxHeight="300px" minHeight="200" w="400px">
               <Stack alignItems='center' justifyContent='center'>
                  <Input
                     placeholder="Enter course name"
                     value={searchText}
                     onChangeText={handleSearchInputChange}
                     w="300px"
                  />
                  {isLoading && <Spinner color='blue' />
                     ||
                     <Stack p="2px" flexDirection='row'><Text w="200px">Name</Text><Text w="60px">CR</Text></Stack>
                  }
                  { !isLoading && matchingCourses.length == 0 &&
                     <Stack justifyContent='center' alignItems='center'><Text>No courses found</Text></Stack>
                  }
                  { !isLoading && matchingCourses.length > 0 &&
                     <FlatList style={{ maxHeight: 300}} data={matchingCourses} renderItem={({ item }) =>
                        <Pressable key={item.id} onPress={(event: GestureResponderEvent) => handleCourseSelect(event, item.id)}>
                           <Stack p="2px" flexDirection='row'><Text w="200px" fontSize="$5">{`${item.name}`}</Text> <Text w="60px" fontSize="$5">{`${item.course_combis.map((cc) => cc.cr).join(',')}`}</Text></Stack>
                        </Pressable>
                     }
                     />
                  }

               </Stack>
            </Stack>
            <Button onPress={onClose}>
               <Text>Close</Text>
            </Button>
         </Stack>
         </Stack>
         </Stack>
      </Modal>
   );
};

export default CourseModal;

