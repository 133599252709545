import { CourseCombiInput, HoleInput } from 'livegolf_shared/lib/generated/graphql';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-native';
import { Stack, Text, Input, Button, RadioGroup, YStack, XStack, SizeTokens, Label, Separator, useMedia } from 'tamagui';
import { LGText } from '../../styles/StyledText';
import { LGUnit } from '../../types/types';
import { CourseService_GetCourse_CC_Data, CourseService_GetCourse_Data } from '../../services/CourseService';
import { HoleHelper } from '../../helpers/HoleHelper';

const labelWidth = "80px";



const AddHolesModal = ({ isVisible, initialIndex, onSubmitHoles, courseData, courseCombiData, onCanceled, numberOfHoles } : 
      { isVisible: boolean, initialIndex: number, onSubmitHoles: (data: Array<HoleInput>) => void, courseData: CourseService_GetCourse_Data, courseCombiData: CourseCombiInput, onCanceled: () => void, numberOfHoles: number}) => {
   const [holeIndex, setHoleIndex] = useState(initialIndex);
   const [par, setPar] = useState(4);
   const [_hcp, setHcp] = useState(0);
   const [length, setLength] = useState(0);
   const [holesData] = useState<Array<HoleInput>>([]);
   const media = useMedia();

   const getHcpFromExistingCourseCombi = (holeIndex, zeroIfTaken = false) =>
   {
      if(!courseData || !courseCombiData)
      {
         return 0;
      }

      var matchingCourseCombi : CourseService_GetCourse_CC_Data | null = null;
      
      if(courseData.course_combis && courseData.course_combis.length > 0)
      {
         for(var i=0; i<courseData.course_combis.length; ++i)
         {
            matchingCourseCombi = courseData.course_combis[i];
            if(courseData.course_combis[i].gender_id == courseCombiData.gender_id)
            {
               break;
            }
         }
      }

      if(matchingCourseCombi)
      {
         if(!matchingCourseCombi.holes || !matchingCourseCombi.holes[holeIndex] || (zeroIfTaken && ccHasHcpAlready(matchingCourseCombi.holes[holeIndex].hcp)))
         {
            return 0;
         }
         return matchingCourseCombi.holes[holeIndex].hcp;
      }
   }

   useEffect(() => {
      if(isVisible)
      {
         var hcp = getHcpFromExistingCourseCombi(0, true);
         setHcp(hcp);
      }
   }, [isVisible])

   const handleLengthChange = (value) => {

      let intValue = value ? parseInt(value) : 0;
      setLength(intValue);

      if(courseCombiData.unit == LGUnit.yards)
      {
         intValue = Math.round(intValue * 0.9144);
      }

      setPar(HoleHelper.getSuggestedParFromLength(intValue, courseCombiData.gender_id));
   }

   const isDataValid = () =>
   {
      if((holesData.length == numberOfHoles-1 && !currentHoleHasValidData()) || holesData.length < numberOfHoles)
      {
         return false;
      }

      for(let i = 0; i < holesData.length; ++i)
      {
         if(holesData[i].hcp == 0)
         {
            return false;
         }
      }

      return true;
      //return holesData.length == numberOfHoles || (holesData.length == numberOfHoles-1 && currentHoleHasValidData());
   }

   const cancelAdd = () =>
   {
      onCanceled();
   }

   const submitData = () =>
   {
      saveCurrentHoleData();
      onSubmitHoles(holesData);
   }

   const ccHasHcpAlready = (hcp: number) =>
   {
      for(let i = 0; i < holesData.length; ++i)
      {
         if(hcp == holesData[i].hcp)
         {
            return true;
         }
      }

      return false;
   }

   const renderHcpRadioGroup = () =>
   {
      const RadioGroupArray = [];

      const rows = 2;
      //const columns = numberOfHoles/rows;

      for(let row=1; row<3; ++row)
      {
         const curArray = [];
         for (let x1=row; x1<=numberOfHoles; x1+=2)
         {
            curArray.push(<RadioGroupItemWithLabel size={media.xs ? "$2" : "$3"} disabled={ccHasHcpAlready(x1)} value={String(x1)} label={String(x1)} />);
         }

         RadioGroupArray.push(curArray);
      }

      return (
         <YStack>
            <LGText px="8px" w={labelWidth}>Hcp</LGText>
            <RadioGroup pt="8px" value={String(_hcp)} defaultValue="0" onValueChange={(value) => {setHcp(parseInt(value));}}>
               <YStack>
                  <XStack>
                  {RadioGroupArray[0]}
                  </XStack>
                  <XStack>
                  {RadioGroupArray[1]}
                  </XStack>
               </YStack>
            </RadioGroup>
         </YStack>
         )
   }

   const canGoToPreviousHole = () =>
   {
      return holeIndex > 0;
   }

   const currentHoleHasValidData = () =>
   {
      return _hcp != 0;
   }

   const canGoToNextHole = () =>
   {
      return holeIndex < numberOfHoles-1;
   }
   
   const saveCurrentHoleData = () =>
   {
      /*if(!currentHoleHasValidData())
      {
         return;
      }*/

      if(holesData.length > holeIndex)
      {
         holesData[holeIndex].hcp = _hcp;
         holesData[holeIndex].par = par;
         holesData[holeIndex].length = length;
      }
      else
      {
         holesData.push({ hole_index: holeIndex, course_id: courseData.id, tee_id: courseCombiData.tee_id, hcp: _hcp, par: par, length: length});
      }
   }

   const goToNextHole = () =>
   {
      saveCurrentHoleData();
      if(holesData.length > holeIndex)
      {
         if(holeIndex + 1 == holesData.length)
         {
            setPar(4);
            setHcp(getHcpFromExistingCourseCombi(holeIndex+1, true));
            setLength(0);
         }
         else
         {
            setPar(holesData[holeIndex+1].par);
            setHcp(holesData[holeIndex+1].hcp);
            setLength(holesData[holeIndex+1].length);
         }
      }
      else
      {
         setPar(4);
         setHcp(getHcpFromExistingCourseCombi(holeIndex+1, true));
         setLength(0);
      }
      setHoleIndex(holeIndex + 1);
   }

   const goToPreviousHole = () =>
   {
      saveCurrentHoleData();
      setPar(holesData[holeIndex-1].par);
      setHcp(holesData[holeIndex-1].hcp);
      setLength(holesData[holeIndex-1].length);
      setHoleIndex(holeIndex - 1);
   }

   const calculateCoursePar = () =>
   {
      let totalPar = 0;
      for(let i = 0; i < holesData.length; ++i)
      {
         totalPar += holesData[i].par;
      }

      return totalPar;
   }

   const calculateCourseLength = () =>
   {
      let totalLength = 0;
      for(let i = 0; i < holesData.length; ++i)
      {
         totalLength += holesData[i].length;
      }

      if(courseCombiData.unit == LGUnit.meters)
      {
         return totalLength;
      }
      else
      {
         return Math.round(totalLength * 0.9144);
      }
   }

   return (
      
      <Modal animationType="none" transparent={true} visible={isVisible} onRequestClose={submitData}>
         { courseData && courseCombiData &&
         <Stack flex={1}>
            <Stack flex={1} justifyContent="center" alignItems="center">
               <Stack bg="#EEE" p={4} borderRadius={6} borderWidth={1} shadowOffset={{ width: 3, height: 3 }} shadowColor="#000" shadowOpacity={0.25} shadowRadius={4} alignItems='center'>
                  <LGText>Course: {courseData.name}</LGText>
                  <LGText>Tee: {courseCombiData.tee_name}</LGText>
                  <LGText>Hole {holeIndex+1}</LGText>
                  <Stack>
                     <Stack alignItems='center' justifyContent='center'>
                        <XStack px="8px" pt="8px" w="100%">
                           <LGText w={labelWidth}>Length</LGText>
                           <Separator />
                           <Input
                              flex={1}
                              placeholder="Enter Length"
                              keyboardType="number-pad"
                              value={String(length)}
                              onChangeText={handleLengthChange}
                              
                           />
                        </XStack>

                        <XStack px="8px" pt="8px" w="100%">
                           <LGText w={labelWidth}>Par</LGText>
                           <Separator />
                           <RadioGroup value={String(par)} defaultValue="4" onValueChange={(value) => {
                                 setPar(parseInt(value));
                           }}>
                              <XStack>
                                 <RadioGroupItemWithLabel size="$3" value="3" label="3" />
                                 <RadioGroupItemWithLabel size="$3" value="4" label="4" />
                                 <RadioGroupItemWithLabel size="$3" value="5" label="5" />
                              </XStack>
                           </RadioGroup>
                        </XStack>
                        { renderHcpRadioGroup()}
                        
                     </Stack>
                  </Stack>
                  <XStack alignContent='center' justifyContent='center' space="$4">
                     <LGText> Course Par: {calculateCoursePar()}</LGText>
                     <LGText> Length: {calculateCourseLength()}m/{Math.round(calculateCourseLength()*1.09361)}y</LGText>
                  </XStack>
                  <XStack>
                     <Button onPress={cancelAdd}>
                        <Text>Cancel</Text>
                     </Button>
                     <Button onPress={goToPreviousHole} disabled={!canGoToPreviousHole()}>
                        <Text>Previous Hole</Text>
                     </Button>
                     <Button onPress={goToNextHole} disabled={!canGoToNextHole()}>
                        <Text>Next Hole</Text>
                     </Button>
                     <Button onPress={submitData} disabled={!isDataValid()}>
                        <Text>Submit</Text>
                     </Button>
                  </XStack>
               </Stack>
            </Stack>
         </Stack>
      }
      </Modal>
      
   );
};


function RadioGroupItemWithLabel(props: {
   size: SizeTokens
   value: string
   label: string
   disabled?: boolean
 }) {
   const id = `radiogroup-${props.value}`
   return (
     <XStack width={40} alignItems="center" space="$1" bg={props.disabled ? "red" : null}>
       <RadioGroup.Item disabled={props.disabled} value={props.value} id={id} size={props.size}>
         <RadioGroup.Indicator />
       </RadioGroup.Item>
 
       <Label size={props.size} htmlFor={id}>
         {props.label}
       </Label>
     </XStack>
   )
 }

 
export default AddHolesModal;

