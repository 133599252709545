import MaterialIcons from '@expo/vector-icons/build/MaterialIcons';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { StyleSheet, View, Dimensions, NativeSyntheticEvent, TextInputChangeEventData, Pressable } from 'react-native';
import { RootStackParamList } from '../navigation/LGStackNavigator';
import { LivegolfHeader } from '../components/LivegolfHeader';
import * as AuthService from '../services/AuthService';
import { isValidEmail } from '../services/ValidateHelper';
import { Button, Input, ScrollView, Stack, Text, XStack } from 'tamagui';
type Props = NativeStackScreenProps<RootStackParamList, 'Recover'>;

const windowHeight = Dimensions.get('window').height;

type State = {
  email: string;
  dataValid: boolean;
}

export default class RecoverScreen extends React.Component<Props, State> {

  emailTimeout;

  constructor(props) {
    super(props);
    this.state = { email: "", dataValid: false }

    this.onEmailChanged = this.onEmailChanged.bind(this);
  }

  onRecoverPressed() {
    AuthService.recoverUser(this.state.email).then(
      () => {
        this.props.navigation.replace('Home');
      }
    );
  }

  onEmailChanged(event: NativeSyntheticEvent<TextInputChangeEventData>) {
    this.setState({ email: event.nativeEvent.text }, () => {
      if (this.emailTimeout) {
        clearTimeout(this.emailTimeout);
      }
      this.emailTimeout = setTimeout(() => { this.emailTimeout = null; this.checkDataValid() }, 500);
    }
    );
  }

  emailValid() {
    return isValidEmail(this.state.email);
  }

  checkDataValid() {
    this.setState({ dataValid: this.emailValid() });
  }

  submitEditingPassword() {
    if(this.emailValid) {
      this.onRecoverPressed();
    }
  }

  render() {
    
    //return ( <View> <LivegolfHeader title="Login" navigation={this.props.navigation} /></View>);
    return (
      <View style={styles.container}>
        <LivegolfHeader title="Login" navigation={this.props.navigation} />
        <ScrollView style={styles.container} contentContainerStyle={{ height: windowHeight }}>
          <View style={{ alignItems: 'center', marginTop: 32 }}>
            
            <Stack w={400} alignItems="center">
            
              <XStack alignItems="center">
                <MaterialIcons name="mail" size={24} color="black" />
                <Stack 
                  marginRight="$-4" 
                  borderTopLeftRadius="$4" 
                  borderBottomLeftRadius='$4'
                  borderTopRightRadius="$0" 
                  borderBottomRightRadius="$0" 
                  borderColor="$gray12Light"
                  backgroundColor='$gray5Light'
                  borderWidth="$0"
                  borderRightWidth="$0"
                />
                <Input 
                  placeholder='Email'
                  flex={1} 
                  borderColor="$gray12Light"
                  focusStyle={{
                    borderColor: '$gray12Light'
                  }}
                  borderWidth="$0"
                  value={this.state.email}
                  backgroundColor='$gray5Light'
                  borderBottomLeftRadius='$4'
                  onChange={this.onEmailChanged}
                />
              </XStack>
              <Button mt={2} w={140} disabled={!this.state.dataValid} onPress={this.onRecoverPressed.bind(this)}>Recover</Button>
            </Stack>
            
          </View>
            
        </ScrollView>
      </View>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: '#FFF'
  },
  developmentModeText: {
    marginBottom: 20,
    color: 'rgba(255,255,255,0.4)',
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center',
  }
});
