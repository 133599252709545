import { StatusBar } from 'react-native';
import * as Types from '../types/types';
import { apollo_client } from "../App";
import { gql } from '@apollo/client';

import { CourseService } from './CourseService';
import { TournamentPlayer } from 'livegolf_shared';
import * as AdminTypes from '../types/AdminTypes';
import { RoundPlayerData } from 'livegolf_shared/lib/types';
import { CourseCombiService } from './CourseCombiService';

const getTournamentListGQL = gql`
  query tournaments($limit: Int!, $offset: Int!, $descending: Boolean) {
    tournaments(limit: $limit, offset: $offset, descending: $descending) {
      rows {
        id
        name
        mode
        active_round_index
        status
        owner_id
        owner{
          nickname
          player_id
        }
        rounds {
          date
          course {
            id
            name
            cluburl
          }
        }
      }
    count 
  }
}
`;

const getAdminTournamentListGQL = gql`
  query tournaments($limit: Int!, $offset: Int!, $descending: Boolean) {
  editableTournaments(limit: $limit, offset: $offset, descending: $descending) {
    id
    name
    mode
    active_round_index
    status
    owner_id
    owner{
      nickname
      player_id
    }
    visibility
    rounds {
      date
      course {
        id
        name
        cluburl
      }
    }    
  }
}
`;

const getTournamentPlayerDataGQL = gql`
  query tournament($id: Int!){
    tournament(id: $id) {
      id
      status
      name
      active_round_index
      mode
      visibility
      owner_id
      owner{
        nickname
        player_id
      }
      best_ball_points
      birdie_points
      nearest_pin_points
      longest_drive_points
      aggregate_points
      score_version
      rounds {
        id
        round_index
        date
        course {
          name
        }
        groups {
          tee
          starting_time
          round_id
          group_players {
            group_id
            course_combi_id
            score_type
            status
            tee_id
            hcp
            player {
              id
              firstname
              lastname
              
            }
            scores {
              hole
              score
              extra_data
              time_created
            }
          }
        }
      }
    }
  }
`;

const getTournamentScoreVersionGQL = gql`
  query tournament($id: Int!){
    tournament(id: $id) {
      score_version
    }
  }
`;

const getAdminTournamentDataGQL = gql`
query tournament($id: Int!){
  tournament(id: $id) {
    id
    status
    name
    active_round_index
    mode
    visibility
    owner_id
    owner {
      nickname
      player_id
    }
    best_ball_points
    birdie_points
    nearest_pin_points
    longest_drive_points
    aggregate_points
    rounds {
      id
      round_index
      course_id
      course {
        id
        name
        cluburl
        default_tee_male
        default_tee_female
        course_combis {
          id
          tee_id
          gender_id
          tee_name
          holes {
            par
          }
        }
      }
      date
      cut_playercount
      hcp_factor
      points_are_money
      groups {
        id
        tee
        starting_time
        round_id
        group_players {
          group_id
          course_combi_id
          team_id
          score_type
          start_index
          status
          tee_id
          gender_id
          hcp
          player {
            id
            firstname
            lastname
          }
        }
      }
    }
  }
}

`;

const createTournamentGQL = gql`
  mutation addTournament($tournamentData: TournamentInput!) {
    addTournament(tournamentData: $tournamentData) {
      id
    }
  }
`;

const updateTournamentGQL = gql`
  mutation updateTournament($tournamentData: TournamentUpdate!) {
    updateTournament(tournamentData: $tournamentData)
  }
`;

export namespace TournamentService {
  class ScoreBoardTournamentHelper {
    data: ScoreBoardTournamentData;
    course_combis: Array<Types.CourseCombi> = [];

    async fromScoreBoardTournament(in_data: Types.ScoreBoardTournament): Promise<ScoreBoardTournamentData> {

      this.data = {
        tournament_details: {
          dateStart: in_data.rounds[0].date,
          dateEnd: in_data.rounds[in_data.rounds.length - 1].date,
          id: in_data.id,
          name: in_data.name,
          status: in_data.status,
          active_round: in_data.active_round_index,
          best_ball_points: in_data.best_ball_points,
          birdie_points: in_data.birdie_points,
          longest_drive_points: in_data.longest_drive_points,
          mode: in_data.mode,
          nearest_pin_points: in_data.nearest_pin_points,
          aggregate_points: in_data.aggregate_points,
          score_version: in_data.score_version,
          owner_id: in_data.owner_id,
          visibility: in_data.visibility
        },
        courses: [],
        course_combi_ids: [],
        playerData: []
      }

      return new Promise(async (resolve, reject) => {

        for (let roundId = 0; roundId < in_data.rounds.length; ++roundId) {
          const round = in_data.rounds[roundId];

          for (let groupId = 0; groupId < round.groups.length; ++groupId) {
            const group = round.groups[groupId];

            for (let groupPlayerId = 0; groupPlayerId < group.group_players.length; ++groupPlayerId) {
              const groupPlayer = group.group_players[groupPlayerId];
              let playerData = this.getOrCreatePlayerData(groupPlayer);
              let playerRound = this.getOrCreatePlayerRound(playerData, group, round, groupPlayer);
              let courseCombi = await this.getCourseCombi(groupPlayer.course_combi_id);
              if(this.data.tournament_details.mode == Types.TournamentMode.Stableford || 
                this.data.tournament_details.mode == Types.TournamentMode.StrokeStableMix) {
                  
                  //console.log("courseCombi: ", courseCombi);
                  //console.log("hcp: ", playerData.tournamentPlayerData.hcp);
                  playerRound.pchcp = this.calculateCourseHandicap(courseCombi, playerData.tournamentPlayerData.hcp);
                  
              }

              const isActiveRound = playerRound.round_index == in_data.active_round_index;
              if (groupPlayer.scores.length == 0 && isActiveRound) {
                playerData.tournamentPlayerData.through = playerRound.starting_time.substring(0, 5);
              }

              for(let holeIndex=0; holeIndex<18; ++holeIndex) {
                playerRound.holeAllowances.push(this.calculateHoleAllowance(courseCombi.holes[holeIndex].hcp, playerRound.pchcp));
              }

              for (let scoreId = 0; scoreId < groupPlayer.scores.length; ++scoreId) {
                const score = groupPlayer.scores[scoreId];
                playerData.tournamentPlayerData.totalscore += score.score;
                playerData.tournamentPlayerData.through = (isActiveRound && score.score > 0) ? scoreId : playerRound.starting_time;// playerRound.through;
                playerData.tournamentPlayerData.total_to_par += score.score - courseCombi.holes[scoreId].par;
                playerData.tournamentPlayerData.points_gross += Math.max(0, ((courseCombi.holes[scoreId].par - score.score)+2));
                if(this.data.tournament_details.mode == Types.TournamentMode.Stableford || 
                  this.data.tournament_details.mode == Types.TournamentMode.StrokeStableMix) {
                    // calculate net points
                    playerData.tournamentPlayerData.points_net += Math.max(0, (courseCombi.holes[scoreId].par - score.score) + 2 + playerRound.holeAllowances[scoreId]);
                }
    
                playerRound.scores.push(score);
                playerRound.totalscore += score.score;
                playerRound.total_to_par += score.score - courseCombi.holes[scoreId].par;
                if (!this.data.course_combi_ids.includes(playerRound.course_combi_id)) {
                  this.data.course_combi_ids.push(playerRound.course_combi_id);
                }
                if (!this.data.courses.includes(round.course)) {
                  this.data.courses.push(round.course);
                }
              }
            }
          }
        }
        resolve(this.data);
      })

    }

    calculateCourseHandicap(courseCombi: Types.CourseCombi, phcp: number) {
      // Course handicap = hcp X slope/113 + (cr-Par)
      const pchcp = Math.round(Math.abs(phcp * courseCombi.slope / 113) + courseCombi.cr - courseCombi.par);
      //console.log("pchcp: ", pchcp);
      return pchcp;
    }

    calculateHoleAllowance(holeHcpIndex: number, pchcp: number) : number {
        let holeAllowance = Math.floor(pchcp / 18);
        const rest = (holeAllowance > 0) ? (pchcp % 18) : pchcp;

        holeAllowance += (rest >= holeHcpIndex) ? 1 : 0;

        return holeAllowance;
    }

    async getCourseCombi(course_combi_id: number): Promise<Types.CourseCombi> {
      for (let i = 0; i < this.course_combis.length; ++i) {
        if (this.course_combis[i].id === course_combi_id) {
          return this.course_combis[i];
        }
      }
      const courseCombi = await CourseCombiService.getCourseCombi(course_combi_id);
      const coursePar = courseCombi.holes.reduce((a, b) => a + b.par, 0);
      //console.log("CoursePar: ", coursePar);
      this.course_combis.push({ ...courseCombi, par: coursePar });
      return this.course_combis[this.course_combis.length - 1];
    }

    getOrCreatePlayerRound(playerData: TournamentPlayer, group: Types.ScoreBoardGroup, round: Types.ScoreBoardRound, group_player: Types.ScoreBoardGroupPlayer) {
      playerData.tournamentPlayerData.rounds.map((playerRound) => {
        if (playerRound.round_id == round.id) {
          return playerRound;
        }
      })

      playerData.tournamentPlayerData.rounds.push({
        course_combi_id: group_player.course_combi_id,
        hcp_factor: 1,
        round_id: round.id,
        scores: [],
        starting_time: group.starting_time,
        round_index: round.round_index,
        total_to_par: 0,
        total_to_par_net: 0,
        totalscore: 0,
        lowest_CR: 0,
        pchcp: 0,
        phcp: 0,
        points_are_money: 0,
        player_id: 0,
        points_gross: 0,
        points_net: 0,
        team_id: 0,
        tee: group.tee,
        through: -1,
        to_CR: 0,
        holeAllowances: []
      })

      return playerData.tournamentPlayerData.rounds[playerData.tournamentPlayerData.rounds.length - 1];
    }

    getOrCreatePlayerData(group_player: Types.ScoreBoardGroupPlayer) {
      for (let playerIndex = 0; playerIndex < this.data.playerData.length; ++playerIndex) {
        const curPlayer = this.data.playerData[playerIndex];
        if (curPlayer.tournamentPlayerData.player_id == group_player.player.id) {
          curPlayer.tournamentPlayerData.status |= group_player.status;
          return this.data.playerData[playerIndex];
        }
      }

      this.data.playerData.push(
        new TournamentPlayer(
          {
            player_id: group_player.player.id,
            firstname: group_player.player.firstname,
            lastname: group_player.player.lastname,
            gender_id: group_player.gender_id,
            points_gross: 0,
            points_net: 0,
            rounds: [],
            score_type: group_player.score_type,
            status: group_player.status,
            through: -1,
            to_CR: 0,
            totalscore: 0,
            total_to_par: 0,
            totaltopar_net: 0,
            hcp: group_player.hcp
          }
        ));

      return this.data.playerData[this.data.playerData.length - 1];
    }

  }

  export async function getTournamentPlayerData(tid: number): Promise<ScoreBoardTournamentData> {

    //const rdata : RoundPlayerData;
    const { data } = await apollo_client.query({ query: getTournamentPlayerDataGQL, variables: { id: tid }, fetchPolicy: "no-cache" });

    const tdata: Types.ScoreBoardTournament = data.tournament;

    //console.log(tdata);

    return Promise.resolve(convertScoreBoardToTournamentPlayerData(tdata));
  }

  export async function getTournamentList(offset: number, limit: number): Promise<Types.TournamentList> {

    //const rdata : RoundPlayerData;
    const { data } = await apollo_client.query({ query: getTournamentListGQL, variables: { offset: offset, limit: limit, descending: true }, fetchPolicy: "no-cache" });

    const tdata: Types.TournamentList = data.tournaments;

    return Promise.resolve(tdata);
  }

  export async function getAdminTournamentList(offset: number, limit: number): Promise<Array<Types.AdminTournamentListEntry>> {

    const { data } = await apollo_client.query({ query: getAdminTournamentListGQL, variables: { offset: offset, limit: limit, descending: true }, fetchPolicy: "no-cache" });

    const tdata: Array<Types.AdminTournamentListEntry> = data.editableTournaments;

    return Promise.resolve(tdata);
  }

  export async function getAdminTournamentData(tid: number): Promise<AdminTypes.AdminTournamentData> {
    const { data } = await apollo_client.query({ query: getAdminTournamentDataGQL, variables: { id: tid }, fetchPolicy: "no-cache" });

    const tdata = data.tournament;

    return Promise.resolve(tdata);
  }

  export async function createTournament(tournamentData: AdminTypes.AdminTournamentInputNew): Promise<number> {
    const { data } = await apollo_client.query({ query: createTournamentGQL, variables: { tournamentData }, fetchPolicy: "no-cache" });
    console.log("AddTournament: ", data);
    const tid = data.addTournament.id;

    return Promise.resolve(tid);
  }

  export async function updateTournament(tournamentData: AdminTypes.AdminTournamentUpdate): Promise<void> {
    const { data } = await apollo_client.query({ query: updateTournamentGQL, variables: { tournamentData }, fetchPolicy: "no-cache" });

  }

  async function convertScoreBoardToTournamentPlayerData(scoreBoardData: Types.ScoreBoardTournament): Promise<ScoreBoardTournamentData> {

    const tournamentData = new ScoreBoardTournamentHelper();

    const scData = await tournamentData.fromScoreBoardTournament(scoreBoardData);

    //console.log(scData);
    return scData;
  }

  export async function fetchTournamentScoreVersion(tid: number) : Promise<number> {
    const { data } = await apollo_client.query({ query: getTournamentScoreVersionGQL, variables: {id: tid}, fetchPolicy: "no-cache" });

    return data?.tournament.score_version;
  }

  export type ScoreBoardTournamentDetails = {
    id: number;
    name: string;
    dateStart: number,
    dateEnd: number,
    status: number;
    active_round: number;
    mode: number;
    visibility: number;
    owner_id: number;
    best_ball_points: number;
    birdie_points: number;
    aggregate_points: number;
    score_version: number;
    nearest_pin_points: number;
    longest_drive_points: number;
  }
  export type ScoreBoardTournamentData = {
    tournament_details: ScoreBoardTournamentDetails;
    venue_name?: string;
    courses: Array<Types.ScoreBoardCourse>;
    course_combi_ids: Array<number>;
    playerData: Array<TournamentPlayer>;
  }
}


