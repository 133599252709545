import { CourseCombiInput } from 'livegolf_shared/lib/generated/graphql';
import React, { useState } from 'react';
import { Modal } from 'react-native';
import { Stack, Text, Input, Button, RadioGroup, YStack, XStack, SizeTokens, Label, Separator } from 'tamagui';
import { LGText } from '../../styles/StyledText';
import { Gender, LGUnit } from '../../types/types';
import { CourseService_GetCourse_Data } from '../../services/CourseService';

const CourseModal = ({ isVisible, onDataSubmitted, courseData, onCanceled } : { isVisible: boolean, onDataSubmitted: (data: CourseCombiInput, holeCount: number) => void, courseData: CourseService_GetCourse_Data, onCanceled: () => void}) => {
   const [courseCombiName, setCourseCombiName] = useState('');
   const [teeId, setTeeId] = useState(0);
   const [teeName, setTeeName] = useState("");
   const [gender, setGender] = useState(Gender.male);
   const [cr, setCR] = useState("72");
   const [slope, setSlope] = useState(128);
   const [unit, setUnit] = useState(LGUnit.meters);
   const [holeCount, setHoleCount] = useState(18);

   const handleNameInputChange = (text) => {
      setCourseCombiName(text);
   }

   const handleTeeIdChange = (text) => {
      setTeeId(text ? parseInt(text) : 0);
   }

   const handleTeeNameChange = (text) => {
      setTeeName(text);
   }

   const pattern = /^(\d{0,2}([,.]\d{0,1})?)?$/;

   function filterString(inputString) {
      // Test if the input string matches the pattern
      if (pattern.test(inputString)) {
          // If it matches, return the filtered string
          return inputString;
      } else {
          // If it doesn't match, remove unwanted characters
          const filteredString = inputString.replace(/[^0-9,.]/g, '');
  
          // Limit the number of digits before and after the comma
          const parts = filteredString.split(/[,.]/);
          const digitsBeforeComma = parts[0].substring(0, 2);
          const digitsAfterComma = parts[1] ? parts[1].substring(0, 1) : '';
  
          // Concatenate the filtered digits
          const result = `${digitsBeforeComma}${digitsAfterComma ? '.' : ''}${digitsAfterComma}`;
  
          return result;
      }
  }

   const handleCRChange = (text) => {
      setCR(filterString(text));
   }
   

   const handleSlopeChange = (text) => {
      setSlope(text ? parseInt(text) : 0);
   }

   const handleHoleCountChange = (text) => {
      setHoleCount(text ? parseInt(text) : 0);
   }

   const isDataValid = () =>
   {
      return courseCombiName.length > 0 && teeId > 0 && teeName.length > 0 && parseFloat(cr) > 0 && slope > 0;
   }

   const cancelAdd = () =>
   {
      onCanceled();
   }

   const submitData = () =>
   {
      onDataSubmitted(
         {
            name: courseCombiName,
            tee_id: teeId,
            tee_name: teeName,
            gender_id: gender,
            course_id: courseData.id,
            cr: parseFloat(cr),
            slope: slope,
            unit: unit
         },
         holeCount
      )
   }

   return (
      
      <Modal animationType="slide" transparent={true} visible={isVisible} onRequestClose={submitData}>
         { courseData &&
         <Stack flex={1}>
          <Stack flex={1} justifyContent="center" alignItems="center">
            <Stack bg="#EEE" p={4} w={600} borderRadius={6} borderWidth={1} shadowOffset={{ width: 3, height: 3 }} shadowColor="#000" shadowOpacity={0.25} shadowRadius={4} alignItems='center'>
            
               <LGText>Add New Course Combi</LGText>
               <LGText>Course: {courseData.name}</LGText>
            
            <Stack maxHeight="400px" minHeight="200" w="400px">
               <Stack alignItems='center' justifyContent='center'>
                  <XStack>
                     <Text w="200px">Course Combi Name</Text>
                     <Separator />
                     <Input
                        placeholder="Enter course combi name"
                        value={courseCombiName}
                        onChangeText={handleNameInputChange}
                        w="200px"
                     />
                  </XStack>
                  <XStack>
                     <Text w="200px">Tee ID</Text>
                     <Separator />
                     <Input
                        placeholder="Enter Tee ID"
                        keyboardType="numeric"
                        value={String(teeId)}
                        onChangeText={handleTeeIdChange}
                        w="200px"
                     />
                  </XStack>
                  <XStack>
                     <Text w="200px">Tee Name</Text>
                     <Separator />
                     <Input
                        placeholder="Enter Tee Name"
                        value={teeName}
                        onChangeText={handleTeeNameChange}
                        w="200px"
                     />
                  </XStack>
                  <XStack>
                     <Text w="200px">Gender</Text>
                     <Separator />
                     <RadioGroup value={String(gender)} defaultValue={Gender.male.toString()} onValueChange={(value) => {
                           setGender(parseInt(value));
                     }}>
                        <XStack>
                           <RadioGroupItemWithLabel size="$3" value={Gender.male.toString()} label="Male" />
                           <RadioGroupItemWithLabel size="$3" value={Gender.female.toString()} label="Female" />
                        </XStack>
                     </RadioGroup>
                  </XStack>
                  <XStack>
                     <Text w="200px">Course Rating</Text>
                     <Separator />
                     <Input
                        placeholder="Enter CR"
                        keyboardType="numbers-and-punctuation"
                        value={String(cr)}
                        onChangeText={handleCRChange}
                        w="200px"
                     />
                  </XStack>
                  <XStack>
                     <Text w="200px">Slope</Text>
                     <Separator />
                     <Input
                        placeholder="Enter Slope"
                        keyboardType="numeric"
                        value={String(slope)}
                        onChangeText={handleSlopeChange}
                        w="200px"
                     />
                  </XStack>
                  <XStack>
                     <Text w="200px">Hole Count</Text>
                     <Separator />
                     <Input
                        placeholder="Enter Number of Holes"
                        keyboardType="numeric"
                        value={String(holeCount)}
                        onChangeText={handleHoleCountChange}
                        w="200px"
                     />
                  </XStack>
                  <XStack>
                     <Text w="200px">Unit</Text>
                     <Separator />
                     <RadioGroup value={String(unit)} defaultValue={LGUnit.meters.toString()} onValueChange={(value) => {
                           setUnit(parseInt(value));
                     }}>
                        <XStack>
                           <RadioGroupItemWithLabel size="$3" value={LGUnit.meters.toString()} label="Meters" />
                           <RadioGroupItemWithLabel size="$3" value={LGUnit.yards.toString()} label="Yards" />
                        </XStack>
                     </RadioGroup>
                  </XStack>
               </Stack>
            </Stack>
            <XStack>
               <Button onPress={cancelAdd}>
                  <Text>Cancel</Text>
               </Button>
               <Button onPress={submitData} disabled={!isDataValid()}>
                  <Text>Add Holes</Text>
               </Button>
            </XStack>
         </Stack>
         </Stack>
         </Stack>
      }
      </Modal>
      
   );
};

export function RadioGroupItemWithLabel(props: {
   size: SizeTokens
   value: string
   label: string
 }) {
   const id = `radiogroup-${props.value}`
   return (
     <XStack width={100} alignItems="center" space="$4">
       <RadioGroup.Item value={props.value} id={id} size={props.size}>
         <RadioGroup.Indicator />
       </RadioGroup.Item>
 
       <Label size={props.size} htmlFor={id}>
         {props.label}
       </Label>
     </XStack>
   )
 }

 
export default CourseModal;

